import React from 'react';
import Typography from "@material-ui/core/Typography";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {CircularProgress, Theme} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";

import { Card } from '../../../../interfaces';
import {SETTINGS} from "@smartpush-front/styles";
import {ImageService} from "../../../../../../../apps/phoenix/src/utils/imageUtil";

const useStylesOrderCard = makeStyles((theme: Theme) => ({
    sectionText: {
        fontSize: '14px',
        fontWeight: 300,
        lineHeight: '16px',
        paddingBottom: '8px',
        color: '#878B8D'
    },
    paymentCard: {
        height: '14px',
        borderRadius: 3
    },
    brandImage: {
        marginRight: '16px'
    },
    infoMargin: {
        marginLeft: 8
    }
}));


interface Props {
  card: Card
}

function OrderCard({card}: Props) {
    const classes = useStylesOrderCard();

    if (!card || (!card.pan && !card.brand && !card.isLoading)) {
      return (
        <Grid container justify="flex-start" className={classes.infoMargin}>
            <Typography className={classes.sectionText} color="textPrimary">
              {SETTINGS.familiarity ? "Renseigne ta" : "Renseignez votre"} carte de paiement.
            </Typography>
        </Grid>
      );
    }
    const {
      pan, brand, expiryDate, isLoading
    } = card;

    const brandImageFile = {
      visa: 'visa',
      mastercard: 'mastercard',
      'american-express': 'amex',
      maestro: 'maestro',
      cb: 'generic',
      cbmc: 'generic'
      /*
          Cards that are not included:
              - 3xcb
              - 4xcb
              - 3xcb-no-fees
              - 4xcb-no-fees
      */
    }
    const brandImage = brand && (<img alt='card' className={classes.paymentCard} src={`${ImageService.getBaseUrlMedia()}/data/images/paymentCardIcons/${brandImageFile[brand]}.svg`}/>);

    return (
        <Grid container justify="center">
            <Grid item xs={12}>
                {isLoading && (
                    <Grid container justify="center">
                        <Grid item xs={2}>
                            <CircularProgress size={20}/>
                        </Grid>
                    </Grid>
                )}
                {!isLoading && brand && pan && expiryDate && (
                    <Grid container justify="flex-start" className={classes.infoMargin}>
                        <Grid item className={classes.brandImage}>
                            {brandImage}
                        </Grid>
                        <Grid item>
                            <Typography className={classes.sectionText} >
                                <span className={classes.brandImage}>{pan}</span> <span>{ 'XX/'+expiryDate.substring(2) }</span>
                            </Typography>
                        </Grid>
                    </Grid>
                )}
            </Grid>
        </Grid>
    );
}
export default OrderCard;
