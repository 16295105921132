import React, {useEffect, useState} from 'react';
import * as qs from 'qs';
import { exceptParameters } from '../../utils/expectParameters';

import {
  makeSelectUserData,
  useInjectReducer,
  makeSelectUserDataLoading,
  makeSelectPartnerPrimaryColor,
  makeSelectRefreshToken, getToken
} from '@smartpush-front/store';

import {useDispatch, useSelector} from "react-redux";
import userSaga from '../../saga/user/userSaga';

import { loadOffers, offersReducer, saga, makeSelectCartOffers, makeSelectOffersGlobalLoading } from '@smartpush-front/offers';

import {useInjectSaga} from "@smartpush-front/api";

import {useParams} from "react-router";
import {
  makeSelectCardBrandProductName, makeSelectCardOrderDataResponseSuccess,
  makeSelectCardOrderDataStatusCodeError,
  makeSelectIsOldCardSelected,
  makeSelectShoppingCartTotalPrice,
  makeSelectOrderId,
  OrderMain,
  addItemQuantityToCart,
  orderReducer,
  orderSaga,
  clearCart,
  makeSelectCardOrderFromRedirectLoading,
  getOrderDataAfterRedirect, makeSelectCardOrderDataResponsePending, makeSelectReadableId, makeSelectShoppingCartItems
} from "@smartpush-front/payment";
import {MuiThemeProvider} from '@material-ui/core';
import {theme} from '@smartpush-front/styles';
import isEmpty from 'lodash/isEmpty';
import { hasError, WidgetError } from '../../components/WidgetError/WidgetError';
import {useTracking} from "@smartpush-front/tracking";

export function PaymentPage({location}) {
  const dispatch = useDispatch();

  useInjectReducer({key: 'offers', reducer: offersReducer});
  useInjectReducer({key: 'order', reducer: orderReducer});
  useInjectSaga({key: 'offers', saga: saga});
  useInjectSaga({key: 'user', saga: userSaga});
  useInjectSaga({key: 'order', saga: orderSaga})
  const queryParams: any = qs.parse(location.search, { ignoreQueryPrefix: true });
  const missingParameters = exceptParameters(queryParams, ['order_lines']);

  const userData = useSelector(makeSelectUserData());
  const offers = useSelector(makeSelectCartOffers());
  const total = useSelector(makeSelectShoppingCartTotalPrice());
  const loadingUserData = useSelector(makeSelectUserDataLoading());
  const loadingOffers = useSelector(makeSelectOffersGlobalLoading());
  const brand = useSelector(makeSelectCardBrandProductName());
  const isOldCardSelected = useSelector(makeSelectIsOldCardSelected());
  const statusCode = useSelector(makeSelectCardOrderDataStatusCodeError());
  const isSuccess = useSelector(makeSelectCardOrderDataResponseSuccess());
  const isPending = useSelector(makeSelectCardOrderDataResponsePending());
  const orderId = useSelector(makeSelectOrderId());
  const readableId = useSelector(makeSelectReadableId());
  const primaryColor = useSelector(makeSelectPartnerPrimaryColor());
  const orderFromRedirectLoading = useSelector(makeSelectCardOrderFromRedirectLoading());
  const {redirectStatus} = useParams();
  const refreshToken = useSelector(makeSelectRefreshToken())
  const token = useSelector(getToken())
  const shoppingCartItems = useSelector(makeSelectShoppingCartItems());
  const tracking = useTracking();

  const [reinitializeStep, setReinitializeStep] = useState(0);
  const [invalidOfferError, setInvalidOfferError] = useState(false);
  const [unauthorizedError, setUnauthorizedError] = useState(false);
  useEffect(() => {
    if (missingParameters.length === 0 && !queryParams.real_order_id) {
        dispatch(loadOffers(queryParams.order_lines.map(ol => ol.offer_id)));
        setReinitializeStep(reinitializeStep + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    missingParameters.length,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(queryParams),
  ]);

  useEffect( () => {
    if (!isEmpty(shoppingCartItems) && !queryParams.real_order_id) {
      const paramForLog : any = {
        currency: "EUR",
        value: 0,
        items: []
      }

      Object.keys(shoppingCartItems).forEach( (item : any) => {
        paramForLog.value += shoppingCartItems[item].freeValue ?
          (shoppingCartItems[item].quantity - (shoppingCartItems[item].quantity * (shoppingCartItems[item].discountAmount / 100))) :
          shoppingCartItems[item].price * shoppingCartItems[item].quantity;
        const element = {
          "item_id": shoppingCartItems[item].offerId ?? shoppingCartItems[item].id,
          "item_name": shoppingCartItems[item].offer,
          "discount_type": shoppingCartItems[item].discountType,
          "discount_amount": shoppingCartItems[item].discountAmount,
          "item_brand": shoppingCartItems[item].brand.name ?? shoppingCartItems[item].brand,
          "item_category": Array.isArray(shoppingCartItems[item].offerCategories) && shoppingCartItems[item].offerCategories.length >= 1 ?
            shoppingCartItems[item].offerCategories[0].name ?? shoppingCartItems[item].offerCategories[0]
            :
            "Pas de catégorie"
          ,
          quantity: shoppingCartItems[item].freeValue ? 1 : shoppingCartItems[item].quantity, // Fix free value offers where quantity equal price
          "item_price": shoppingCartItems[item].freeValue ? shoppingCartItems[item].quantity : shoppingCartItems[item].price, // Fix free value offers where quantity equal price
          "item_variant": shoppingCartItems[item].variant,
          "item_provider": shoppingCartItems[item]?.provider?.name,
          "item_type": shoppingCartItems[item]?.offerType
        }
        paramForLog.items.push(element);
      })
      tracking.triggerTrackingEvent({
        category: 'ecommerce',
        data: { event: "begin_checkout", "screen_name": "Tunnel de paiement: Paiement", ecommerce: paramForLog }
      })
    }
  }, [shoppingCartItems, missingParameters.length]);

  useEffect(() => {
    if (!orderFromRedirectLoading && redirectStatus && !isEmpty(queryParams) && queryParams.real_order_id && queryParams.access_token) {
      dispatch(getOrderDataAfterRedirect(queryParams.real_order_id, token))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setInvalidOfferError(false);
    setUnauthorizedError(false);
    if (offers.length > 0) {
      dispatch(clearCart())
      queryParams.order_lines.forEach(orderLineSelected => {
        const offer = offers.find(o => {
          return orderLineSelected.offer_id === o.id
        });

        if (!loadingOffers && !loadingUserData && (!offer || !orderLineSelected || (offer.offerVariantPrice?.length > 0 && !orderLineSelected.offer_variant_id) || (offer.price === '0.00' && offer.offerVariantPrices.length === 0))) {
          return setInvalidOfferError(true);
        }

        dispatch(
          addItemQuantityToCart(offer, offer.offerVariantPrices?.length > 0, orderLineSelected.offer_variant_id, parseInt(orderLineSelected.quantity, 10))
        );
      })
    } else if (!loadingOffers && !loadingUserData) {
      if (! userData || ! userData.email ) {
        setUnauthorizedError(true);
      } else {
        setInvalidOfferError(true);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ offers, userData ]);

  if (hasError(missingParameters, loadingUserData, unauthorizedError)) {
    return (
      <WidgetError
        missingParameters={missingParameters}
        isLoading={loadingUserData}
        unauthorizedError={unauthorizedError}
        invalidOfferError={invalidOfferError} />
    );
  }

  const themeWithPrimary = {...theme};
  themeWithPrimary.palette.primary.main = primaryColor || themeWithPrimary.palette.primary.main;

  return (
    <MuiThemeProvider theme={themeWithPrimary}>
      <OrderMain
        userData={userData}
        total={total}
        brand={brand}
        orderId={orderId || queryParams.real_order_id}
        isOldCardSelected={isOldCardSelected}
        statusCode={statusCode}
        isSuccess={isSuccess}
        redirectStatus={redirectStatus}
        reinitializeStep={reinitializeStep}
        isPending={isPending}
        readableId={readableId || queryParams.orderid}
        orderRoute={`/orders/:orderId?access_token=${token}&refresh_token=${refreshToken}`}
      />
    </MuiThemeProvider>
  );
}
