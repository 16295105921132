import React, {useEffect} from 'react';
import {CircularProgress, fade, makeStyles, Typography, Theme} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import {useDispatch, useSelector} from "react-redux";
import saga from './saga';
import reducer from './reducer';
import {
  makeSelectUpdatePasswordLoading, makeSelectUpdatePasswordSuccess
} from "./selectors";
import {clearUpdatePassword, submitUpdatePassword} from "./actions";
import {useInjectReducer} from '@smartpush-front/store';
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import {withTheme} from "@rjsf/core";
import {Theme as MuiTheme} from "@rjsf/material-ui";
import {COLORS, SETTINGS} from "@smartpush-front/styles";
import {resetPasswordSchema} from "./formSchema";
import {useInjectSaga} from "../../../../utils/injectSaga";
import {CustomPasswordTextFieldWidget} from "../../../../utils/forms/widgets";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import Fab from "@material-ui/core/Fab";
import Modal from "@material-ui/core/Modal";
import Divider from "@material-ui/core/Divider";
import clsx from "clsx";

const useStylesUpdatePassword = makeStyles((theme: Theme) => ({
  box: {
    padding: '4px'
  },
  closeButton: {
    color: COLORS.black
  },
  iconNoPadding: {
    cursor: 'pointer',
    fontSize: '1.5em',
    padding: 0,
    borderRadius: '5px'
  },
  modal: {
    root:{
      outline: 'none',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&$focus':{
      outline: 0
    }
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 2px 4px 0 rgba(152,152,152,0.62)',
    padding: theme.spacing(2, 0, 3),
    maxWidth: '600px',
    borderRadius: '5px',
    outline: 0
  },
  divider: {
    marginTop: 24,
    marginBottom: 24,
    '& .MuiDivider-root': {
      marginLeft: 5,
      marginRight: 5,
      color: fade("#2C3A47", 0.12)
    }
  },
  dividerMarginTopBottom: {
    marginTop: 16,
    marginBottom: 16,
  },
  title: {
    fontSize: 24,
    fontFamily: 'ProximaNovaBold',
    lineHeight: '29px',
    paddingBottom: 8,
    paddingLeft: 16
  },
  description: {
    fontSize: 16,
    lineHeight: '19px',
    fontWeight: 300,
    paddingLeft: 16,
    paddingRight: 16
  },
  horizontalPadding: {
    paddingLeft: 16,
    paddingRight: 16
  },
  sectionInput: {
    '& .MuiInputBase-input': {
      marginBottom: 0
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#E1E9EC',
      height: 34,
      borderRadius: 5,
      color: '#232323'
    },
    '& .MuiSelect-root': {
      backgroundColor: '#E1E9EC',
      borderRadius: 5,
      color: '#232323'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
      borderRadius: 5
    },
    '& .MuiOutlinedInput-input': {
      padding: 8,
      fontSize: 16
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(10px, 10px) scale(1)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75);'
    }
  },
  button: {
    borderRadius: 5,
    color: COLORS.white,
    fontFamily: 'ProximaNovaBold',
    fontSize: 16,
    heightLine: '18px'
  }
}));
const key = 'updatePassword';
function validate(formData: any, errors: any) {
  if (formData.pass1 !== formData.pass2) {
    errors.pass2.addError("Les mots de passe ne correspondent pas");
  }
  return errors;
}

export function UpdatePasswordModal({
  open,
  handleClose
}) {

  useInjectSaga({ key, saga });
  useInjectReducer({ key, reducer });
  const classes = useStylesUpdatePassword();
  const dispatch = useDispatch();
  const loading = useSelector(makeSelectUpdatePasswordLoading());
  const success = useSelector(makeSelectUpdatePasswordSuccess());
  const Form = withTheme(MuiTheme);
  const UISchema = {};
  const widgets = {
    PasswordWidget : (props: any) => <CustomPasswordTextFieldWidget {...props} className={classes.sectionInput} />
  };
  const handleSubmit = (formData: any) => {
    dispatch(submitUpdatePassword(formData.oldPassword, formData.pass1));
  };

  useEffect(() => {
    if (success === true) {
      handleClose();
    }
    return () => {
      dispatch(clearUpdatePassword());
    }
  }, [success])

  function ObjectFieldTemplate(props: any): any {
    const dividersAfter = [
      'oldPassword'
    ];
    const formContainers = [
      {
        'title': '',
        'fields' : [
          'oldPassword',
          'pass1',
          'pass2'
        ]
      }
    ];

    return (
        <div>
          <Grid
              container
              justify="center"
          >
            <Grid item xs={12} color={"#b0b0b0"}>
              <Typography className={classes.title}>
                {props.title}
              </Typography>
            </Grid>
            <Grid item xs={12} color={"#b0b0b0"}>
              <Typography className={classes.description}>
                {props.description}
              </Typography>
            </Grid>
          </Grid>
          <Divider className={classes.divider}/>
          <div>
            <Grid container
                  justify="center"
            >
              {
                formContainers.map((container: any, index: number) => {
                  return (
                    <Grid key={index} item xs={10} sm={7} md={7} lg={7} color={"#b0b0b0"}>
                      <Paper elevation={0}>
                        <Typography variant="h2" component="h2" gutterBottom>
                          {container.title}
                        </Typography>
                        <Grid container>
                          {props.properties.filter((e: any) => {return container.fields.includes(e.name)}).map((element: any, index: number) => {
                            return (
                              <React.Fragment key={index}>
                                <Grid item xs={12}>
                                  <Box key={index} className={classes.box}>
                                    {element.content}
                                  </Box>
                                </Grid>
                                { dividersAfter.includes(element.name) && (
                                  <Grid item xs={12}>
                                    <Box className={clsx(classes.divider, classes.dividerMarginTopBottom)}>
                                      <Divider variant="fullWidth"/>
                                    </Box>
                                  </Grid>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </Grid>
                      </Paper>
                    </Grid>
                  );
                })
              }
            </Grid>
          </div>
          <Divider className={classes.divider}/>
        </div>
    );
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="password-update-modal-form"
      aria-describedby="password-update-description"
      className={classes.modal}
    >
      <Grid container className={classes.paper}>
        <Grid item xs={12}>
          <Grid container direction="row-reverse" alignItems="flex-start" className={classes.horizontalPadding}>
            <Grid item>
              <div className={classes.iconNoPadding} onClick={handleClose}>
                <FontAwesomeIcon icon={faTimes} className={classes.closeButton}/>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Form
            schema={resetPasswordSchema}
            ObjectFieldTemplate={ObjectFieldTemplate}
            widgets={widgets}
            uiSchema={UISchema}
            validate={validate}
            showErrorList={false}
            onSubmit={(e: any) => handleSubmit(e.formData)}
            onError={(e: any) => console.log(e)}
          >
            <Grid
              container
              justify="flex-end"
              className={classes.horizontalPadding}
            >
              <Grid item color={"#b0b0b0"}>
                <Fab color="primary" type="submit" variant="extended" className={classes.button}>
                  {!loading && ('Enregistrer')}
                  {loading && (
                    <CircularProgress size={24} color="secondary" />
                  )}
                </Fab>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </Modal>
  );
}
