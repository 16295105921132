/**
 * User Account Data Profile
 */

import {call, put, select, takeLatest} from 'redux-saga/effects';
import { SmartpushAPI } from '@smartpush-front/api';
import {
  getToken,
  getUserOrdersError,
  getUserOrdersSuccess,
  getUserOrderError,
  getUserOrderSuccess,
  GET_ORDERS,
  GET_ORDER
} from '@smartpush-front/store';
import { injectParameter } from '@smartpush-front/payment';
import { SMARTPUSH_API_URL_USER_ORDERS, SMARTPUSH_API_URL_USER_ORDER } from '@smartpush-front/api';
import {makeSelectUserData} from "../../../payment/src/lib/store/selectors/userSelector";

/**
 * User Orders Data request/response handler
 */
export function* getUserAccountOrdersCall() {
  const token = yield select(getToken());
  const user = yield select(makeSelectUserData());

  const options = {
    url   : SMARTPUSH_API_URL_USER_ORDERS.replace(':userId', user?.id ?? "") + '?filters[orderStatus]=completed',
    config: {
      headers: {
        Authorization: token
      }
    }
  };

  try {
    const {data} = yield call(SmartpushAPI.get, options);
    /** Put user data profile through redux */
    yield put(getUserOrdersSuccess(data));
  } catch (err) {
    yield put(getUserOrdersError(err));
  }
}

export function* getUserAccountOrderCall(action: any) {
  const token = yield select(getToken());
  const options = {
    url   : injectParameter(SMARTPUSH_API_URL_USER_ORDER, action.orderId),
    config: {
      headers: {
        Authorization: token
      }
    }
  };

  try {
    const {data} = yield call(SmartpushAPI.get, options);
    /** Put user data profile through redux */
    yield put(getUserOrderSuccess(data));
  } catch (err) {
    yield put(getUserOrderError(err));
  }
}


/**
 * Root saga manages watcher lifecycle
 */
export default function* userAccountSaga() {
  yield takeLatest(GET_ORDERS, getUserAccountOrdersCall);
  yield takeLatest(GET_ORDER, getUserAccountOrderCall);
}
