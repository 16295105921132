import React, {useEffect, useState} from 'react';
import {Grid, Typography} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import NumberFormat from 'react-number-format';
import {SETTINGS} from "@smartpush-front/styles";
import {QuantityButton} from "./QuantityButton";
import ReactReadMoreReadLess from "react-read-more-read-less";

interface OfferVariantFreeI {
  variant: any
  changeAmountValue: any,
  clearOrderLines?: any,
  onPaymentCard?: any
}

export const OfferVariantFree = ({variant, changeAmountValue, clearOrderLines, onPaymentCard}: OfferVariantFreeI) => {
  const [error, setError] = useState<string|undefined>(undefined);
  const [showValueText, setShowValueText] = useState<boolean>(false);
  const [amount, setAmount] = useState<number|undefined>(undefined);
  const classes = styles();
  useEffect(() => {
    if (error && !onPaymentCard) {
      clearOrderLines();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  useEffect(() => {
    if (variant?.quantity) {
      setAmount(() => variant.quantity);
    }
  }, [variant])

  useEffect(() => {
    if (amount === undefined) {
      setError(undefined);
      setShowValueText(false)
      changeAmountValue(0);
      return;
    }
    setShowValueText(true)
    const hasErrorOnMin = amount < variant.minFaceValue;
    const hasErrorOnMax = variant.maxFaceValue ? amount > variant.maxFaceValue : false;

    if (hasErrorOnMin) {
      setError('min');
    }
    if (hasErrorOnMax) {
      setError('max')
    }

    if (onPaymentCard) {
      if (!hasErrorOnMin && !hasErrorOnMax) {
        setError(undefined)
      }
      changeAmountValue(amount);
    }

    if (!hasErrorOnMin && !hasErrorOnMax && !onPaymentCard) {
      setError(undefined);
      changeAmountValue(amount);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [amount])

  const getContentError = (): string|null => {
    switch (error) {
      case 'min':
        return `Le montant minimum attendu est de ${variant.minFaceValue} €`;
      case 'max':
        return "Si vous souhaitez commander un montant plus important, utiliser l'option avec les bons de valeurs différentes.";
      default:
        return null;
    }
  }

  const increaseOrDecrease = (number: number) => {
    let newValue;
    if (amount) {
      if ((amount + number) > 9999) {
        newValue = 9999;
      } else {
        newValue = (amount + number) < variant.minFaceValue ? variant.minFaceValue : (amount + number);
      }
    } else {
      newValue = variant.minFaceValue;
    }

    setAmount(newValue)
  }

  const infoPaymentCard = () => {
    if (SETTINGS.familiarity) {
      return "Recommandation: arrondis à l’euro supérieur pour pouvoir régler la totalité de ton achat avec ce bon car certain sites ne permettent pas de régler moins de 1€ en CB."
    }

    return "Recommandation: arrondissez à l’euro supérieur pour pouvoir régler la totalité de votre achat avec ce bon car certain sites ne permettent pas de régler moins de 1€ en CB."
  }

  return (
    <Grid container justify="center" alignItems="flex-start" direction="column" className={classes.container}>
      <Grid item className={classes.containerInput}>
        <div className={classes.inputCenter}>
            <div>
              <span className={classes.minFaceValueText}>{variant.maxFaceValue ? variant.maxFaceValue : variant.minFaceValue}€ {variant.maxFaceValue ? "maximum" : "minimum"}</span>
            </div>
          <div className={classes.inputBloc}>
            <QuantityButton handleClick={() => increaseOrDecrease(-1)} type="minus" />
            <NumberFormat
              thousandSeparator={" "}
              decimalScale={0}
              allowNegative={false}
              onValueChange={(values) => {setAmount(values.floatValue)}}
              suffix={"€"}
              value={amount}
              placeholder={variant.minFaceValue+ "€ mini"}
              className={classes.inputStyle}
              maxLength={5}
            />
            <QuantityButton handleClick={() => increaseOrDecrease(1)} type="plus" />
          </div>
          {(error === 'max') && (
            <Grid item className={classes.containerError}>
              <Typography variant="caption" color="error" style={{fontStyle: 'italic'}}>{getContentError()}</Typography>
            </Grid>
          )}
        </div>
      </Grid>
      {onPaymentCard && (
        <Grid item style={{marginTop: 16, textAlign: 'center', width: "100%"}}>
          <span style={{textAlign: 'center', fontSize: 12}}>{SETTINGS.familiarity ? "Tu peux": "Vous pouvez"} modifier la valeur de {SETTINGS.familiarity ? "ton" : "votre"} bon</span>
        </Grid>
      )}
      {!onPaymentCard && (
        <Grid item style={{marginTop: 16,  width: "100%"}}>
          <span style={{textAlign: 'center', fontSize: 12, fontStyle: 'italic', color: 'red'}}>
            <ReactReadMoreReadLess charLimit={52} readMoreText={"Lire la suite"} readLessText={"Voir moins"} readMoreStyle={{textDecoration: "underline"}} readLessStyle={{textDecoration: "underline", color: "#000000"}}>
              {infoPaymentCard()}
            </ReactReadMoreReadLess>
          </span>
        </Grid>
      )}
    </Grid>

  )
}

const styles = makeStyles((theme) => ({
  minFaceValueText: {
    fontSize: '0.8rem',
    fontFamily: 'Proxima Nova Bold',
    fontWeight: 'bold'
  },
  container: {
    marginBottom: '1rem',
  },
  containerInput: {
    width: '100%'
  },
  containerError: {
    marginTop: '0.5rem'
  },
  inputBloc: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      color: SETTINGS.styles.primaryLink,
      '&:hover': {
        cursor: 'pointer'
      }
    }
  },
  adornment: {
    marginLeft: "0.5rem"
  },
  inputCenter: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputStyle: {
    textAlign: 'center',
    width: '100px',
    height: '36px',
    margin: '0',
    padding: '6px 0 7px',
    minWidth: '0',
    background: '#E1E9EC',
    borderRadius: '5px',
    marginLeft: '0.5rem',
    marginRight: '0.5rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    paddingTop: '0',
    paddingBottom: '0',
    border: 'none',
    '&:focus': {
      outline: 'none'
    },
    fontSize: "16px"
  }
}));
