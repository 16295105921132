/*
 * Modal CGU City Actions
 */


export const GET_USER_CITY         = 'SmartPush/UserCGU/GET_USER_CITY';
export const GET_USER_CITY_SUCCESS = 'SmartPush/UserCGU/GET_USER_CITY_SUCCESS';
export const GET_USER_CITY_ERROR   = 'SmartPush/UserCGU/GET_USER_CITY_ERROR';
export const GET_USER_CITY_BY_ID         = 'SmartPush/UserCGU/GET_USER_CITY_BY_ID';
export const GET_USER_CITY_BY_ID_SUCCESS = 'SmartPush/UserCGU/GET_USER_CITY_BY_ID_SUCCESS';
export const GET_USER_CITY_BY_ID_ERROR   = 'SmartPush/UserCGU/GET_USER_CITY_BY_ID_ERROR';

/**
 * Get the offer city
 *
 * @param  {object} cityName  Cities to find by name
 *
 * @return {object}           An action object with a type of GET_CITY
 */
export function getUserCity(cityName?: any) {
  return {
    type: GET_USER_CITY,
    cityName
  };
}


/**
 * Get the offer city success
 *
 * @param  {object} cities    Cities found
 *
 * @return {object}           An action object with a type of GET_CITY_SUCCESS
 */
export function getUserCitySuccess(cities?: any) {
  return {
    type: GET_USER_CITY_SUCCESS,
    cities
  };
}



/**
 * Get the offer city error
 *
 * @param  {object} error    Error during cities retrieval
 *
 * @return {object}          An action object with a type of GET_CITY_ERROR
 */
export function getUserCityError(error?: any) {
  return {
    type: GET_USER_CITY_ERROR,
    error
  };
}


/**
 * Get the offer city
 *
 * @param  {object} cityId    City to find by id
 *
 * @return {object}           An action object with a type of GET_USER_CITY_BY_ID
 */
export function getUserCityById(cityId?: any) {
  return {
    type: GET_USER_CITY_BY_ID,
    cityId
  };
}


/**
 * Get the offer city success
 *
 * @param  {object} city      City found
 *
 * @return {object}           An action object with a type of GET_USER_CITY_BY_ID_SUCCESS
 */
export function getUserCityByIdSuccess(city?: any) {
  return {
    type: GET_USER_CITY_BY_ID_SUCCESS,
    city
  };
}



/**
 * Get the offer city error
 *
 * @param  {object} error    Error during city retrieval
 *
 * @return {object}          An action object with a type of GET_USER_CITY_BY_ID_ERROR
 */
export function getUserCityByIdError(error?: any) {
  return {
    type: GET_USER_CITY_BY_ID_ERROR,
    error
  };
}
