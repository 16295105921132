import {Grid, Typography, useMediaQuery} from "@material-ui/core";
import * as React from "react";
import {CardGiftcard} from "@material-ui/icons";
import {useStylesOrderContent} from "./styles";
import {ProductQuantity} from "../ProductFields/ProductQuantity";

interface OrderContentFixVariantProps {
  element: any;
  pendingOrSuccess: boolean;
}

export const OrderContentFixVariant = ({element, pendingOrSuccess}: OrderContentFixVariantProps) => {
  const classes = useStylesOrderContent();
  const isMobile = useMediaQuery('(max-width:767px)');

  return (
    <Grid container className={classes.offerVariant} justify="space-around">
      <Grid item xs={12} lg={10} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
        <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
          <CardGiftcard fontWeight={24} />
          <Typography>{element.quantity}x {element.offer}</Typography>
        </div>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <ProductQuantity product={element} readOnly={pendingOrSuccess} isMobile={isMobile}/>
        </div>
      </Grid>
    </Grid>
  )
}
