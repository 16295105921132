import React from 'react';
import {Typography} from "@material-ui/core";
import {useStylesPaymentModal} from "../styles";

interface CardFeesPropsI {
  totalWithoutFees: any;
}

export const SubTotalWithoutFees = ({totalWithoutFees}: CardFeesPropsI) => {
  const classes = useStylesPaymentModal();
  return (
    <div style={{display: 'flex', justifyContent: 'space-between'}}>
      <div className="text">
        <Typography className="title" style={{fontSize: '14px'}}>Sous-total</Typography>
      </div>
      <div className="amount">
        <Typography className={classes.totalAmountCommon} style={{fontSize: "14px"}}>{totalWithoutFees}€</Typography>
      </div>
    </div>
  )
}
