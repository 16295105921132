/**
 * UpdatePassword selectors
 */

import { createSelector } from 'reselect';
import {initialState} from "./reducer";

const selectGlobal = (state: any) => state.updatePassword || initialState;

/** User token selector */
const makeSelectUpdatePasswordLoading = () =>
    createSelector(
        selectGlobal,
        updateState => updateState.loading,
    );
const makeSelectUpdatePasswordError = () =>
    createSelector(
        selectGlobal,
        updateState => updateState.error
    );
const makeSelectUpdatePasswordSuccess = () =>
  createSelector(
    selectGlobal,
    updateState => updateState.success
  );

export { selectGlobal, makeSelectUpdatePasswordError, makeSelectUpdatePasswordLoading, makeSelectUpdatePasswordSuccess};
