import React from 'react';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {TextField, InputLabel, Input, InputAdornment} from "@material-ui/core";
import PublishIcon from "@material-ui/icons/Publish";
import Moment from 'moment';
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Typography from "@material-ui/core/Typography";
import {SETTINGS} from "@smartpush-front/styles";
import {useSelector} from "react-redux";
import {makeSelectPartnerSettings} from "../../../../../apps/phoenix/src/app/pages/LoginPage/selectors";
import {ImageService} from "../../../../../apps/phoenix/src/utils/imageUtil";

export const CustomDatePicker = (props: any) => {
    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
                margin="none"
                id={props.label}
                label={props.label}
                format="dd/MM/yyyy"
                variant="inline"
                inputVariant="outlined"
                className={props.className ?? "custom"}
                //locale="fr-FR"
                value={props.value !== undefined ? Moment(props.value).toDate() : null}
                required={props.required}
                InputProps={{ classes: { colorSecondary: "primary" } }}
                onChange={(value: any) => {
                    props.onChange(Moment(value).format())
                }}
                KeyboardButtonProps={{
                    'aria-label': 'change date',
                    'color': 'primary'
                }}
            />
        </MuiPickersUtilsProvider>
    );
};

export const CustomEmailWidget = (props: any) => {
    return (
        <TextField
            type="email"
            className={props.className ?? "custom"}
            variant="outlined"
            label="Email"
            value={props.value}
            required={props.required}
            disabled={props.disabled}
            onChange={(event) => props.onChange(event.target.value)}
        />
    );
};

export const CustomTextFieldWidget = (props: any) => {
    return (
        <TextField
            className={props.className ?? "custom"}
            variant="outlined"
            label={props.label}
            value={props.value ? props.value : ''}
            required={props.required}
            onChange={(event) => props.onChange(event.target.value)}
        />
    );
};

export const CustomPasswordTextFieldWidget = (props: any) => {
    return (
        <TextField
            className={props.className ?? "custom"}
            variant="outlined"
            type="password"
            label={props.label}
            helperText={props.description}
            value={props.value ? props.value : ''}
            required={props.required}
            onChange={(event) => props.onChange(event.target.value)}
        />
    );
};

export const CustomInputFileWidget = (props: any) => {
    const processFile = (files: any) => {
        const f = files[0];
        return new Promise((resolve, reject) => {
            const reader: any = new FileReader();
            reader.onload = (event: any) => {
                resolve(event.target.result + ";name:" + event.target.fileName);
            };
            reader.fileName = f.name;
            reader.readAsDataURL(f);
        });
    };

    return (
        <>
            <InputLabel htmlFor="standard-adornment-password" shrink>
                {props.label}
            </InputLabel>
            <Input
                id={props.label}
                type={"file"}
                onChange={(event: any) => processFile(event.target.files).then((evt) => {
                    props.onChange(evt);
                })}
                endAdornment={
                    <InputAdornment position="end">
                        <PublishIcon
                            color="primary"
                        />
                    </InputAdornment>
                }
            />
        </>
    );
};

export const CustomSelectWidget = (props: any) => {
    return (
        <FormControl variant="outlined">
            <InputLabel id={props.label}>{props.label}</InputLabel>
            <Select
                fullWidth
                id={props.label}
                label={props.label}
                className={props.className ?? "custom"}
                labelWidth={45}
                variant="outlined"
                margin="dense"
                value={props.value ? props.value : ''}
                required={props.required}
                disabled={props.disabled}
                MenuProps={{
                  anchorOrigin: {
                    vertical: "bottom",
                    horizontal: "left"
                  },
                  transformOrigin: {
                    vertical: "top",
                    horizontal: "left"
                  },
                  getContentAnchorEl: null
                }}
                onChange={(event) => props.onChange(event.target.value)}
                style={{ borderColor: '#ff0000' }}
            >
                {props && props.options.enumOptions && props.options.enumOptions.map((option: any, index: number) => {
                    return (
                        <MenuItem key={index} value={option.value}>{option.label}</MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};


export const CustomCheckboxUserCGUPage = (props: any) => {
  const partnerSettings = useSelector(makeSelectPartnerSettings());

  return (
    <FormGroup>
      <FormControlLabel
        classes={{
          label: props.label
        }}
        style={{display:'table'}}
        control={<div style={{display:'table-cell'}}><Checkbox checked={props.isChecked} color="primary" onChange={(event: any) => props.onChange(event.target.checked)} name="checkedA" className="checkbox" /></div>}
        label={<Typography>
          En créant mon compte, je confirme avoir plus de 15 ans, et j'accepte les <a href={ImageService.getBaseUrlMedia() + partnerSettings?.legals?.smartaccount} style={{color: "black", fontFamily: "ProximaNovaBold, Arial", fontWeight: 600}} target="_blank" rel="noopener noreferrer">Conditions générales de service</a> et la <a href={partnerSettings?.legals?.privacy} style={{color: "black", fontFamily: "ProximaNovaBold, Arial", fontWeight: 600}} target="_blank" rel="noopener noreferrer">Politique de confidentialité</a>
        </Typography>}
      />
    </FormGroup>
  );
};

export const CustomCheckboxUserMarketingCGUPage = (props: any) => {
  return (
    <FormGroup>
      <FormControlLabel
        classes={{
          label: props.label
        }}
        style={{display:'table'}}
        control={<div style={{display:'table-cell'}}><Checkbox checked={props.isChecked} color="primary" onChange={(event: any) => props.onChange(event.target.checked)} name="checkedA-B" className="checkbox" /></div>}
        label={<Typography>
          J'accepte de recevoir les informations et les meilleures offres par email. Je peux me désinscrire à tout moment.
        </Typography>}
      />
    </FormGroup>
  );
};
export const CustomCheckboxUserMarketingUserDataModal = (props: any) => {
  return (
    <FormGroup>
      <FormControlLabel
        classes={{
          label: props.label
        }}
        style={{display:'table'}}
        control={<div style={{display:'table-cell'}}><Checkbox checked={props.value} color="primary" onChange={(event: any) => props.onChange(event.target.checked)} name="checkedA-B" /></div>}
        label={<Typography>
          J'accepte de recevoir les informations et les meilleures offres par email.
        </Typography>}
      />
    </FormGroup>
  );
};
