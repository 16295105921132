import {
  CALL_CREDENTIAL_TOKEN_FRONT,
  CALL_TOKEN_EXCHANGE_FRONT,
  CALL_TOKEN_EXCHANGE_FRONT_SUCCESS,
  CALL_TOKEN_EXCHANGE_FRONT_ERROR,
  CALL_CREDENTIAL_TOKEN_FRONT_SUCCESS,
  CALL_CREDENTIAL_TOKEN_FRONT_ERROR, SET_TOKEN_IFRAME_FRONT
} from './constants';

/**
 * Dispatched when datas are correctly sent to the table
 * @return {object}               An action object with a type of SEND_ANALYTICS_OFFER_PRINT_SUCCESS passing datas
 */
export function callCredentialTokenFront() {
  return {
    type: CALL_CREDENTIAL_TOKEN_FRONT,
  }
}

export function callCredentialTokenFrontSuccess(credentialToken: string) {
  return {
    type: CALL_CREDENTIAL_TOKEN_FRONT_SUCCESS,
    credentialToken
  }
}

export function callCredentialTokenFrontError(err: any) {
  return {
    type: CALL_CREDENTIAL_TOKEN_FRONT_ERROR,
    err
  }
}

export function callTokenExchangeKeycloakFront() {
  return {
    type: CALL_TOKEN_EXCHANGE_FRONT,
  }
}

export function setTokenIframeFront(token: any) {
  return {
    type: SET_TOKEN_IFRAME_FRONT,
    token
  }
}

export function callTokenExchangeKeycloakFrontSuccess(accessTokenKeyCloak: string, refreshTokenKeyCloak: string, keycloakResponse: string ) {
  return {
    type: CALL_TOKEN_EXCHANGE_FRONT_SUCCESS,
    accessTokenKeyCloak,
    refreshTokenKeyCloak,
    keycloakResponse
  }
}

export function callTokenExchangeKeycloakFrontError(err: any) {
  return {
    type: CALL_TOKEN_EXCHANGE_FRONT_ERROR,
    err
  }
}
