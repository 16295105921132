import React from "react";

import {useMediaQuery} from "@material-ui/core";
import {useStylesPaymentModal} from "../styles";

import {currencyFormatFR} from "../../../../Price/Price";

import Divider from "@material-ui/core/Divider";

import {Card, Offer} from '../../../../../interfaces';
import {OrderContentContainer} from "./OrderContentContainer";
import {OrderContentDelivery} from "./OrderContentDelivery";
import {OrderContentPaymentMethod} from "./OrderContentPaymentMethod";
import {OrderContentBilling} from "./OrderContentBilling";
import {SETTINGS} from "@smartpush-front/styles";
import {OrderContentTotal} from "../../../../../../../offers/src/store/Offer/OrderContentTotal/OrderContentTotal";

interface Props {
  total: number,
  items: Offer[],
  card: Card,
  deliveryEmail: string,
  pendingOrSuccess: boolean,
  username?: string|null,
  address?: string|null,
  zipCode?: string|null,
  city?: string|null,
  savings?: number|null
}

export function OrderSummary(props) {
  const classes = useStylesPaymentModal();
  const isMobile = useMediaQuery('(max-width:767px)');
  const {
    total,
    items,
    card,
    deliveryEmail,
    pendingOrSuccess,
    username,
    address,
    zipCode,
    city,
    savings,
    totalWithoutFees,
    fees
  } = props;

  const getOrderContentTotal = () => {
    if (SETTINGS.specific?.orderContentTotal) {
      return SETTINGS.specific?.orderContentTotal(currencyFormatFR(total), currencyFormatFR(savings));
    }

    return <OrderContentTotal
      savings={currencyFormatFR(savings)}
      total={currencyFormatFR(total)}
      fees={fees ? currencyFormatFR(fees) : null}
      totalWithoutFees={currencyFormatFR(totalWithoutFees)}
    />
  }

  return (
    <>
      <OrderContentContainer isMobile={isMobile} items={items} pendingOrSuccess={pendingOrSuccess}/>
      <Divider variant="fullWidth" className={classes.dividerOrderSummary}/>
      <OrderContentDelivery deliveryEmail={deliveryEmail} />
      <Divider variant="fullWidth" className={classes.dividerOrderSummary}/>
      <OrderContentPaymentMethod pendingOrSuccess={pendingOrSuccess} card={card} />
      <Divider variant="fullWidth" className={classes.dividerOrderSummary}/>
      <OrderContentBilling userName={username} address={address} zipCode={zipCode} city={city} />
      <Divider variant="fullWidth" className={classes.dividerOrderSummary}/>
      {(getOrderContentTotal())}
    </>
  );
}
