import React, {useState, useEffect} from "react";
import {Grid, useMediaQuery, Link} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Checkbox from '@material-ui/core/Checkbox';
import Fab from "@material-ui/core/Fab";
import TextField from "@material-ui/core/TextField";
import Divider from "@material-ui/core/Divider";
import PaymentFields from "../PaymentFields/PaymentFields";
import {useDispatch, useSelector} from "react-redux";
import {useStylesPaymentModal} from "./styles";
import {postOrderData, updateCardFields} from "../../../store/actions/actions";
import {injectParameter} from "../../../../utils/url/injectParameter";
import {USER_ORDER} from "../../../../routes/routes";
import { OrderSummary } from './components/OrderSummary';
import { OrderDeclinedMessage } from './components/OrderDeclinedMessage';
import { OrderSuccessMessage } from './components/OrderSuccessMessage';
import { OrderPendingMessage } from './components/OrderPendingMessage';
import MailIcon from '@material-ui/icons/AlternateEmail';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { OrderButton } from './components/OrderButton';
import {
  makeSelectShoppingCartItems,
  makeSelectCardBrandProductName,
  makeSelectCardExpiryDate,
  makeSelectCardIsLoading,
  makeSelectCardPan,
  makeSelectCardOrderDataError,
  makeSelectOrderIsValid,
  makeSelectCardFeesAmount,
  makeSelectOrderDataResponse,
} from '../../../store/selectors/selectors';

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import {SETTINGS} from "@smartpush-front/styles";
import {currencyFormatFR, Price, PriceSize} from "../../../Price/Price";
import {OrderCardNotSupportedMessage} from "./components/OrderCardNotSupportedMessage";
import {OrderPSPInternalError} from "./components/OrderPSPInternalError";
import {
  DS_ACCEPT,
  DS_CANCEL, DS_DECLINE,
  DS_EXCEPTION, DS_PENDING,
  HTTP_BAD_REQUEST,
  HTTP_CONFLICT, HTTP_FORBIDDEN, HTTP_PAYMENT_REQUIRED,
  HTTP_SERVICE_UNAVAILABLE, HTTP_UNPROCESSABLE_ENTITY
} from "@smartpush-front/api";
import OrderContent from "../OrderContent/OrderContent";
import {useTracking} from "@smartpush-front/tracking";
import {SubTotalWithoutFees} from "./components/SubTotalWithoutFees";
import {CardFees} from "./components/CardFees";
import {OrderRequestInvalidMessage} from "./components/OrderRequestInvalidMessage";

enum Step {
  DELIVERY = 0,
  PAYMENT = 1,
  BILLING = 2,
  SUMMARY = 3
};

interface Props {
  userData: any,
  total: any,
  brand: string,
  isOldCardSelected: true,
  statusCode: number,
  isSuccess: boolean,
  redirectStatus: string,
  orderId: string,
  readableId: string|null
  reinitializeStep?: number,
  orderRoute?: string,
  isPending: boolean
}

export function OrderMain({
  userData,
  total,
  brand,
  isOldCardSelected,
  statusCode,
  isSuccess,
  isPending,
  redirectStatus,
  orderId,
  reinitializeStep,
  readableId,
  orderRoute = USER_ORDER
}: Props) {
    const dispatch = useDispatch();
    const classes = useStylesPaymentModal();
    const isMobile = useMediaQuery('(max-width:767px)');
    const declineRules = [HTTP_FORBIDDEN, HTTP_PAYMENT_REQUIRED, HTTP_UNPROCESSABLE_ENTITY, DS_DECLINE];
    const pendingRules = [DS_PENDING]
    const pspErrorRules = [HTTP_SERVICE_UNAVAILABLE, DS_EXCEPTION]
    const cancelRules = [DS_CANCEL]
    const invalidRequestRules = [HTTP_BAD_REQUEST]
    const noStockErrorRules = ["Can't book the quantity requested", "No Restopolitan codes available for offer", HTTP_BAD_REQUEST]

    const shoppingCartItems = useSelector(makeSelectShoppingCartItems());
    const items = Object.keys(shoppingCartItems).map(
      itemId => shoppingCartItems[itemId]
    );

    const error = useSelector(makeSelectCardOrderDataError());

    const cardPan = useSelector(makeSelectCardPan());
    const cardBrand = useSelector(makeSelectCardBrandProductName());
    const cardExpiryDate = useSelector(makeSelectCardExpiryDate());
    const cardLoading = useSelector(makeSelectCardIsLoading());

    const cardData = {
      pan: cardPan,
      brand: cardBrand,
      expiryDate: cardExpiryDate,
      isLoading: cardLoading
    };

    //Redirect status from HiPay webhook might be : accept, decline, pending, exception, cancel
    const success: boolean = isSuccess || redirectStatus === DS_ACCEPT;
    //Specific Restopolitan.HTTP_BAD_REQUEST
    const noStockError = noStockErrorRules.includes(statusCode) && (noStockErrorRules.includes("Can't book the quantity requested") || noStockErrorRules.includes("No Restopolitan codes available for offer"));
    const decline: boolean = declineRules.includes(statusCode) || declineRules.includes(redirectStatus);
    const pending: boolean = pendingRules.includes(redirectStatus) || isPending;
    const cancel: boolean = cancelRules.includes(redirectStatus);
    const invalidRequest: boolean = invalidRequestRules.includes(statusCode);
    const cardNotSupported: boolean = statusCode === HTTP_CONFLICT;
    const pspInternalError: boolean = pspErrorRules.includes(statusCode) || pspErrorRules.includes(redirectStatus);

    const [email, setEmail] = useState<string>(userData.email ?? '');
    const [address, setAddress] = useState<string>(userData.address ?? '');
    const [zipCode, setZipCode] = useState<string>(userData.zipCode && userData.zipCode.length === 5  ? userData.zipCode : '');
    const [userName] = useState<string>(userData.name ?? '');
    const [city, setCity] = useState<string>(userData.city ?? '');
    const [cbState, setCbState] = useState<boolean>(true);
    const [adressState, setAdressState] = useState<boolean>(userData.zipCode && userData.address ? false : true);
    const [addressInput, setAddressInput] = useState<boolean>(userData.zipCode && userData.address ? false : true);
    const [step, setStep] = useState<Step>(Step.DELIVERY);
    const [orderIdFinal, setOrderIdFinal] = useState<string>(orderId);
    const [saveBillingAddress, setSaveBillingAddress] = useState<boolean>(false);
    const isOrderValid = useSelector(makeSelectOrderIsValid());
    const fees = useSelector(makeSelectCardFeesAmount());
    const isWebview = require('is-ua-webview')
    const isApp = isWebview(navigator.userAgent);
    const tracking = useTracking()
    const orderDataResponse = useSelector(makeSelectOrderDataResponse());


    useEffect( () => {
      if (!isApp && total) {
        if (success || pending) {
          const event = {
            'event': 'purchase',
            'name': 'Validation de la commande',
            'screen_name': 'Tunnel de paiement: Validation de la commande',
            'ecommerce': {
              "transaction_id": orderId ?? Math.floor((total ?? 1) * Date.now()),
              "total_amount": total,
              "order_type": "Commande de bons",
              items: []
            }
          };

          Object.keys(shoppingCartItems).forEach((item : any, index: any) => {
            if (shoppingCartItems[item].freeValue) {
              if (index > 0 && event.ecommerce.order_type === "Commande de bons") {
                event.ecommerce.order_type = 'Commande de bons & à montant libre';
              } else {
                event.ecommerce.order_type = "Commande à montant libre";
              }
            }

            event.ecommerce.items.push({
              "item_id": shoppingCartItems[item].offerId ?? shoppingCartItems[item].id,
              "item_name": shoppingCartItems[item].offer,
              "discount_type": shoppingCartItems[item].discountType,
              "discount_amount": shoppingCartItems[item].discountAmount,
              "item_brand": shoppingCartItems[item].brand.name ?? shoppingCartItems[item].brand,
              "item_category": shoppingCartItems[item].offerCategories,
              quantity: shoppingCartItems[item].freeValue ? 1 : shoppingCartItems[item].quantity,// Fix free value offers where quantity equal price
              "item_price": shoppingCartItems[item].freeValue ? shoppingCartItems[item].quantity : shoppingCartItems[item].price, // Fix free value offers where quantity equal price
              "item_variant": shoppingCartItems[item].variant,
              "item_provider": shoppingCartItems[item]?.provider?.name,
              "item_type": shoppingCartItems[item]?.offerType
            });
          });

          tracking.triggerTrackingEvent({ category: 'ecommerce', data: {...event} });
        } else if (decline || cancel) {
          tracking.triggerTrackingEvent({
            category: 'ecommerce',
            data: {
              'event': 'purchase_failed',
              'name': 'Annulation de la commande',
              'screen_name': 'Tunnel de paiement: Annulation de la commande'
            }
          });
        }
      }
    }, [decline, success, pending, cancel, total, orderId])

    useEffect(() => {
      if (noStockError) {
        const matches = error?.error?.match(/Order: ([a-zA-Z0-9-]+)$/);
        if (matches) {
          setOrderIdFinal(matches[1]);
        } else {
          setOrderIdFinal(orderId)
        }
      } else {
        setOrderIdFinal(orderId)
      }
    }, [noStockError, orderId]);

    const hideShow = () => {
      setCbState(!cbState)
    }

    const getSavings = (orderItems: any) => {
      console.log(items);
      let savings = 0
      orderItems.forEach((item) => {
        if (item.freeValue) {
          savings += item.quantity * item.discountAmount / 100;
        } else {
          const face = item?.faceValue * 100;
          const price = item?.price * 100;
          const save = Math.round(face - price) / 100;

          savings += save * item.quantity;
        }
      });
      return savings;
    }

    const addAddress = () => {
      setAddressInput(true)
    }

  const hideShowAddress = () => {
    setAdressState(!adressState)
  }

    useEffect(() => {
      setStep(Step.DELIVERY);
    }, [reinitializeStep])

    useEffect(() => {
      return () => {
        dispatch(updateCardFields({
            cardNumber: "",
            expirationDate: "",
            cvc: "",
            multiUse: false
        }));
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (decline) {
          dispatch(updateCardFields({
            cardNumber: "",
            expirationDate: "",
            cvc: "",
            multiUse: false
          }));
          setStep(Step.PAYMENT);
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [decline]);

      const hasBackButton = () => isMobile && !success && !pending && !decline && step !== Step.DELIVERY;

  const calculateFees = () => {
    if ((redirectStatus === "accept" || redirectStatus === "pending") && orderDataResponse.fees > 0) {
      return orderDataResponse.fees;
    }
    if (fees === null || fees === 0) {
      return null;
    }

    return Math.round((total * fees) * 100) / 100;
  }

    return (
      <>
        {hasBackButton() && (
          <Link className={"back-link "+classes.backLink} onClick={() => setStep(step - 1) }>
            <ArrowBackIosIcon />
            <span>Retour à l'étape précédente</span>
          </Link>
        )}
        <Grid container spacing={4} style={{padding: isMobile ? '0 0px' : '0 8px', marginTop: 0}}>
            <Grid item className={classes.root} xs={isMobile ? 12 : 8}>
                <div className={classes.cardRoot}>
                    {success &&  (
                      <OrderSuccessMessage isMobile={isMobile} userData={userData} ctaRoute={injectParameter(orderRoute, orderIdFinal)} orderRoute={orderRoute} readableId={readableId}/>
                    )}
                    {pending && (
                      <OrderPendingMessage userData={userData} ctaRoute={injectParameter(orderRoute, orderIdFinal)} />
                    )}
                    {decline && (
                      <div style={{marginBottom: 24, flexGrow: 1}}>
                        <OrderDeclinedMessage isMobile={isMobile}  userData={userData}/>
                      </div>
                    )}
                    {cancel && (
                      <div style={{marginBottom: 24, flexGrow: 1}}>
                        <OrderDeclinedMessage isMobile={isMobile}/>
                      </div>
                    )}
                    {cardNotSupported && (
                      <div style={{marginBottom: 24, flexGrow: 1}}>
                        <OrderCardNotSupportedMessage />
                      </div>
                    )}
                    {pspInternalError && (
                      <div style={{marginBottom: 24, flexGrow: 1}}>
                        <OrderPSPInternalError />
                      </div>
                    )}
                    {invalidRequest && (
                      <div style={{marginBottom: 24, flexGrow: 1}}>
                        <OrderRequestInvalidMessage/>
                      </div>
                    )}
                  {!success && !pending && (
                    <>
                      {isMobile && (
                        <Grid container alignItems="flex-start" className={"sectionPadding "+classes.sectionPaddingMobile}>
                                <Grid style={{marginTop: 0}} item xs={12}>
                                  <Typography className={classes.sectionTitlePaddingMobile}>
                                    Ma commande
                                  </Typography>
                                </Grid>
                                <Grid item style={{paddingTop: 10, paddingBottom: 10}} xs={12}>
                                  <OrderContent items={items} pendingOrSuccess={success || pending} />
                                </Grid>
                                <Grid style={{marginTop: 0}} item xs={12}>
                                  <Typography className={classes.sectionTitlePaddingMobile}>
                                    Mon adresse mail de livraison
                                  </Typography>
                                </Grid>
                                <div style={{position: "relative", margin: isMobile ? '10 auto' : '0', width: '100%', maxWidth: '360px'}}>
                                  {email &&
                                    <Typography style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginTop: '16px', paddingLeft: '20px'}} >
                                      <MailIcon/>
                                      <span style={{paddingLeft: '10px'}}>{email}</span>
                                    </Typography>
                                  }
                                  {!email &&
                                    <TextField
                                      disabled
                                      className={classes.sectionInput}
                                      variant="outlined"
                                      onChange={({target: {value}}) => {setEmail(value)}}
                                      value={email}
                                    />
                                  }
                                </div>
                                <Grid style={{marginTop: isMobile ? 16 : 0}} item xs={12}>
                                  <Typography className={classes.sectionTitlePaddingMobile} style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginTop: '0px', paddingLeft: '0px', justifyContent: "space-between"}}>
                                    <span style={{paddingLeft: '20px'}}>Mon mode de paiement</span>
                                    <KeyboardArrowDownIcon  className={!cbState ? classes.iconRotate : classes.iconRotated} onClick={() => hideShow()}/>
                                  </Typography>
                                </Grid>
                                {cbState &&
                                  <div style={{padding: "10px 20px"}}>
                                    <Grid item xs={12}>
                                      <Typography gutterBottom className={classes.sectionText} color="textPrimary">
                                        {SETTINGS.familiarity ? "Renseigne ta" : "Renseignez votre"} carte de paiement.
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                      <PaymentFields isMobile={isMobile} />
                                    </Grid>
                                  </div>
                                }
                                <Grid container alignItems="flex-start"
                                      className={"sectionPadding " + classes.sectionPaddingMobile}>
                                  <Grid style={{marginTop: !isMobile ? 16 : 0}} item xs={12}>
                                    <Typography className={classes.sectionTitlePaddingMobile} style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap', marginTop: '0px', paddingLeft: '0px', justifyContent: "space-between"}}>
                                      <span style={{paddingLeft: '20px'}}>Mon adresse de facturation</span>
                                      <KeyboardArrowDownIcon className={!adressState ? classes.iconRotate : classes.iconRotated} onClick={() => hideShowAddress()}/>
                                    </Typography>
                                  </Grid>
                                  {adressState &&
                                    <div>
                                      {addressInput &&
                                        <div style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          paddingLeft: '10px',
                                          marginTop: '10px'
                                        }}>
                                          <div style={{
                                            position: "relative",
                                            margin: '0 auto',
                                            width: '100%',
                                            maxWidth: '360px'
                                          }}>
                                            <TextField
                                              placeholder="Adresse"
                                              className={classes.sectionInput}
                                              variant="outlined"
                                              onChange={({target: {value}}) => {
                                                setAddress(value)
                                              }}
                                              value={address}
                                            />
                                          </div>
                                          <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            position: "relative",
                                            margin: '8px auto 0',
                                            width: '100%',
                                            maxWidth: '360px'
                                          }}>
                                            <TextField
                                              placeholder="Code Postal"
                                              className={classes.sectionInput}
                                              variant="outlined"
                                              onChange={({target: {value}}) => {
                                                setZipCode(value)
                                              }}
                                              value={zipCode}
                                            />
                                            <TextField
                                              style={{marginLeft: 8}}
                                              placeholder="Ville"
                                              className={classes.sectionInput}
                                              variant="outlined"
                                              onChange={({target: {value}}) => {
                                                setCity(value)
                                              }}
                                              value={city}
                                            />
                                          </div>
                                        </div>
                                      }
                                      {!addressInput &&
                                        <div style={{
                                          display: 'flex',
                                          flexDirection: 'column',
                                          paddingLeft: '30px',
                                          marginTop: '10px'
                                        }}>
                                          <></>
                                          <div style={{fontStyle: "italic"}}> Mon adresse enregistrée</div>
                                          <div style={{
                                            position: "relative",
                                            margin: '8px auto 0',
                                            width: '100%',
                                            maxWidth: '360px'
                                          }}>
                                            {userName}
                                          </div>
                                          <div style={{
                                            position: "relative",
                                            margin: '8px auto 0',
                                            width: '100%',
                                            maxWidth: '360px'
                                          }}>
                                            {address}
                                          </div>
                                          <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            position: "relative",
                                            margin: '8px auto 0',
                                            width: '100%',
                                            maxWidth: '360px'
                                          }}>
                                            {zipCode + " " + city}
                                          </div>
                                          <div onClick={() => addAddress()} style={{textDecoration: "underline", paddingTop: '10px'}}>Ajouter une adresse</div>
                                        </div>
                                      }
                                      <div style={{
                                        margin: '16px auto',
                                        textAlign: 'center',
                                        width: '100%',
                                        maxWidth: 240
                                      }}>
                                      </div>
                                    </div>
                                  }
                                  <Divider/>
                                  <Grid style={{marginTop: !isMobile ? 16 : 10}} item xs={12}>
                                    {(fees || orderDataResponse?.fees > 0) && (
                                      <>
                                        <div className={classes.totalContainerFees}>
                                          <SubTotalWithoutFees totalWithoutFees={currencyFormatFR(total)} />
                                        </div>
                                        <Typography className={classes.totalTypographyStyle}>
                                          <span style={{fontFamily: 'ProximaNovaBold', fontSize: "14px", fontStyle: 'italic'}}>Economies réalisées</span>
                                          <Price size={PriceSize.SMALL} amount={getSavings(items)}/>
                                        </Typography>
                                        <div className={classes.totalContainerFees}>
                                          <CardFees fees={currencyFormatFR(calculateFees())} />
                                        </div>
                                      </>
                                    )}
                                    <Typography className={classes.totalTypographyStyle}>
                                      <span style={{fontFamily: 'ProximaNovaBold'}}>TOTAL</span>
                                      <Price size={PriceSize.SMALL} amount={total + calculateFees()} />
                                    </Typography>
                                    {(!fees || orderDataResponse?.fees === 0) && (
                                      <Typography className={classes.totalTypographyStyle}>
                                        <span style={{fontFamily: 'ProximaNovaBold', fontSize: "14px", fontStyle: 'italic'}}>Economies réalisées</span>
                                        {/*<Price size={PriceSize.SMALL} amount={getSavings(items)}/>*/}
                                      </Typography>
                                    )}
                                  </Grid>
                                  <Grid style={{marginBottom: 20}} item xs={12}>
                                    <div className={classes.orderButtonContainerMobile} >
                                      <OrderButton
                                        disabled={!email || !city || !zipCode || !address || (!isOldCardSelected && !brand) || !isOrderValid}
                                        onClick={() => dispatch(postOrderData(email, address, zipCode, city, saveBillingAddress))}
                                        total={total + calculateFees()}
                                        isMobile={isMobile}
                                      />
                                    </div>
                                  </Grid>
                                </Grid>
                              </Grid>
                            )}
                            {!isMobile && (
                              <>
                              <Grid container alignItems="flex-start">
                                  <Grid item xs={12}>
                                      <Typography className={classes.mainTitle}>
                                          Commande
                                      </Typography>
                                  </Grid>
                              </Grid>
                                <Grid container alignItems="flex-start" className={"sectionPadding "+classes.sectionPadding}>
                                <Grid style={{marginTop: isMobile ? 16 : 0}} item xs={12}>
                                  <Typography className={classes.sectionTitlePadding4}>
                                    Adresse de livraison
                                  </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                  <Typography gutterBottom className={classes.sectionText} color="textPrimary">
                                  {SETTINGS.familiarity ? (
                                    <>
                                    Ton adresse e-mail va nous permettre de t'envoyer ta commande.
                                    Nous avons pré-rempli le champs avec l’adresse e-mail de ton compte utilisateur.
                                    </>
                                    ) : (
                                    <>
                                    Votre adresse e-mail va nous permettre de vous envoyer votre commande.
                                    Nous avons pré-rempli le champs avec l’adresse e-mail de votre compte utilisateur.
                                    </>
                                    )}
                                  </Typography>
                                </Grid>
                                <div style={{position: "relative", margin: isMobile ? '0 auto' : '0', width: '100%', maxWidth: '360px'}}>
                                  <TextField
                                  disabled
                                  className={classes.sectionInput}
                                  variant="outlined"
                                  onChange={({target: {value}}) => {setEmail(value)}}
                                  value={email}
                                  />
                                </div>
                              </Grid>
                            <Divider variant="fullWidth" className={classes.divider}/>
                            <Grid container alignItems="flex-start" className={"sectionPadding "+classes.sectionPadding}>
                                {decline && isMobile && (
                                  <OrderDeclinedMessage isMobile={isMobile}/>
                                )}
                                <Grid style={{marginTop: isMobile ? 16 : 0}} item xs={12}>
                                    <Typography className={classes.sectionTitlePadding4}>
                                        Paiement
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography gutterBottom className={classes.sectionText} color="textPrimary">
                                      {SETTINGS.familiarity ? "Renseigne ta" : "Renseignez votre"} carte de paiement.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <PaymentFields isMobile={isMobile} />
                                </Grid>
                                {isMobile && (
                                <div style={{ margin: '16px auto', textAlign: 'center', width: '100%', maxWidth: 240 }}>
                                  <Fab color="primary"   disabled={!(isOldCardSelected || brand)} className={classes.paymentButton} onClick={() => setStep(Step.BILLING) }>
                                      <Typography className={classes.paymentButtonTypo}>
                                          SUIVANT
                                      </Typography>
                                  </Fab>
                                </div>
                                )}
                            </Grid>
                            <Divider variant="fullWidth" className={classes.divider}/>
                              <Grid container alignItems="flex-start" className={"sectionPadding "+classes.sectionPadding} style={{paddingBottom: 24}}>
                                <Grid style={{marginTop: isMobile ? 16 : 0}} item xs={12}>
                                    <Typography className={classes.sectionTitlePadding4}>
                                      Adresse de facturation
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography gutterBottom className={classes.sectionText} color="textPrimary">
                                      {SETTINGS.familiarity ? (
                                        <>
                                          Ton adresse de facturation va nous permettre d'éditer ta facture. Nous avons pré-rempli les champs avec les informations de ton compte utilisateur.
                                        </>
                                      ) : (
                                        <>
                                          Votre adresse de facturation va nous permettre d'éditer votre facture. Nous avons pré-rempli les champs avec les informations de votre compte utilisateur.
                                        </>
                                      )}
                                      </Typography>
                                </Grid>
                                <div style={{display: 'flex', flexDirection: 'column'}}>
                                  <div style={{position: "relative", margin: '0 auto', width: '100%', maxWidth: '360px'}}>
                                      <TextField
                                          placeholder="Adresse"
                                          className={classes.sectionInput}
                                          variant="outlined"
                                          onChange={({target: {value}}) => {setAddress(value)}}
                                          value={address}
                                      />
                                  </div>
                                  <div style={{display: 'flex', flexDirection: 'row', position: "relative", margin: '8px auto 0', width: '100%', maxWidth: '360px'}}>
                                      <TextField
                                          placeholder="Code Postal"
                                          className={classes.sectionInput}
                                          variant="outlined"
                                          onChange={({target: {value}}) => {setZipCode(value)}}
                                          value={zipCode}
                                      />
                                      <TextField
                                          style={{marginLeft: 8}}
                                          placeholder="Ville"
                                          className={classes.sectionInput}
                                          variant="outlined"
                                          onChange={({target: {value}}) => {setCity(value)}}
                                          value={city}
                                      />
                                  </div>
                                  {isMobile && (
                                    <div style={{ margin: '16px auto', textAlign: 'center', width: '100%', maxWidth: 240 }}>
                                      <Fab color="primary" disabled={!address || !zipCode || !city} className={classes.paymentButton} onClick={() => setStep(Step.SUMMARY) }>
                                          <Typography className={classes.paymentButtonTypo}>
                                              SUIVANT
                                          </Typography>
                                      </Fab>
                                    </div>
                                  )}
                                </div>
                              </Grid>
                            {isMobile && (
                              <>
                                <Divider variant="fullWidth" className={classes.divider}/>

                                <Grid container alignItems="flex-start" className={"sectionPadding "+classes.sectionPadding}>
                                <div style={{ margin: '16px 0', width: '100%' }}>
                                <OrderButton
                                disabled={!email || !city || !zipCode || !address || !isOrderValid}
                                onClick={() => dispatch(postOrderData(email, address, zipCode, city, saveBillingAddress))}
                                total={total + calculateFees()}
                                isMobile={isMobile}
                                />
                                </div>
                                </Grid>
                              </>
                            )}
                              </>
                            )}
                        </>
                    )}
                </div>
            </Grid>
            {!isMobile && (
            <Grid item xs={4} className={classes.root}>
                <div className={classes.cardRoot}>
                    <Grid container alignItems="flex-start">
                        <Grid item xs>
                            <Typography className={classes.mainTitleNoPaddingBottom}>
                                Récapitulatif
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider variant="fullWidth" className={classes.dividerOrderSummary}/>
                    <OrderSummary
                      items={items}
                      total={total + calculateFees()}
                      card={cardData}
                      pendingOrSuccess={success || pending}
                      deliveryEmail={email}
                      username={userName}
                      address={address}
                      zipCode={zipCode}
                      city={city}
                      savings={getSavings(items)}
                      totalWithoutFees={total}
                      fees={calculateFees()}
                    />
                    {!success && !pending && (
                        <>
                            <Divider variant="fullWidth" className={classes.dividerLast}/>
                            <Grid container alignItems="flex-start" className={classes.sectionPaddingOrder}>
                                <Grid item xs={12}>
                                    <Grid container justify="space-between" alignItems="flex-end">
                                        <Grid item xs={12}>
                                            <OrderButton
                                              disabled={!email || !city || !zipCode || !address || (!isOldCardSelected && !brand) || !isOrderValid}
                                              total={total + calculateFees()}
                                              onClick={() => dispatch(postOrderData(email, address, zipCode, city, saveBillingAddress))}
                                              isMobile={isMobile}
                                            />
                                        </Grid>
                                        <Grid item>
                                            <Typography align="center" className={classes.paymentCGU}>
                                                En validant ma commande, je déclare avoir pris connaissance et accepté sans réserve : les Conditions Générales de Vente, la politique de données personnelles, les Conditions Générales d’utilisation.
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </div>
            </Grid>
            )}
        </Grid>
      </>
    );
}
