import React from "react";
import {Theme} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {ImageService} from "../../../../../../apps/phoenix/src/utils/imageUtil";

export const useStylesPaymentCardImages = makeStyles((theme: Theme) => ({
    paymentCard: {
        height: '14px',
        marginLeft: 4,
        borderRadius: 3
    },
    paymentDiv: {
        marginLeft: 40,
        marginTop: 6,
        position: 'relative',
        top: -4
    }
}));

export default function PaymentCardImages(props) {
    const classes = useStylesPaymentCardImages();

    const cards = [ 'visa', 'mastercard', 'maestro', 'cb' ];

    return (
        <div className={classes.paymentDiv}>
          {(cards.map((card, index) => (
            <span key={index}>
                <img alt="card" className={classes.paymentCard} src={`${ImageService.getBaseUrlMedia()}/data/images/paymentCardIcons/${card}.svg`} />
            </span>
          )))}
        </div>
    );
}
