import {HipayCard} from "@smartpush-front/payment";
import {store} from "@smartpush-front/store";

export default class HipayService {
  private Hipay: any;

  public constructor() {
    const { pspConfig } = store.getState()?.global?.partnerSettings;
    const env = process.env.REACT_APP_SMARTPUSH_HIPAY_ENV || process.env.NX_SMARTPUSH_HIPAY_ENV;

    this.Hipay = typeof (window as any).HiPay === "function" ? (window as any).HiPay({
      username: pspConfig?.publicLogin,
      password: pspConfig?.publicPassword,
      environment: env,
      lang: "fr"
    }) : null
  }

  public tokenize = async ({
       cardHolder,
       cardNumber,
       expiryMonth,
       expiryYear,
       cvc,
       multiUse
  }): Promise<HipayCard> => {
    return await this.Hipay.tokenize({
      cardHolder,
      cardNumber,
      expiryMonth,
      expiryYear,
      cvc,
      multiUse
    });
  }

  public getBrowserInfo() {
    return this.Hipay.getBrowserInfo()
  }
}
