/**
 * Card data
 */

import {call, put, takeLatest, select} from 'redux-saga/effects';
import {
  clearCardTokenError,
  clearCardTokenSuccess, getCardFees, getCardTokens,
  getCardTokensError,
  getCardTokensSuccess,
  getCardTypesError,
  getCardTypesSuccess, getOrderDataAfterRedirectError, getOrderDataAfterRedirectSuccess,
  postOrderDataError,
  postOrderDataSuccess,
  saveCardDataError,
  saveCardDataSuccess, saveCardFees, saveCardFeesError,
  saveCardTokenData, saveCardTokenDataError, saveCardTokenDataSuccess
} from '../actions/actions';
import {
  CLEAR_CARD_TOKEN_DATA, GET_CARD_FEES,
  GET_CARD_TOKENS_DATA,
  GET_CARD_TYPES_DATA, GET_ORDER_DATA_AFTER_REDIRECT,
  POST_ORDER_DATA,
  SAVE_CARD_DATA,
  SAVE_CARD_TOKEN_DATA
} from '../constants/constants';
import {getRefreshToken, getToken} from '../selectors/userSelector';
import {
  SMARTPUSH_API_URL_CARD_TYPES,
  SMARTPUSH_API_URL_CREATE_ORDER, SMARTPUSH_API_URL_GET_CARD_FEES, SMARTPUSH_API_URL_USER_CARD_TOKEN,
  SMARTPUSH_API_URL_USER_CARD_TOKENS
} from '@smartpush-front/api';
import { SmartpushAPI } from '@smartpush-front/api';
import {
  makeSelectCardBrandProductName, makeSelectCardData,
  makeSelectCardToken, makeSelectCardTokens, makeSelectIsOldCardSelected,
  makeSelectShoppingCartItems,
  makeSelectCardExpiryDate,
  makeSelectCardPan,
  makeSelectRequestId
} from "../selectors/selectors";
import {injectParameter} from "../../../utils/url/injectParameter";
import {SMARTPUSH_API_URL_USER_ORDER} from "../../../config/constants";
import {
  clearData,
  LS_CREDENTIAL_TOKEN,
  LS_CREDENTIAL_TOKEN_KEY,
  LS_IFRAME_TOKEN,
  LS_IFRAME_TOKEN_KEY,
  LS_KEYCLOAK_ACCESS_TOKEN_KEY,
  LS_KEYCLOAK_REFRESH_TOKEN_KEY,
  LS_KEYCLOAK_RESPONSE_TOKEN_KEY,
  LS_KEYCLOAK_TOKENS,
  LS_OAUTH_ACCESS_TOKEN_KEY,
  LS_OAUTH_REFRESH_TOKEN_KEY,
  LS_OAUTH_TOKENS,
} from '@smartpush-front/store';
import HipayService from "../../Payment/components/HipayService";
import {logEvent} from "../../../utils/analytics";
import {isKeycloakAuthentication} from "../../../../../api/src/utils/keycloak";

interface HipayBrowserInfo {
  java_enabled: boolean,
  javascript_enabled: boolean,
  language: string,
  color_depth: number,
  screen_height: number,
  screen_width: number,
  timezone: number,
  ipaddr: string,
  http_accept: string,
  http_user_agent: string
}
export interface HipayCard {
  brand: string,
  browser_info: HipayBrowserInfo,
  card_expiry_month: string,
  card_expiry_year: string,
  card_holder: string,
  card_id: string,
  card_type: string,
  country: string,
  forbidden_issuer_country: boolean,
  multi_use: number,
  pan: string,
  payment_product: string,
  request_id: string,
  token: string
}

/**
 * Order / Card request/response handler
 */
export function* saveCardDataCall(action: any) {
    try {
        const hipayService = new HipayService();
        const cardData: HipayCard = yield hipayService.tokenize(action.cardDataToSave);
        yield put(saveCardDataSuccess(Object.assign({}, cardData, {
          pan: cardData.pan.substr(0, 4) + ' xxxx xxxx xx' + cardData.pan.substr(-2)
//          expirationDate: cardData.expiryDate
        })));
        yield put(getCardFees(cardData.request_id, cardData.token));
        //if (action.cardDataToSave.multiUse) {
        //    yield put(saveCardTokenData(token, cardData.payment_product));
        //}
    } catch (err) {
        yield put(saveCardDataError(err));
    }
}

export function* getCardFeesCall(action: any) {
  const token = yield select(getToken());
  const options = {
    url: SMARTPUSH_API_URL_GET_CARD_FEES + '?requestId=' + action.requestId + '&cardToken=' + action.cardToken,
    config: {
      headers: {
        Authorization: token
      }
    }
  }
  try {
    const {data} = yield call(SmartpushAPI.get, options);
    yield put(saveCardFees(data.amount));
  } catch (err) {
    yield put(saveCardFeesError());
  }
}

/**
 * Order / Card request/response handler
 */
export function* getCardTokensDataCall() {
    const token = yield select(getToken());
    const options = {
        url: SMARTPUSH_API_URL_USER_CARD_TOKENS,
        config: {
            headers: {
                Authorization: token
            }
        }
    };

    try {
        const {data} = yield call(SmartpushAPI.get, options);
        yield put(getCardTokensSuccess(data));
    } catch (err) {
        yield put(getCardTokensError(err));
    }
}


interface SaveCardData {
  type: any;
  token: string,
  pan: string,
  brand: string
  expirationDate: string,
}

/**
 * Card Token request/response handler
 */
export function* saveCardTokenDataCall(action: SaveCardData) {
    const token = yield select(getToken());
    const optionsTypes = {
        url: SMARTPUSH_API_URL_CARD_TYPES,
        config: {
            headers: {
                Authorization: token
            }
        }
    };

    const {data} = yield call(SmartpushAPI.get, optionsTypes);
    const cardTypes = data;
    const mappingBrandNameWithCardType = {
        'visa'              : 'visa',
        'cb'                : 'carte bleue',
        'american-express'  : 'american express',
        'maestro'           : 'maestro',
        'mastercard'        : 'mastercard',
        'bcmc'              : 'bancontact',
    };

    const cardTypeFound = cardTypes.find((cardType: any) => (cardType.name === mappingBrandNameWithCardType[action.brand]));

    const options = {
        url: SMARTPUSH_API_URL_USER_CARD_TOKENS,
        config: {
            headers: {
                Authorization: token
            }
        },
        data: {
            name: action.brand,
            token: action.token,
            cardPan: action.pan,
            expirationDate: action.expirationDate,
            cardType: cardTypeFound && cardTypeFound.id
        }
    };

    try {
        yield call(SmartpushAPI.post, options);
        yield put(saveCardTokenDataSuccess());
    } catch (err) {
        yield put(saveCardTokenDataError(err));
    }
}

/**
 * Card Types request/response handler
 */
export function* clearCardTokenDataCall(action: any) {
    const token = yield select(getToken());
    const options = {
        url: injectParameter(SMARTPUSH_API_URL_USER_CARD_TOKEN, action.cardTokenId),
        config: {
            headers: {
                Authorization: token
            }
        }
    };

    try {
        const {data} = yield call(SmartpushAPI.delete, options);
        yield put(clearCardTokenSuccess(data));
        yield put(getCardTokens());
    } catch (err) {
        yield put(clearCardTokenError(err));
    }
}


/**
 * Card Types request/response handler
 */
export function* getCardTypesDataCall() {
    const token = yield select(getToken());
    const optionsTypes = {
        url: SMARTPUSH_API_URL_CARD_TYPES,
        config: {
            headers: {
                Authorization: token
            }
        }
    };

    try {
        const {data} = yield call(SmartpushAPI.get, optionsTypes);
        yield put(getCardTypesSuccess(data));
    } catch (err) {
        yield put(getCardTypesError(err));
    }
}

export function* postOrderDataCall(action : any) {
    const token = yield select(getToken());
    const refreshToken = yield select(getRefreshToken());
    const cardData = yield select(makeSelectCardData());
    let cardToken = yield select(makeSelectCardToken());

    let cardBrand = yield select(makeSelectCardBrandProductName());
    const requestId = yield select(makeSelectRequestId());

    let cardPan = yield select(makeSelectCardPan());
    let cardExpiryDate = yield select(makeSelectCardExpiryDate());
    let reuseCard = false;
    const cardTokens = yield select(makeSelectCardTokens());
    const hipayService = new HipayService();
    const browserInfo = hipayService.getBrowserInfo();

    const origin = window.location.origin;

    //
    // IF AN REGISTERED CARD IS SELECTED OVERWRITE THE TOKEN AND BRAND
    //
    const oldCardSelected = yield select(makeSelectIsOldCardSelected());
    if (oldCardSelected && cardTokens && Array.isArray(cardTokens) && cardTokens.length > 0) {
        cardToken = cardTokens[0]?.token;
        cardBrand = cardTokens[0]?.name;
        cardPan = cardTokens[0]?.pan;
        cardExpiryDate = cardTokens[0]?.expirationDate;
        reuseCard = true;
    }
    const shoppingCartItems = yield select(makeSelectShoppingCartItems());
    const items = Object.keys(shoppingCartItems).map((itemId) => {
        if (shoppingCartItems[itemId].parentId) {
            let product = {
                offerId: shoppingCartItems[itemId].parentId,
                offerVariantPriceId: shoppingCartItems[itemId].id,
                quantity: null,
                amount: null
            };
            if (shoppingCartItems[itemId].freeValue) {
              product = {
                ...product,
                quantity: 1,
                amount: shoppingCartItems[itemId].quantity,
              }
            } else {
              product = {
                ...product,
                quantity: shoppingCartItems[itemId].quantity,
              }
            }

            return product;
        } else {
            let product = {
              offerId: shoppingCartItems[itemId].id,
              quantity: null,
              amount: null,
            }
            if (shoppingCartItems[itemId].freeValue) {
              product = {
                ...product,
                quantity: 1,
                amount: shoppingCartItems[itemId].quantity,
              }
            } else {
              product = {
                ...product,
                quantity: shoppingCartItems[itemId].quantity,
              }
            }

            return product;
        }
    });
    if (cardData['multi_use'] && !oldCardSelected) {
        yield put(saveCardTokenData(cardToken, cardBrand, cardPan, cardExpiryDate));
    }

    const options = {
        url: SMARTPUSH_API_URL_CREATE_ORDER,
        config: {
            headers: {
                Authorization: token,
                'X-Refresh-Token': refreshToken,
            }
        },
        data: {
            cardToken: cardToken,
            cardBrand: cardBrand,
            cardPan: cardPan,
            cardExpirationYear: cardExpiryDate,
            cardExpirationMonth: cardData['card_expiry_month'],
            cardHolder: cardData['card_holder'],
            cardCountry: cardData['card_country'],
            orderLines: items,
            email: action.email,
            address: action.address,
            zipCode: action.zipCode,
            city: action.city,
            browserInfo,
            origin,
            reuseCard,
            saveBillingAddress: action.saveBillingAddress,
            requestId: requestId,
            fromWidget: true
        }
    };

    const paramForLog : any = {
      currency: "EUR",
      value: 0,
      quantity: 0
    }

    let objects;
    Object.keys(shoppingCartItems).map( (item : any) => {
      objects = [{
        // eslint-disable-next-line @typescript-eslint/camelcase
        item_id : shoppingCartItems[item].offerId,
        // eslint-disable-next-line @typescript-eslint/camelcase
        item_name : shoppingCartItems[item].offer,
        // eslint-disable-next-line @typescript-eslint/camelcase
        item_category : shoppingCartItems[item].offerCategories.join(', '),
        // eslint-disable-next-line @typescript-eslint/camelcase
        item_variant : shoppingCartItems[item].variant,
        // eslint-disable-next-line @typescript-eslint/camelcase
        item_brand : shoppingCartItems[item].brand,
        // eslint-disable-next-line @typescript-eslint/camelcase
        quantity : shoppingCartItems[item].quantity,
      }];
      // eslint-disable-next-line @typescript-eslint/camelcase
      paramForLog.quantity += shoppingCartItems[item].quantity,
      // eslint-disable-next-line @typescript-eslint/camelcase
      paramForLog.value += (shoppingCartItems[item].price * shoppingCartItems[item].quantity),
      // eslint-disable-next-line @typescript-eslint/camelcase
      paramForLog.discount_type = shoppingCartItems[item].discountType;
      // eslint-disable-next-line @typescript-eslint/camelcase
      paramForLog.discount_amount = shoppingCartItems[item].faceValue?
        (( 1-(shoppingCartItems[item].price / shoppingCartItems[item].faceValue) ) * 100)
        :
        Number(shoppingCartItems[item].discountAmount)
      ;
    });
    paramForLog.items = objects;

    try {
        const {data, headers} = yield call(SmartpushAPI.post, options);
        const orderId = headers['x-resource-id'];
        const readableId = headers['x-readable-id'];
        // eslint-disable-next-line @typescript-eslint/camelcase
        paramForLog.transaction_id = orderId;

        yield call(logEventCall, {name: "purchase", paramForLog});
        yield put(postOrderDataSuccess(data, orderId, readableId));
    } catch (err) {
        if (err.statusCode === 307) {
            window.location = err.urlForward;
            return;
        } else {
          if (err.error) {
            try {
              paramForLog.transaction_id = err.error?.orderId;
            } catch (e) {
              paramForLog.transaction_id = e.message;
            }
          }
          yield call(logEventCall, {name: "purchase_failed", paramForLog});
        }

        yield put(postOrderDataError(err, err.statusCode));
    }
}

function* logEventCall(action: any) {
  try {
    logEvent(action.name, action.paramForLog);
  } catch(e) {
    console.log('error when logging purchase event', e);
  }
}

export function* getOrderDataAfterRedirectCall(action : any) {
  yield call(persistAccessToken, {
    type: action.type,
    accessToken: action.accessToken,
    refreshToken: ""
  });
  const options = {
    url: injectParameter(SMARTPUSH_API_URL_USER_ORDER, action.orderId),
    config: {
      headers: {
        Authorization: action.accessToken
      }
    }
  };

  try {
    const {data} = yield call(SmartpushAPI.get, options);
    yield put(getOrderDataAfterRedirectSuccess(data, action.orderId));
  } catch (err) {
    yield put(getOrderDataAfterRedirectError(err));
  }
}

export function* persistAccessToken(action: any) {
  try {
    const tokens: any                       = {};
    tokens[LS_OAUTH_ACCESS_TOKEN_KEY]  = action.accessToken;
    tokens[LS_OAUTH_REFRESH_TOKEN_KEY] = action.refreshToken;
    yield localStorage.setItem(LS_OAUTH_TOKENS, JSON.stringify(tokens));
  } catch (e) {
  }
}

export function* persistIframeToken(action: any) {
  try{
    const iframeToken: any = {};
    iframeToken[LS_IFRAME_TOKEN_KEY] = action.iframeToken;
    yield localStorage.setItem(LS_IFRAME_TOKEN, JSON.stringify(iframeToken));
  }catch (e) {
    console.log(e)
  }
}

export function* persistCredentialToken(action: any) {
  try{
    const token: any = {};
    token[LS_CREDENTIAL_TOKEN_KEY] = action.accessToken;
    yield localStorage.setItem(LS_CREDENTIAL_TOKEN, JSON.stringify(token));
  }catch (e) {
    console.log(e)
  }
}

export function* persistAccessTokenKeyCloak(action: any) {
  try{
    const tokensKeyCloak: any = {};
    tokensKeyCloak[LS_KEYCLOAK_ACCESS_TOKEN_KEY] = action.accessToken;
    tokensKeyCloak[LS_KEYCLOAK_REFRESH_TOKEN_KEY] = action.refreshToken;
    tokensKeyCloak[LS_KEYCLOAK_RESPONSE_TOKEN_KEY] = action.responseKeyCloak;
    yield localStorage.setItem(LS_KEYCLOAK_TOKENS, JSON.stringify(tokensKeyCloak));
  }catch (e) {
    console.log(e)
  }
}


export function* removeAccessToken() {
  try {
    yield localStorage.setItem(LS_OAUTH_TOKENS, "");
    yield localStorage.removeItem(LS_OAUTH_TOKENS);
    if (isKeycloakAuthentication()) {
      yield localStorage.removeItem(LS_KEYCLOAK_TOKENS)
      yield localStorage.removeItem(LS_KEYCLOAK_ACCESS_TOKEN_KEY)
      yield localStorage.removeItem(LS_KEYCLOAK_REFRESH_TOKEN_KEY)
    }
    /** Delete the user data from redux */
    yield put(clearData());
  } catch (e) {
    console.log(e);

  }
}

export function* removeAccessTokenKeyCloak(){
  try {
    yield localStorage.setItem(LS_KEYCLOAK_TOKENS, "");
    yield localStorage.removeItem(LS_KEYCLOAK_TOKENS);
    yield put(clearData());
  } catch (e){
    console.log(e);
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* orderSaga() {
    yield takeLatest(SAVE_CARD_DATA, saveCardDataCall);
    yield takeLatest(SAVE_CARD_TOKEN_DATA, saveCardTokenDataCall);
    yield takeLatest(CLEAR_CARD_TOKEN_DATA, clearCardTokenDataCall);
    yield takeLatest(GET_CARD_TOKENS_DATA, getCardTokensDataCall);
    yield takeLatest(POST_ORDER_DATA, postOrderDataCall);
    yield takeLatest(GET_CARD_TYPES_DATA, getCardTypesDataCall);
    yield takeLatest(GET_ORDER_DATA_AFTER_REDIRECT, getOrderDataAfterRedirectCall);
    yield takeLatest(GET_CARD_FEES, getCardFeesCall);
}
