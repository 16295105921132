/*
 * Order Actions
 */

import {
  ADD_ITEM_QUANTITY_TO_CART,
  CLEAR_CARD_TOKEN_DATA,
  CLEAR_CARD_TOKEN_DATA_ERROR,
  CLEAR_CARD_TOKEN_DATA_SUCCESS,
  CLEAR_CART,
  CLEAR_PAYMENT_PAGE,
  GET_CARD_TOKENS_DATA,
  GET_CARD_TOKENS_DATA_ERROR,
  GET_CARD_TOKENS_DATA_SUCCESS,
  GET_CARD_TYPES_DATA,
  GET_CARD_TYPES_DATA_ERROR,
  GET_CARD_TYPES_DATA_SUCCESS,
  POST_ORDER_DATA,
  POST_ORDER_DATA_ERROR,
  POST_ORDER_DATA_SUCCESS,
  REMOVE_ITEM_QUANTITY_FROM_CART,
  SAVE_CARD_DATA,
  SAVE_CARD_DATA_ERROR,
  SAVE_CARD_DATA_SUCCESS,
  SAVE_CARD_TOKEN_DATA,
  SAVE_CARD_TOKEN_DATA_ERROR,
  SAVE_CARD_TOKEN_DATA_SUCCESS,
  UPDATE_CARD_FIELDS,
  UPDATE_SELECTED_CARD,
  SNACKBAR_SYSTEM_OPEN,
  SNACKBAR_SYSTEM_CLOSE,
  GET_ORDER_DATA_AFTER_REDIRECT,
  GET_ORDER_DATA_AFTER_REDIRECT_SUCCESS,
  GET_ORDER_DATA_AFTER_REDIRECT_ERROR,
  GET_CARD_FEES, SAVE_CARD_FEES, SAVE_CARD_FEES_ERROR
} from "../constants/constants";

import { HipayCard } from '../sagas/saga';

/**
 * Add an item's quantity to shoppingCart, this action starts the request saga
 *
 * @param  {object}  item   Item id to add
 * @param  {boolean} hasVariantPrice
 * @param  {number}  variantPriceId
 * @return {object}         An action object with a type of ADD_ITEM_TO_CART
 */
export function addItemQuantityToCart(item: any, hasVariantPrice = false, variantPriceId = '', quantity = 1) {
    return {
        type: ADD_ITEM_QUANTITY_TO_CART,
        item,
        hasVariantPrice,
        variantPriceId,
        quantity
    };
}


/**
 * Remove an item's quantity to shoppingCart, this action starts the request saga
 *
 * @param  {object}  itemId Item id to remove
 *
 * @return {object}         An action object with a type of REMOVE_ITEM_QUANTITY_FROM_CART
 */
export function removeItemQuantityFromCart(itemId: string) {
    return {
        type: REMOVE_ITEM_QUANTITY_FROM_CART,
        itemId
    };
}

/**
 * Remove all item's quantity to shoppingCart
 *
 * @return {object}         An action object with a type of CLEAR_CART
 */
export function clearCart() {
    return {
        type: CLEAR_CART
    };
}

/**
 * Update card fields, this action starts the request saga
 *
 * @param  {object}  fields             Card fields
 *
 * @return {object}                     An action object with a type of SAVE_CARD_DATA
 */
export function updateCardFields(fields: any) {
    return {
        type: UPDATE_CARD_FIELDS,
        fields
    };
}

/**
 * Update selected card, this action starts the request saga
 *
 * @return {object}                     An action object with a type of UPDATE_SELECTED_CARD
 */
export function updateSelectedCard(value: boolean) {
    return {
        type: UPDATE_SELECTED_CARD,
        value
    };
}

/**
 * Save card token, this action starts the request saga
 *
 * @param  {object}  hiPay              PSP
 * @param  {object}  cardDataToSave     Card data retrieve by the PSP
 *
 * @return {object}                     An action object with a type of SAVE_CARD_DATA
 */
export function saveCardData(cardDataToSave: any) {
    return {
        type: SAVE_CARD_DATA,
        cardDataToSave
    };
}

/**
 * Dispatched when the card data is correctly retrieved by the request saga
 *
 * @param  {object}  cardData     Card data retrieve by the PSP
 *
 * @return {object}               An action object with a type of SAVE_CARD_DATA_SUCCESS
 */
export function saveCardDataSuccess(cardData: HipayCard) {
    return {
        type: SAVE_CARD_DATA_SUCCESS,
        cardData
    };
}

/**
 * Dispatched when the card data failed to be retrieved
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of SAVE_CARD_DATA_ERROR passing the error
 */
export function saveCardDataError(error: any) {
    return {
        type: SAVE_CARD_DATA_ERROR,
        error,
    };
}

/**
 * Save card token, this action starts the request saga
 *
 * @param  {object} cardToken       The card token
 * @param  {object} cardBrand       The card brand
 *
 * @return {object}                 An action object with a type of SAVE_CARD_TOKEN_DATA
 */
export function saveCardTokenData(
  token: string,
  brand: string,
  pan: string,
  expirationDate: string
) {
    return {
        type: SAVE_CARD_TOKEN_DATA,
        token,
        brand,
        pan,
        expirationDate
    };
}

/**
 * Dispatched when the card token is correctly registered by the request saga
 *
 * @return {object}               An action object with a type of SAVE_CARD_TOKEN_DATA_SUCCESS
 */
export function saveCardTokenDataSuccess() {
    return {
        type: SAVE_CARD_TOKEN_DATA_SUCCESS
    };
}

/**
 * Dispatched when the card data failed to be retrieved
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of SAVE_CARD_TOKEN_DATA_ERROR passing the error
 */
export function saveCardTokenDataError(error: any) {
    return {
        type: SAVE_CARD_TOKEN_DATA_ERROR,
        error,
    };
}

/**
 * Save card token, this action starts the request saga
 *
 * @return {object}                     An action object with a type of GET_CARD_TYPES_DATA
 */
export function getCardTypes() {
    return {
        type: GET_CARD_TYPES_DATA
    };
}

/**
 * Dispatched when the card token is correctly registered by the request saga
 *
 * @param  {object} cardTypes     Card types return by API
 *
 * @return {object}               An action object with a type of GET_CARD_TYPES_DATA_SUCCESS passing card types
 */
export function getCardTypesSuccess(cardTypes: any) {
    return {
        type: GET_CARD_TYPES_DATA_SUCCESS,
        cardTypes
    };
}

/**
 * Dispatched when the card data failed to be retrieved
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of GET_CARD_TYPES_DATA_ERROR passing the error
 */
export function getCardTypesError(error: any) {
    return {
        type: GET_CARD_TYPES_DATA_ERROR,
        error,
    };
}


/**
 * Save card token, this action starts the request saga
 *
 * @return {object}                 An action object with a type of GET_CARD_TOKEN_DATA
 */
export function getCardTokens() {
    return {
        type: GET_CARD_TOKENS_DATA
    };
}

/**
 * Dispatched when the card token is correctly registered by the request saga
 *
 * @return {object}               An action object with a type of GET_CARD_TOKEN_DATA_SUCCESS passing the card tokens
 */
export function getCardTokensSuccess(cardTokens: any) {
    return {
        type: GET_CARD_TOKENS_DATA_SUCCESS,
        cardTokens
    };
}

/**
 * Dispatched when the card data failed to be retrieved
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of GET_CARD_TOKEN_DATA_ERROR passing the error
 */
export function getCardTokensError(error: any) {
    return {
        type: GET_CARD_TOKENS_DATA_ERROR,
        error,
    };
}


/**
 * Clear card token, this action starts the request saga
 *
 * @param  {object} cardTokenId   Card token id to clear
 *
 * @return {object}             An action object with a type of CLEAR_CARD_TOKEN_DATA
 */
export function clearCardToken(cardTokenId: string) {
    return {
        type: CLEAR_CARD_TOKEN_DATA,
        cardTokenId
    };
}

/**
 * Dispatched when the card token is correctly registered by the request saga
 *
 * @return {object}               An action object with a type of CLEAR_CARD_TOKEN_DATA_SUCCESS
 */
export function clearCardTokenSuccess(cardTokens: any) {
    return {
        type: CLEAR_CARD_TOKEN_DATA_SUCCESS,
        cardTokens
    };
}

/**
 * Dispatched when the card data failed to be cleared
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of CLEAR_CARD_TOKEN_DATA_ERROR passing the error
 */
export function clearCardTokenError(error: any) {
    return {
        type: CLEAR_CARD_TOKEN_DATA_ERROR,
        error,
    };
}

/**
 * Post order data, this action starts the request saga
 *
 * @param  {string}  email    Order data retrieve by the API
 *
 * @return {object}           An action object with a type of POST_ORDER_DATA
 */
export function postOrderData(email: string, address: string, zipCode: string, city: string, saveBillingAddress: boolean) {
    return {
        type: POST_ORDER_DATA,
        email,
        address,
        zipCode,
        city,
        saveBillingAddress
    };
}

/**
 * Dispatched when the order data is correctly sent by the request saga
 *
 * @param  {object}  orderData    Order data retrieve by the API
 * @param  {string}  orderId      Order id retrieved by the API in headers
 * @param  {string}  readableId   Order readable Id
 *
 * @return {object}               An action object with a type of POST_ORDER_DATA_SUCCESS
 */
export function postOrderDataSuccess(orderData: any, orderId: string, readableId: string) {
    return {
        type: POST_ORDER_DATA_SUCCESS,
        orderData,
        orderId,
        readableId
    };
}

/**
 * Dispatched when the order data failed to be sent
 *
 * @param  {object} error       The error
 * @param  {number} statusCode  The error's status code
 *
 * @return {object}             An action object with a type of POST_ORDER_DATA_ERROR passing the error
 */
export function postOrderDataError(error: any, statusCode: number) {
    return {
        type: POST_ORDER_DATA_ERROR,
        error,
        statusCode
    };
}

/**
 * Get order data, this action starts the request saga
 *
 * @param  {string}  orderId      Order id retrieve by hiPay
 * @param  {string}  accessToken  Access token retrieve by hiPay
 *
 * @return {object}           An action object with a type of POST_ORDER_DATA
 */
export function getOrderDataAfterRedirect(orderId: any, accessToken: any) {
  return {
    type: GET_ORDER_DATA_AFTER_REDIRECT,
    orderId,
    accessToken
  };
}

/**
 * Dispatched when the order data is correctly retrieved by the request saga
 *
 * @param  {object}  orderData    Order data retrieve by the API
 * @param  {string}  orderId      Order id retrieved by the API in headers
 *
 * @return {object}               An action object with a type of POST_ORDER_DATA_SUCCESS
 */
export function getOrderDataAfterRedirectSuccess(orderData: any, orderId: string) {
  return {
    type: GET_ORDER_DATA_AFTER_REDIRECT_SUCCESS,
    orderData,
    orderId
  };
}

/**
 * Dispatched when the order data failed to be retrieved
 *
 * @param  {object} error       The error
 *
 * @return {object}             An action object with a type of POST_ORDER_DATA_ERROR passing the error
 */
export function getOrderDataAfterRedirectError(error: any) {
  return {
    type: GET_ORDER_DATA_AFTER_REDIRECT_ERROR,
    error
  };
}

/**
 * Remove all from payment reducer
 *
 * @return {object}         An action object with a type of CLEAR_PAYMENT_PAGE
 */
export function clearPaymentPage() {
    return {
        type: CLEAR_PAYMENT_PAGE
    };
}


/**
 * Dispatched when a snackbar will be displayed
 *
 * @param  {object} typeOfAlert        Type of alert
 * @param  {object} content            Content of snackbar
 *
 * @return {object}                    An action object with a type of SNACKBAR_SYSTEM_OPEN passing the content and type
 */
export function displaySnackBar(typeOfAlert: string, content: any) {
  return {
    type: SNACKBAR_SYSTEM_OPEN,
    typeOfAlert,
    content
  };
}

/**
 * Dispatched when a snackbar will be hidden
 *
 * @return {object}             An action object with a type of CLEAR_DATA passing the offers
 */
export function hideSnackBar() {
  return {
    type: SNACKBAR_SYSTEM_CLOSE,
  };
}

export function getCardFees(requestId: string, cardToken: string) {
  return {
    type: GET_CARD_FEES,
    requestId,
    cardToken
  }
}

export function saveCardFees(amount: number) {
  return {
    type: SAVE_CARD_FEES,
    amount,
  }
}

export function saveCardFeesError() {
  return {
    type: SAVE_CARD_FEES_ERROR
  }
}
