import makeStyles from '@material-ui/core/styles/makeStyles';
import { Theme } from '@material-ui/core';

export const useStylesOrderContent = makeStyles((theme: Theme) => ({
  root: {
    paddingLeft: '20px',
    paddingRight: '20px'
  },
  product: {
    marginBottom: '8px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    '& p': { //Typography
      flexGrow: 1,
      fontSize: '16px',
      lineHeight: '19px',
      fontFamily: 'ProximaNovaBold',
      paddingRight: 8
    },
    '& span': { //Price
      flexWrap: 'nowrap'
    }
  },
  offerVariantMobile: {
    marginBottom: '8px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    '& p': {
      flexGrow: 1,
      fontSize: '16px',
      lineHeight: '19px',
      paddingRight: 8
    },
    '& span': {
      paddingLeft: "10px",
      flexWrap: 'nowrap'
    }
  },
  offerVariant: {
    marginBottom: '8px',
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
    '& p': {
      flexGrow: 1,
      fontSize: '16px',
      lineHeight: '19px',
      paddingRight: 4,
      paddingLeft: 4
    },
    '& span': {
      flexWrap: 'nowrap'
    }
  }
}));
