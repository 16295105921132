import React, { useState }          from 'react';
import Grid                         from '@material-ui/core/Grid';
import makeStyles                   from '@material-ui/core/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty                      from 'lodash/isEmpty';
import Typography                   from '@material-ui/core/Typography';
import clsx                         from 'clsx';
import CreditCardViewerEmptySvg          from './CreditCardViewerEmpty.svg';
import DeleteIcon                   from '@material-ui/icons/Delete';
import { IconButton, Theme }               from '@material-ui/core';
import { makeSelectCardTokens }     from '../../../store/selectors/selectors';
import { makeSelectUserData }       from '../../../store/selectors/userSelector';
import { clearCardToken }           from '../../../store/actions/actions';

import {SMARTPUSH_API_BASE_URL} from '@smartpush-front/api';
import {COLORS} from "@smartpush-front/styles";
import {ImageService} from "../../../../../../../apps/phoenix/src/utils/imageUtil";

const useCreditCardViewerStyles = makeStyles((theme: Theme) => ({
  container        : {
    position          : 'relative',
    marginLeft        : 25.5,
    marginRight       : 56,
    width             : 285,
    height            : 177,
    '&:hover'         : {},
  },
  svg        : {
    position          : 'absolute',
    marginLeft        : 25.5,
    marginRight       : 56,
    zIndex            : 0,
    borderRadius      : 8,
    boxShadow         : '0 2px 4px 0 rgba(152,152,152,0.62)',
    width             : 285,
    height            : 177,
  },
  overlay          : {
    position     : 'absolute',
    borderRadius : 8,
    top          : 0,
    height       : 177,
    width        : 285,
    zIndex        : 99,
    display      : 'flex',
    flexDirection: 'column',
    flex         : 1,
    alignItems   : 'flex-end',
    justifyItems : 'flex-end',
  },
  overlayColor     : {
    backgroundColor: 'rgba(0,0, 0, 0.3)',
  },
  cardLogo         : {
    width: 40,
  },
  cardOwnerName    : {
    paddingLeft: 24,
    paddingTop : 24,
    fontSize   : '16px',
    lineHeight : '19px',
    fontWeight : 'bold',
    color      : 'white',
    maxWidth   : 100,
  },
  lowFields        : {
    marginTop: 40,
  },
  lowContainer     : {
    height: '75px',
  },
  cardOwnerNumber  : {
    paddingTop: 0,
    opacity   : '0.82',
    maxWidth  : 280,
  },
  cardOwnerValidity: {
    opacity   : '0.82',
    maxWidth  : 280,
    paddingTop: 4,
  },
}));


function CardViewerSvg({className}: any) {
  return (
    <svg width="285" height="177" className={className}>
      <g className="layer">
        <title>Layer 1</title>
        <rect stroke="#000000" id="svg_3" height="210.85879" width="320.60185" y="-19.43519" x="-20.49076"
              stroke-linecap="null" stroke-linejoin="null" stroke-dasharray="null" stroke-width="0" fill="#ffffff"/>
        <path stroke="#000000" id="svg_7"
              d="m-0.93752,181.29335l176.02812,0.39415c19.83963,-33.88172 42.79136,-55.68639 42.59686,-96.96004c-0.19451,-41.27364 -23.14624,-62.63551 -44.93093,-88.09685l-172.7215,-0.00561c-0.32418,61.55611 -0.64836,123.11223 -0.97254,184.66835z"
              stroke-linecap="null" stroke-linejoin="null" stroke-dasharray="null" stroke-width="0" fill={COLORS.primary}/>
      </g>
    </svg>
  );
}

export function CreditCardViewer() {
  const dispatch                    = useDispatch();
  const [ isHovered, setIsHovered ] = useState(false);
  const creditCardTokens            = useSelector(makeSelectCardTokens());
  const userData                    = useSelector(makeSelectUserData());
  const creditCardToken             = creditCardTokens.length > 0 ? creditCardTokens[0] : {};

  const classes            = useCreditCardViewerStyles();
  const handleOnMouseEnter = () => {
    setIsHovered(true);
  };
  const handleOnMouseLeave = () => {
    setIsHovered(false);
  };

  const handleClearCreditCardToken = () => {
    if (!isEmpty(creditCardToken)) {
      dispatch(clearCardToken(creditCardToken.id));
    }
  };

  return (
    <>
      <CardViewerSvg className={classes.svg} />
      {!isEmpty(creditCardToken) && (
      <Grid container className={classes.container} onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave}>
        {isHovered && (
          <>
            <div className={clsx(classes.overlay, classes.overlayColor)} />
            <div className={clsx(classes.overlay)}>
              <IconButton onClick={() => handleClearCreditCardToken()}>
                <DeleteIcon color="primary" />
              </IconButton>
            </div>
          </>
        )}
        <>
            <Grid item xs={12}>
              <Typography className={classes.cardOwnerName}>
                {userData.firstName + ' ' + userData.lastName}
              </Typography>
            </Grid>
            <Grid item xs={12} className={classes.lowFields}>
                <Grid container>
                    <Grid item xs={9}>
                        <Grid container >
                            <Grid item xs={12}>
                                <Typography className={clsx(classes.cardOwnerName, classes.cardOwnerNumber)}>
                                    {creditCardToken.pan}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={clsx(classes.cardOwnerName, classes.cardOwnerValidity)}>
                                    xx/{creditCardToken.expirationDate.substr(2)}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={3} >
                        <Grid container className={classes.lowContainer} justify="center" alignItems='center'>
                            <Grid item>
                                <img alt="bank logo" src={`${ImageService.getBaseUrlMedia()}/${creditCardToken.cardType.logo}`} className={classes.cardLogo}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
          </>
      </Grid>
      )}
      {isEmpty(creditCardToken) && (
        <Grid container className={classes.container} style={{background: `url("${CreditCardViewerEmptySvg}")`}}>
        </Grid>
      )}
    </>
  );
}
