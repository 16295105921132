/*
 * Order reducer
 */

import produce from 'immer';
import {
  ADD_ITEM_QUANTITY_TO_CART,
  CLEAR_CART,
  CLEAR_PAYMENT_PAGE, GET_CARD_FEES,
  GET_CARD_TOKENS_DATA_SUCCESS,
  GET_CARD_TYPES_DATA_SUCCESS,
  GET_ORDER_DATA_AFTER_REDIRECT,
  GET_ORDER_DATA_AFTER_REDIRECT_ERROR,
  GET_ORDER_DATA_AFTER_REDIRECT_SUCCESS,
  POST_ORDER_DATA,
  POST_ORDER_DATA_ERROR,
  POST_ORDER_DATA_SUCCESS,
  REMOVE_ITEM_QUANTITY_FROM_CART,
  SAVE_CARD_DATA,
  SAVE_CARD_DATA_ERROR,
  SAVE_CARD_DATA_SUCCESS, SAVE_CARD_FEES, SAVE_CARD_FEES_ERROR,
  UPDATE_CARD_FIELDS,
  UPDATE_SELECTED_CARD,
} from '../constants/constants';

import { Card } from '../../../interfaces';

// The initial state of the OfferShopList reducer
export const initialState: OrderReducerProps = {
  shoppingCart: {},
  cardData: null,
  cardTypes: [],
  cardTokens: [],
  isOldCardSelected: false,
  fields: {
    cardNumber: '',
    expirationDate: '',
    cvc: '',
    multiUse: false,
  },
  loading: false,
  orderFromRedirectLoading: false,
  error: null,
  orderDataSending: false,
  orderDataResponse: {},
  orderDataError: {
    error: null,
    statusCode: null,
    orderId: null,
  },
  fees: null,
  feesError: false,
  feesLoading: false
};

interface OrderReducerProps {
  shoppingCart: any;
  cardData: Card;
  cardTypes: any;
  cardTokens: any;
  isOldCardSelected: boolean;
  fields: {
    cardNumber: string;
    expirationDate: string;
    cvc: string;
    multiUse: boolean;
  };
  loading: boolean;
  orderFromRedirectLoading: boolean;
  error: any;
  orderDataSending: boolean;
  orderDataResponse: any;
  orderDataError: {
    error: any;
    statusCode: any;
    orderId: any;
  };
  fees: number|null,
  feesError: boolean,
  feesLoading: boolean
}

export const orderReducer = (
  state: OrderReducerProps = initialState,
  action: any
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ADD_ITEM_QUANTITY_TO_CART:
      // eslint-disable-next-line no-case-declarations
        const id = action.hasVariantPrice
          ? action.item.offerVariantPrices.find(variant => variant.id === action.variantPriceId).id
          : action.item.id;

        // eslint-disable-next-line no-case-declarations
        const item = action.hasVariantPrice
          ? {
              ...action.item.offerVariantPrices.find(variant => variant.id === action.variantPriceId),
              offer: action.item.offerVariantPrices.find(variant => variant.id === action.variantPriceId).name,
              description: action.item.description,
              parentId: action.item.id,
              parentOffer: action.item.offer,
              offerId: action.item.id,
              offerType: action.item.offerType,
              offerCategories: Array.isArray(action.item.offerCategories) ? action.item.offerCategories.map( (offerCategory : any) => {
                return offerCategory.name;
              } ) : [],
              brand: action.item.brand.name,
              variant: action.item.offerTypeEnhanced,
              discountType: action.item.discountType,
              quantity: action.item.quantity,
              discountAmount: action.item.discountAmount
            }
          : {
              ...action.item,
            };

        draft.shoppingCart = {
          ...state.shoppingCart,
          [id]: state.shoppingCart[id]
            ? {
                ...state.shoppingCart[id],
                quantity: action.item.freeValue ? Number(action.quantity) : state.shoppingCart[id].quantity + action.quantity,
              }
            : {
                ...item,
                quantity: action.quantity,
              },
        };
        break;
      case REMOVE_ITEM_QUANTITY_FROM_CART:
        if (
          state.shoppingCart[action.itemId] &&
          state.shoppingCart[action.itemId].quantity > 1
        ) {
          draft.shoppingCart = {
            ...state.shoppingCart,
            [action.itemId]: {
              ...state.shoppingCart[action.itemId],
              quantity: state.shoppingCart[action.itemId].quantity - 1,
            },
          };
          break;
        }
        delete draft.shoppingCart[action.itemId];
        break;
      case CLEAR_CART:
        draft.shoppingCart = {};
        break;
      case UPDATE_CARD_FIELDS:
        draft.fields = action.fields;
        draft.cardData = null;
        draft.error = null;
        break;
      case UPDATE_SELECTED_CARD:
        draft.isOldCardSelected = action.value; //!state.isOldCardSelected;
        if (action.value) {
          draft.cardData = {
            token: draft.cardTokens[0].token,
            brand: draft.cardTokens[0].name.toUpperCase(),
            // eslint-disable-next-line @typescript-eslint/camelcase
            payment_product: draft.cardTokens[0].name,
            pan: draft.cardTokens[0].pan,
            // eslint-disable-next-line @typescript-eslint/camelcase
            card_expiry_year: draft.cardTokens[0].expirationDate
          }
        } else {
          draft.cardData = null
        }
        break;
      case SAVE_CARD_DATA:
        draft.loading = true;
        draft.error = null;
        break;
      case SAVE_CARD_DATA_SUCCESS:
        draft.loading = false;
        draft.cardData = action.cardData;
        break;
      case SAVE_CARD_DATA_ERROR:
        draft.loading = false;
        draft.error = action.error;
        draft.fees = null;
        draft.feesError = false;
        break;
      case POST_ORDER_DATA:
        draft.orderDataSending = true;
        draft.orderDataError = {
          error: null,
          statusCode: null,
          orderId: null
        };
        break;
      case POST_ORDER_DATA_SUCCESS:
        draft.orderDataSending = false;
        draft.orderDataResponse = {
          ...action.orderData,
          orderId: action.orderId,
          readableId: action.readableId
        };
        break;
      case POST_ORDER_DATA_ERROR:
        draft.orderDataSending = false;
        draft.orderDataError.error = action.error;
        draft.orderDataError.statusCode = action.statusCode;
        draft.orderDataError.orderId = action.error?.error?.orderId;
        break;
      case GET_CARD_TYPES_DATA_SUCCESS:
        draft.cardTypes = action.cardTypes;
        break;
      case GET_CARD_TOKENS_DATA_SUCCESS:
        draft.cardTokens = action.cardTokens;
        break;
      case CLEAR_PAYMENT_PAGE:
        return initialState;
      case GET_ORDER_DATA_AFTER_REDIRECT:
        draft.orderFromRedirectLoading = true;
        break;
      case GET_ORDER_DATA_AFTER_REDIRECT_SUCCESS:
        draft.orderFromRedirectLoading = false;
        // eslint-disable-next-line no-case-declarations
        let shoppingCart = {};
        action.orderData.orderLines.forEach((orderLine) => {
          shoppingCart = {
            ...shoppingCart,
            [orderLine.id]: orderLine.variantName
              ? {
                price: orderLine.price,
                offer: orderLine.variantName,
                description: orderLine.description,
                parentOffer: orderLine.summary,
                parentId: orderLine.offerId,
                quantity: orderLine.quantity,
                offerCategories: orderLine.offerCategories,
                brand: orderLine.brand,
                faceValue: orderLine.faceValue
              }
              : {
                price: orderLine.price,
                offer: orderLine.summary,
                description: orderLine.description,
                quantity: orderLine.quantity,
                offerCategories: orderLine.offerCategories,
                brand: orderLine.brand,
                faceValue: orderLine.faceValue
              }
          };
        });
        if (action.orderData.transactions && Array.isArray(action.orderData.transactions) && action.orderData.transactions[0]) {
          draft.cardData = {
            token: "",
            brand: action.orderData.transactions[0].card.brand.toUpperCase(),
            // eslint-disable-next-line @typescript-eslint/camelcase
            payment_product: action.orderData.transactions[0].card.brand,
            pan: action.orderData.transactions[0].card.pan,
            // eslint-disable-next-line @typescript-eslint/camelcase
            card_expiry_year: action.orderData.transactions[0].card.expirationDate
          }
        }
        draft.shoppingCart = shoppingCart;
        draft.orderDataResponse = {
          orderId: action.orderId,
          amount: action.orderData.amount,
          fees: action.orderData.fees,
        };
        break;
      case GET_ORDER_DATA_AFTER_REDIRECT_ERROR:
        draft.orderFromRedirectLoading = false;
        break;
      case GET_CARD_FEES:
        draft.fees = null;
        draft.feesError = false;
        draft.feesLoading = true;
        break;
      case SAVE_CARD_FEES:
        draft.fees = action.amount;
        draft.feesError = false;
        draft.feesLoading = false;
        break;
      case SAVE_CARD_FEES_ERROR:
        draft.fees = null;
        draft.feesError = true;
        draft.feesLoading = false;
        break;
    }
  });
