import React, {useEffect} from "react";

import clsx from "clsx";

import {useDispatch, useSelector} from "react-redux";

import makeStyles from "@material-ui/core/styles/makeStyles";

import { USER_ORDER } from "@smartpush-front/payment";
import {
  makeSelectUserOrders,
  getUserOrders,
  makeSelectUserData,
  makeSelectUserDataOrderloading
} from '@smartpush-front/store';

import {COLORS} from "@smartpush-front/styles";

import Typography from "@material-ui/core/Typography";

import {Grid, useMediaQuery, Theme, CircularProgress} from "@material-ui/core";
import {useInjectSaga} from "@smartpush-front/api";

import saga from "../../store/saga";

import { OrdersHistoryDesktop } from './components/OrdersHistoryDesktop';
import { OrdersHistoryMobile } from './components/OrdersHistoryMobile';

import "./style.scss";

const useStylesOrdersHistory = makeStyles((theme: Theme) => ({
  card: {
    maxWidth: 745,
    borderRadius: 5,
    backgroundColor: COLORS.white,
    padding: theme.spacing(2, 0, 2, 0),
    boxShadow: '0 2px 4px 0 rgba(152,152,152,0.62)',
  },
  horizontalPadding: {
    paddingLeft: 56,
    paddingRight: 56,
  },
  title: {
    fontWeight: 800,
    fontFamily: 'ProximaNovaBold',
    color: 'black'
  },
  titleSub: {
    fontWeight: 800,
    fontSize: 16,
    paddingBottom: 0,
    paddingTop: 16
  }
}));


export function OrdersHistory({detailRoute = USER_ORDER}) {
  const dispatch = useDispatch();

  const classes = useStylesOrdersHistory();
  const ordersData = useSelector(makeSelectUserOrders());
  const ordersDataLoading = useSelector(makeSelectUserDataOrderloading());

  const userData = useSelector(makeSelectUserData());
  const isMobile = useMediaQuery('(max-width:767px)');

  useInjectSaga({key: 'userOrder', saga});

  useEffect(() => {
    if (userData?.email) {
      dispatch(getUserOrders());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userData]);

  const renderContent = () => {
    if (ordersDataLoading) {
      return (
        <Grid container justify="center" alignItems="center">
          <CircularProgress size={18} />
        </Grid>
      )
    }

    if (ordersData.length === 0) {
      return (
        <Grid container justify="center" alignItems="center">
          Aucune commande à ce jour.
        </Grid>
      )
    }

    return (
      <div style={{width: '100%'}} className={"order-history-row "+classes.horizontalPadding}>
        {isMobile ? <OrdersHistoryMobile ordersData={ordersData} detailRoute={detailRoute} /> : <OrdersHistoryDesktop ordersData={ordersData} detailRoute={detailRoute} />}
      </div>
    )
  }

  return (
    <>
      <Typography variant="h6" className={"order-history-title "+clsx(classes.title, classes.titleSub, classes.horizontalPadding)} noWrap>
        Historique des commandes
      </Typography>
      {renderContent()}
    </>
  )
}
