export const COLORS = {
    white: '#FFFFFF',
    black: '#000000',
    grey: '#a3a3a3',
    greyCategory: "#a6b3c6",
    greyDarker: '#7e7e7e',
    greyLighter: '#f0f0f0',
    greyLighter2: '#878B8D',
    error: '#d32f2f',
    errorDarker: '#b71c1c',
    primary: '#DD054A',
    primaryDarker: '#AB0338',
    primaryLighter: '#339FAB',
    greyPrimaryLighter: '#d6d6d6',
    secondary: '#61cd8d',
    secondaryDarker: '#52A46E',
};

export const FORMS_STYLES = {
    borderRadiusTextField: 25,
};