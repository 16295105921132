import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import { useStylesOrderContent } from './styles';

import { Offer } from '../../../../interfaces/Offer';
import {OrderContentFixVariant} from "./OrderContentFixVariant";
import {OrderContentFreeVariant} from "./OrderContentFreeVariant";
import {SETTINGS} from "@smartpush-front/styles";
import {Typography} from "@material-ui/core";

interface Props {
  items: Offer[];
  pendingOrSuccess: boolean
}


function OrderContent({items, pendingOrSuccess}: Props) {
  const brand = items[0] ? items[0].brand : ''

  const classes = useStylesOrderContent();

  if (!items || items.length === 0) {
    return (<div>Commande vide...</div>)
  }
  const offers: Offer[] = items.reduce((res, elem) => {
    if (elem.parentId && !res.find((offer) => offer.id === elem.parentId)) {
      return [
        ...res,
        {
          id: elem.parentId,
          offer: elem.parentOffer,
          description: elem.description,
          price: items.reduce(
            (accu, offer) =>
              offer.parentId === elem.parentId
                ? accu + offer.price * offer.quantity
                : accu,
            0
          ),
          hasChildren: !!elem.parentId,
          children: items.reduce(
            (accu, offer) =>
              offer.parentId === elem.parentId ? [...accu, offer] : [...accu],
            []
          ),
        },
      ];
    } else if (!elem.parentId) {
      return [
        ...res,
        {
          id: elem.id,
          offer: elem.offer,
          description: elem.description,
          price: elem.price * elem.quantity,
          quantity: elem.quantity,
          hasChildren: false,
          children: [],
        },
      ];
    } else {
      return [...res];
    }
  }, []);
  return (
    <Grid container className={classes.root}>
      {Array.isArray(offers) &&
        offers.length > 0 &&
        offers.map((product, index: number) => (
          <React.Fragment key={index}>
            {product.hasChildren &&
              product.children.map((child: any, index2) => {
                return child.freeValue ?
                  <OrderContentFreeVariant element={child} key={index2} pendingOrSuccess={pendingOrSuccess} /> :
                  <OrderContentFixVariant element={child} key={index2} pendingOrSuccess={pendingOrSuccess} />
              })}
          </React.Fragment>
        ))}
    </Grid>
  );
}
export default OrderContent;
