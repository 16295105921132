import {useMatomo} from "@jonkoops/matomo-tracker-react";
import {isArray} from "lodash";
import {SETTINGS} from "@smartpush-front/styles";

const defaultCustomDimensions = {
  screen_category: {
    prod: 1,
    preprod: 1
  },
  filter_name: {
    prod: 2,
    preprod: 2
  },
  item_brand: {
    prod: 3,
    preprod: 3
  },
  item_provider: {
    prod: 4,
    preprod: 4
  },
  item_type: {
    prod: 5,
    preprod: 5
  },
  discount_type: {
    prod: 6,
    preprod: 6
  },
  discount_amount: {
    prod: 7,
    preprod: 7
  },
  currency: {
    prod: 8,
    preprod: 8
  },
  sign_up_step: {
    prod: 9,
    preprod: 9
  },
  profil_step: {
    prod: 10,
    preprod: 10
  },
  brand_id: {
    prod: 11,
    preprod: 11
  },
  brand_name: {
    prod: 12,
    preprod: 12
  }
}

export const useTrackingMatomo = () => {
  const { trackPageView, trackEvent, trackSiteSearch, pushInstruction } = useMatomo();

  const buildCustomDimensions = (data:object) => {
    const customDimensions = [];
    const env = process.env.REACT_APP_APP_ENV === 'prod' ? 'prod' : 'preprod';

    const selectedCustomDimensions = SETTINGS?.matomo?.customDimensions ?? defaultCustomDimensions;

    for (let prop in data) {
      if (selectedCustomDimensions[prop] && selectedCustomDimensions[prop][env]) {
        customDimensions.push({
          id: selectedCustomDimensions[prop][env],
          value: data[prop]
        });
      }
    }

    if (data['ecommerce'] && data['ecommerce']['items'] && data['ecommerce']['items'][0]) {
      return customDimensions.concat(buildCustomDimensions(data['ecommerce']['items'][0]));
    }

    return customDimensions;
  }

  const addECommerceItems = (items:[]) => {
    let totalAmount = 0;

    items.forEach((item:any) => {
      totalAmount += (item.item_price ?? 0) * (item.quantity || 0);

      pushInstruction(
        'addEcommerceItem',
        item.item_id,
        `${item.item_brand} - ${item.item_name}`,
        item.item_category,
        item.item_price,
        item.quantity
      );
    });

    return totalAmount;
  }

  const setECommerceView = (items:[]) => {
    items.forEach((item:any) => {
      pushInstruction(
        'setEcommerceView',
        '' + item.item_id,
        `${item.item_brand} - ${item.item_name}`,
        item.item_category
      );
    });
  };

  const sendMatomo = (data: any, category: any) => {
    let totalAmount = 0;

    for (let i = 1; i < 26; i++){
      pushInstruction('deleteCustomDimension', i)
    }

    // Send event

    const defaultParams = {
      documentTitle: data?.screen_name ?? window.document.title,
      customDimensions: buildCustomDimensions(data)
    }

    const defaultParamsEvent = {
      ...defaultParams,
      name: data?.name || window.location.pathname,
      action: data?.event,
      category: category,
    }

    if (data.user_id) {
      pushInstruction('setUserId', data.user_id);
    }

    switch (data.event) {
      // ECOMMERCE EVENTS

      case "categories_view":
        pushInstruction('setEcommerceView', false, false, data?.categories_selected);
        trackPageView({...defaultParams});
        break;

      case "view_item":
        setECommerceView(data?.ecommerce?.items);
        trackPageView({...defaultParams});
        break;

      case "coupon_selected":
      case "coupon_redirection":
      case "coupon_download":
        trackEvent({
          ...defaultParamsEvent
        });
        break;

      case "begin_checkout":
        trackPageView({...defaultParams});
        totalAmount = addECommerceItems(data?.ecommerce?.items ?? []);
        pushInstruction('trackEcommerceCartUpdate', totalAmount);
        break;

      case "purchase":
        trackPageView({...defaultParams});
        totalAmount = addECommerceItems(data?.ecommerce?.items ?? []);
        pushInstruction('trackEcommerceOrder', data?.ecommerce?.transaction_id, totalAmount);
        break;

      case "purchase_failed":
        trackPageView({...defaultParams});
        trackEvent({...defaultParamsEvent});
        break;

      case "remove_from_wishlist":
      case "add_to_wishlist":
        setECommerceView(data?.ecommerce?.items);
        trackEvent({...defaultParamsEvent});
        break;

      // DEFAULT HANDLERS

      case "page_view":
        trackPageView({...defaultParams});
        break;

      case "filter":
        const keyword = isArray(data?.filter_name) ? data.filter_name.join(', ') : data?.filter_name;
        if (!keyword) { break; }

        trackSiteSearch({
          keyword: keyword,
          category: data.filter_category
        });
        break;

      default:
        trackEvent({...defaultParamsEvent});
        break;
    }
  }

  return {sendMatomo}
}
