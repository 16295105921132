import React from "react";

import {OrderStatus} from './OrderStatus';

import moment from "moment";
import {makeStyles, Theme, darken, useMediaQuery} from "@material-ui/core";
import {FORMS_STYLES} from "@smartpush-front/styles";

import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";

import { injectParameter } from "@smartpush-front/payment";
import { history } from '@smartpush-front/store';

import { buildUrl } from '@smartpush-front/api';

const useStylesOrdersHistory = makeStyles((theme: Theme) => ({
  cardItem: {
    padding: '4px',
  },
  cardPictureDiv: {
    height: '64px',
    width: '64px',
    marginRight: 16,
    display: "flex",
    flex: 1,
    alignItems: "center",
    boxShadow: '0 2px 4px 0 rgba(152,152,152,0.62)',
    borderRadius: 5,
  },
  cardPicture: {
    borderRadius: 5,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    maxHeight: '64px',
    maxWidth: '64px',
  },
  paymentButton: {
    borderRadius: FORMS_STYLES.borderRadiusTextField,
    height: '34px',
    width: '100%',
    maxWidth: 240,
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.main, 0.3)
    }
  },
  paymentButtonTypo: {
    fontSize: '16px',
    fontFamily: 'ProximaNovaBold'
  },
  centeredButton: {
    display: 'flex',
    justifyContent: 'center'
  },
  item: {
    display: 'flex',
    borderTop: '1px solid #CCC',
    marginTop: 16,
    paddingTop: 16,
    '& .content': {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      '& .row': {
        fontSize: 16,
        marginBottom: 16,
        display: 'flex',
        flexDirection: 'row',
        '& .label': {
          fontFamily: 'ProximaNovaBold'
        },
        '& .value': {
          flexGrow: 1,
          textAlign: 'right'
        }
      }
    }
  }
}));

export function OrdersHistoryMobile({ordersData, detailRoute}) {
  const classes = useStylesOrdersHistory();
  const isMobile = useMediaQuery('(max-width:767px)');

  return (
    <div>
      {ordersData.map((row: any, index: number) => (
        <React.Fragment key={index}>
          <div className={classes.item}>
            <div className={"cardPictureDiv "+classes.cardPictureDiv}>
              <img alt='card' src={buildUrl(row.firstOrderLineFullImage)} className={"cardPicture "+classes.cardPicture}/>
            </div>
            <div className="content">
              <div className="row">
                <div className="label">Statut</div>
                <div className="value"><OrderStatus status={row.status} isMobile={isMobile}/></div>
              </div>
              <div className="row">
                <div className="label">Date</div>
                <div className="value">{moment(row.createdAt.date).format('DD/MM/YYYY')}</div>
              </div>
              <div className="row">
                <div className="label">Num.</div>
                <div className="value">{row.readableId}</div>
              </div>
              <div className="row">
                <div className="label">Montant</div>
                <div className="value">{row.amount.toFixed(2)}€</div>
              </div>
            </div>
          </div>
          <div className={classes.centeredButton}>
            <Fab color="primary" className={classes.paymentButton} onClick={() => history.push(injectParameter(detailRoute, row.id))}>
              <Typography className={classes.paymentButtonTypo}>
                Voir les détails
              </Typography>
            </Fab>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}
