import React from 'react';
import { Route } from 'react-router-dom';
import { PaymentPage } from './pages/PaymentPage/PaymentPage';
import { OrdersPage } from './pages/OrdersPage/OrdersPage';
import { OrderDetailPage } from './pages/OrderDetailPage/OrderDetailPage';

export function Routes() {
  return (
    <>
      <Route
        path="/"
        exact
        component={PaymentPage}
      />
      <Route
        path="/payment/:redirectStatus"
        component={PaymentPage}
      />
      <Route
        path="/orders"
        exact
        component={OrdersPage}
      />
      <Route
        path="/orders/:id"
        exact
        component={OrderDetailPage}
      />
    </>
  );
}
