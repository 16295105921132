import React, { useState } from 'react';
import { Typography, Fab, Grid } from '@material-ui/core';
import SvgIcon from '@material-ui/core/SvgIcon';
import Divider from '@material-ui/core/Divider';
import { useStylesProductFields } from './styles';
import { OfferCodesModal, displayPDForCodeTitle } from '../OfferCodesModal/OfferCodesModal';

import { buildUrl } from '@smartpush-front/api';
import {ProductQuantity} from './ProductQuantity'
import {SETTINGS} from "@smartpush-front/styles";

export function ProductFields(props) {
  const { isMobile, items, readOnly, hasOfferCodes, orderStatus, onlyButton } = props;
  const classes = useStylesProductFields({ isMobile, hasOfferCodes });

  const [offerCodesModal, setOfferCodesModal] = useState({
    open: false,
    offerCodes: [],
    imageUrl: '',
    title: ''
  });

  const handleClickOrderLineButton = (product, imageUrl: string) => {
    setOfferCodesModal({
      open: true,
      offerCodes: product.redemptions.length > 0 ? product.redemptions : [],
      title: getOfferName(product),
      imageUrl
    });
  }

  const disabledButton = (product: any) => {
    if (orderStatus !== 'completed') {
      return true;
    }

    return product.offerCodes.length === 0 && product.redemptions.length === 0;
  }

  const getOfferName = (product: any) => {
    if (product.isFreeValue) {
      return `Bon de ${product.faceValue}€`
    }

    return product.offer;
  }

  return (
    <div style={{display: 'flex', flexGrow: 1, flexDirection: 'column'}}>
      {Array.isArray(items) &&
        items.length > 0 &&
        items.map((product: any, index: number) => (
          <React.Fragment key={index}>
            <div className={classes.root}>
              {!onlyButton &&
              <div className="root-container">
               <div className="card-div">
                  <img
                    alt="card"
                    src={buildUrl(product.img || product.fullImage)}
                  />
               </div>
                <div className="content">
                  <div className="text">
                    {isMobile &&
                      <Grid container justify="space-between" alignItems="center">
                        <Typography className="title">
                          {getOfferName(product)}
                        </Typography>
                        {!readOnly && isMobile && !onlyButton && (
                          <>
                            <div style={{marginBottom: 12, marginTop: 0}}>
                              <ProductQuantity product={product} readOnly={readOnly} hasOfferCodes={hasOfferCodes} isMobile={isMobile}/>
                            </div>
                          </>
                        )}
                      </Grid>
                    }
                    {!isMobile &&
                      <Typography className="title">
                        {getOfferName(product)}
                      </Typography>
                    }
                    <Typography className="description" style={{fontFamily: 'ProximaNova'}}>
                      {!readOnly && !isMobile && (
                        <>
                          <span>{SETTINGS.familiarity ? "Tu peux" : "Vous pouvez"} augmenter la quantité en cliquant simplement sur</span>
                          <SvgIcon viewBox="0 0 15 15" style={{marginLeft: 8, width: 15, height: 15, position: 'relative', top: 2}}>
                            <path d="M7.5,0 C11.6421356,0 15,3.35786438 15,7.5 C15,11.6421356 11.6421356,15 7.5,15 C3.35786438,15 0,11.6421356 0,7.5 C0,3.35786438 3.35786438,0 7.5,0 Z M7.5,1 C3.91014913,1 1,3.91014913 1,7.5 C1,11.0898509 3.91014913,14 7.5,14 C11.0898509,14 14,11.0898509 14,7.5 C14,3.91014913 11.0898509,1 7.5,1 Z M7.5,4.5 C7.77614237,4.5 8,4.72385763 8,5 L8,5 L8,7.038 L10.038,7.038 C10.2931556,7.038 10.5,7.24484445 10.5,7.5 C10.5,7.75515555 10.2931556,7.962 10.038,7.962 L10.038,7.962 L8,7.962 L8,10 C8,10.2761424 7.77614237,10.5 7.5,10.5 C7.22385763,10.5 7,10.2761424 7,10 L7,10 L7,7.962 L4.962,7.962 C4.70684445,7.962 4.5,7.75515555 4.5,7.5 C4.5,7.24484445 4.70684445,7.038 4.962,7.038 L4.962,7.038 L7,7.038 L7,5 C7,4.72385763 7.22385763,4.5 7.5,4.5 Z"></path>
                          </SvgIcon>
                        </>
                      )}
                    </Typography>
                  </div>
                  {(!readOnly && !isMobile) && (
                    <div style={{marginBottom: 12}}>
                      <ProductQuantity product={product} readOnly={readOnly} hasOfferCodes={hasOfferCodes} isMobile={isMobile}/>
                    </div>
                  )}
                </div>
              </div>
              }
              {hasOfferCodes && Array.isArray(product.offerCodes) && (
                <div className="offerCode">
                  <Fab
                      style={{borderRadius: 5}}
                      color="primary"
                      className={classes.button}
                      variant="extended"
                      disabled={disabledButton(product)}
                      onClick={() => handleClickOrderLineButton(product, buildUrl(product.img || product.fullImage))}
                    >
                      <span style={{fontFamily: 'ProximaNovaBold'}}>{ isMobile ? 'TÉLÉCHARGER' : displayPDForCodeTitle(product) }</span>
                  </Fab>
                </div>
              )}
            </div>

            {items.length - 1 !== index && (
              <div className={classes.productDivider}>
                <Divider light variant="fullWidth" />
              </div>
            )}
          </React.Fragment>
        ))}
      <OfferCodesModal
        open={offerCodesModal.open}
        handleClose={() => {setOfferCodesModal((prevState: any) => ({
          ...prevState,
          open: false
        }))}}
        offerCodes={offerCodesModal.offerCodes}
        imageUrl={offerCodesModal.imageUrl}
        title={offerCodesModal.title}
      />
    </div>
  );
}
