/**
 * User Data Profile
 */

import {call, put, select, takeLatest} from 'redux-saga/effects';
import {
  SMARTPUSH_API_URL_USER,
  SMARTPUSH_API_URL_USERS,
  SmartpushAPI
} from '@smartpush-front/api';
import { SMARTPUSH_API_URL_USER_ME } from '@smartpush-front/api';
import {putUserDataProfileError, putUserDataProfileSuccess} from "./actions";
import {PUT_USER_DATA_PROFILE, store} from '@smartpush-front/store';
import {getToken, makeSelectUserData} from "../../../store/selectors/userSelector";
import moment from "moment";
import {getUserCityById} from "../../../../utils/forms/cityWidget/actions";
import {makeSelectPartnerSettings} from "../../../../../../../apps/phoenix/src/app/pages/LoginPage/selectors";
import {OPTIONAL_ONBOARDING, PartnerSettingsService} from "../../../../../../api/src/utils/PartnerSettingsService";

/**
 * UserData request/response handler
 */
export function* putUserDataProfileCall(action: any) {
  const partnerSettings = yield select(makeSelectPartnerSettings());
  const token = yield select(getToken());
  const userData = yield select(makeSelectUserData());
  const user = yield select(makeSelectUserData());
  const options = {
    url   : SMARTPUSH_API_URL_USER.replace(':userId', user?.id),
    config: {
      headers: {
        Authorization: token
      }
    },
    data: {
      ...action.userData,
      cityId: undefined,
      marketingCookies: undefined,
      //onBoardingSettings: {
      //  cityId: action.userData.cityId
      //}
    }
  };
  const optionsMe = {
    url   : SMARTPUSH_API_URL_USER_ME,
    config: {
      headers: {
        Authorization: token
      }
    }
  };

  const optionsForMarketingConsent = {
    url   : SMARTPUSH_API_URL_USER.replace(':userId', user?.id ?? ""),
    data         : {
      'consent':{
        'marketingCookies': Number(action.userData.marketingCookies).toString(),
      }
    },
    config: {
      headers: {
        Authorization: token
      }
    }
  };

  const optionsForCityId = {
    url          : SMARTPUSH_API_URL_USER.replace(':userId', user?.id ?? ""),
    data         : {
      ...userData.onBoardingSettings,
      birthdate: moment(userData.onBoardingSettings.birthdate).format('YYYY-MM-DD'),
      cityId: action?.userData?.cityId?.id
    },
    config: {
      headers: {
        Authorization: token
      }
    },
  };

  try {
    yield call(SmartpushAPI.patch, optionsForMarketingConsent);
    if (!PartnerSettingsService.isFeatureEnable(OPTIONAL_ONBOARDING)) {
      yield call(SmartpushAPI.patch, optionsForCityId);
    }

    yield call(SmartpushAPI.put, options);

    const data = yield call(SmartpushAPI.get, optionsMe);
    /** Put user data profile through redux */
    yield put(putUserDataProfileSuccess({
      ...data.data
    }));
    if(action?.userData?.cityId?.id) {
      yield put(getUserCityById(action?.userData?.cityId?.id))
    }
  } catch (err) {
    yield put(putUserDataProfileError(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* userDataProfileSaga() {
  yield takeLatest(PUT_USER_DATA_PROFILE, putUserDataProfileCall);
}
