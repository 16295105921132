import React from 'react';
import { makeStyles, Theme } from '@material-ui/core';

const size = {
  very_small: {
    root: {
      height: '12px',
    },
    unit: {
      fontSize: '0.875rem',
      lineHeight: '15px',
      fontWeight: 700,
    },
    decimal: {
      fontSize: '9px',
      lineHeight: '9px',
      fontWeight: 700
    },
    currency: {
      fontSize: '10px',
      bottom: '4px',
      fontWeight: 800,
      right: '3px',
      position: 'relative',
    },
  },
  small: {
    root: {
      height: '18px',
    },
    unit: {
      fontSize: '18px',
      lineHeight: '15px',
      fontWeight: 700,
    },
    decimal: {
      fontSize: '9px',
      lineHeight: '9px',
      fontWeight: 700
    },
    currency: {
      fontSize: '11px',
      bottom: '4px',
      fontWeight: 800,
      right: '3px',
      position: 'relative',
    },
  },
  medium: {
    root: {
      height: '32px',
    },
    unit: {
      fontSize: '32px',
      lineHeight: '32px',
      fontWeight: 700,
    },
    decimal: {
      fontSize: '16px',
      lineHeight: '23px',
      fontWeight: 700,
    },
    currency: {
      fontSize: '17px',
      bottom: '15px',
      fontWeight: 700,
      right: '11px',
      position: 'relative',
    },
  },
  large: {
    root: {
      height: '62px',
    },
    unit: {
      fontSize: '48px',
      lineHeight: '58px',
      fontWeight: 700,
    },
    decimal: {
      fontSize: '24px',
      lineHeight: '45px',
      fontWeight: 700,
    },
    currency: {
      fontSize: '28px',
      bottom: '24px',
      right: '16px',
      position: 'relative',
      fontWeight: 700,
    },
  },
};

export enum PriceSize {
  VERY_SMALL = 'very_small',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

interface Props {
  size: PriceSize;
  amount: number;
  color?: string;
  light?: boolean
}

const useStyles = makeStyles((theme: Theme) => ({
  root: (props: Props) => ({
    ...size[props.size].root,
    whiteSpace: 'nowrap',
    display: 'flex',
    flexWrap: 'nowrap',
    boxSizing: 'border-box',
    alignItems: 'flex-end',
    color: props.color ?? 'black'
  }),
  unit: (props: Props) => ({
    ...size[props.size].unit,
    fontFamily: props.light ? 'ProximaNova' : 'ProximaNovaBold'
  }),
  decimal: (props: Props) => ({
    ...size[props.size].decimal,
    fontFamily: props.light ? 'ProximaNova' : 'ProximaNovaBold'
  }),
  currency: (props: Props) => ({
    fontFamily: props.light ? 'ProximaNova' : 'ProximaNovaBold',
    fontSize: size[props.size].currency.fontSize,
    fontWeight: 700,
    position: 'relative',
    bottom: size[props.size].currency.bottom,
    right: size[props.size].currency.right,
  }),
}));

export function currencyFormatFR(num: any) {
  return [
    num > 0 ? Math.floor(num) + '' : Math.ceil(num) + '',
    (num % 1).toFixed(2).replace('0.', ','),
  ];
}

export function Price(props: Props) {
  const classes = useStyles(props);

  const { amount } = props;
  if (isNaN(amount)) {
    return <span>Loading...</span>;
  }

  return (
    <span className={classes.root}>
      <span className={classes.unit}>{currencyFormatFR(amount)[0]}</span>
      <span className={classes.decimal}>{currencyFormatFR(amount)[1]}</span>
      <span className={classes.currency}>€</span>
    </span>
  );
}
