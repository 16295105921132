/*
 * Offer Actions
 */

import {
  PUT_OFFER_IN_WALLET,
  PUT_OFFER_IN_WALLET_SUCCESS,
  PUT_OFFER_IN_WALLET_ERROR,
  ADD_COUPON,
  ADD_COUPON_SUCCESS,
  ADD_COUPON_ERROR,
  CLEAR_COUPON,
  REDIRECT_TO_EXTERNAL_OFFER,
  REDIRECT_TO_EXTERNAL_OFFER_SUCCESS,
  REDIRECT_TO_EXTERNAL_OFFER_ERROR, CLOSE_EXTERNAL_OFFER_MODAL
} from './constants';
import { OfferModel } from "./OfferModel";

/**
 * Put of Delete offer in wallet by his ID, this action starts the request saga
 *
 * @param  {string}   offerId       The offer id to put in wallet
 * @param  {boolean}  isInWallet    Is the offer already in wallet
 * @param  {string}   componentName componentName for Redux use
 * @param  {boolean}  inModal    Is the offer already in wallet
 *
 * @return {object}                   An action object with a type of PUT_OFFER_IN_WALLET
 */
export function putOrDeleteOfferInWallet(offerId: string, isInWallet: boolean, componentName: string, inModal: boolean) {
  return {
    type: PUT_OFFER_IN_WALLET,
    offerId,
    isInWallet,
    componentName,
    inModal
  };
}

/**
 * Dispatched when the offer is correctly put of deleted in the wallet by the request saga
 *
 * @param  {string}  componentName  Component name for Redux use
 * @param  {boolean} inModal        Is offer selected displayed in modal
 *
 * @param offerId
 * @param inWallet
 * @return {object}             An action object with a type of PUT_OFFER_IN_WALLET_SUCCESS
 */
export function putOrDeleteOfferInWalletSuccess(componentName: string, inModal: boolean, offerId: string, inWallet: boolean) {
  return {
    type: PUT_OFFER_IN_WALLET_SUCCESS,
    componentName,
    inModal,
    offerId,
    inWallet
  };
}

/**
 * Dispatched when the offer fail to put in wallet
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of PUT_OFFER_IN_WALLET_ERROR passing the error
 */
export function putOrDeleteOfferInWalletError(error: any) {
  return {
    type: PUT_OFFER_IN_WALLET_ERROR,
    error,
  };
}

/**
 * Add coupon for user by offer ID, this action starts the request saga
 *
 * @param  {OfferModel}   offer           The offer id for adding coupon
 * @param  {string}       componentName   Component name from where the coupon is added
 * @param  {string}       shopId          ShopId if it is a shopCoupon type
 *
 * @param displayPdf
 * @return {object}               An action object with a type of ADD_COUPON
 */
export function addCoupon(offer: OfferModel, componentName: string, displayPdf: boolean, shopId?: string) {
  return {
    type: ADD_COUPON,
    offer,
    componentName,
    shopId,
    displayPdf
  };
}

/**
 * Dispatched when the coupon is correctly added to the user by the request saga
 *
 * @param  {object}  couponData   Data retrieved when adding coupon
 *
 * @return {object}               An action object with a type of ADD_COUPON_SUCCESS
 */
export function addCouponSuccess(couponData: any) {
  return {
    type: ADD_COUPON_SUCCESS,
    couponData
  };
}

/**
 * Dispatched when the coupon fail to add to the user
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of ADD_COUPON_ERROR passing the error
 */
export function addCouponError(error: any) {
  return {
    type: ADD_COUPON_ERROR,
    error
  };
}

/**
 * Close coupon modal by clearing currentCoupon on redux
 *
 * @return {object} An action object with a type of CLOSE_COUPON_MODAL
 */
export function clearCoupon(willGreetingDisplayed: boolean) {
  return {
    type: CLEAR_COUPON,
    willGreetingDisplayed
  };
}

/**
 * Add coupon for user by offer ID, this action starts the request saga
 * @param  {OfferModel}   offer           The offer id for redirecting to  coupon
 *
 * @return {object}               An action object with a type of ADD_COUPON
 */
export function redirectToExternalOffer(offer: OfferModel) {
  return {
    type: REDIRECT_TO_EXTERNAL_OFFER,
    offer
  };
}

/**
 * Redirect to external offer, this action starts the request saga
 * @param  {string}   url         The url to redirecting to.
 * @param  {object}   data        The data retrieve by the route.
 *
 * @return {object}               An action object with a type of REDIRECT_TO_EXTERNAL_OFFER_SUCCESS
 */
export function redirectToExternalOfferSuccess(url: string, data: object) {
  return {
    type: REDIRECT_TO_EXTERNAL_OFFER_SUCCESS,
    url,
    data
  };
}

/**
 * Dispatched when the redirection fail to retrieve an offer
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of REDIRECT_TO_EXTERNAL_OFFER_ERROR passing the error
 */
export function redirectToExternalOfferError(error: any) {
  return {
    type: REDIRECT_TO_EXTERNAL_OFFER_ERROR,
    error
  };
}


/**
 * Dispatched when the external offer modal must close
 * @return {object}       An action object with a type of CLOSE_EXTERNAL_OFFER_MODAL passing the error
 */
export function closeExternalOfferModal() {
  return {
    type: CLOSE_EXTERNAL_OFFER_MODAL
  };
}

