import React, { useEffect, useState }  from 'react';
import * as qs from 'qs';
import { exceptParameters } from '../../utils/expectParameters';
import { useDispatch, useSelector } from 'react-redux';
import {
  userLogged,
  getUserDataWithToken,
  makeSelectUserData,
  makeSelectPartnerPrimaryColor,
  makeSelectUserDataLoading,
  makeSelectRefreshToken, getToken
} from '@smartpush-front/store';

import {useInjectSaga} from "@smartpush-front/api";

import userSaga from '../../saga/user/userSaga';
import { useParams } from 'react-router-dom';
import { OrderDetails } from '@smartpush-front/orders';
import { MuiThemeProvider } from '@material-ui/core';

import { theme } from '@smartpush-front/styles';

import { hasError, WidgetError } from '../../components/WidgetError/WidgetError';
import {logEvent} from "@smartpush-front/payment";

export function OrderDetailPage({location}) {
  const dispatch = useDispatch();
  const { id } = useParams();

  useInjectSaga({key: 'user', saga: userSaga});

  const refreshToken = useSelector(makeSelectRefreshToken())
  const token = useSelector(getToken())
  const queryParams: any = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [unauthorizedError, setUnauthorizedError] = useState(true);

  useEffect(() => {
      dispatch(userLogged(token, refreshToken ?? ''));
      dispatch(getUserDataWithToken());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(queryParams)
  ]);

  const userData = useSelector(makeSelectUserData());
  const loadingUserData = useSelector(makeSelectUserDataLoading());
  const primaryColor = useSelector(makeSelectPartnerPrimaryColor());

  useEffect(() => {
    if ( ! userData?.email ) {
      setUnauthorizedError(true);
    } else {
      setUnauthorizedError(false);
    }
  }, [userData]);


  if (hasError([], loadingUserData, unauthorizedError)) {
    return (
      <WidgetError
        missingParameters={[]}
        isLoading={loadingUserData}
        unauthorizedError={unauthorizedError}
        invalidOfferError={false} />
    );
  }
  const themeWithPrimary = {...theme};
  themeWithPrimary.palette.primary.main = primaryColor || themeWithPrimary.palette.primary.main;

  return (
    <MuiThemeProvider theme={themeWithPrimary}>
      <OrderDetails
        id={id}
        backRoute={`/orders?access_token=${token}&refresh_token=${refreshToken}&id_partner=${queryParams.id_partner}`}
      />
    </MuiThemeProvider>
  );
}
