import {
  SMARTPUSH_API_URL_TOKEN,
  SmartpushAPI,
  URL_CALL_TOKEN_CREDENTIALS,
} from '@smartpush-front/api';
import {call, put, select, takeLatest} from "redux-saga/effects";
import {
  CALL_TOKEN_EXCHANGE_FRONT,
  CALL_CREDENTIAL_TOKEN_FRONT,
  SET_TOKEN_IFRAME_FRONT,
  HANDLE_TOKEN_FRONT,
}                                      from "./constants";
import * as Qs                         from 'qs';
import {SETTINGS} from "@smartpush-front/styles";
import {
  callCredentialTokenFrontError, callCredentialTokenFrontSuccess,
  callTokenExchangeKeycloakFrontError,
  callTokenExchangeKeycloakFrontSuccess,
}                                                                               from './actions';
import {
  persistAccessTokenKeyCloak,
  persistCredentialToken,
  persistIframeToken
} from '@smartpush-front/payment';
import {
  getCredentialToken,
  getTokenAccessKeyCloak,
  LS_IFRAME_TOKEN,
} from "@smartpush-front/store";
import {get}                                                                    from "local-storage";
import { getToken }                                                             from '../../pages/LoginPage/selectors';
import {getIamRealmName, isKeycloakAuthentication} from "libs/api/src/utils/keycloak";
import {injectParameter} from "../../../utils/url/injectParameter";

export function* callCredentialToken() {
  let options;
  if (isKeycloakAuthentication()) {
    const data = {
      'grant_type': 'client_credentials',
      'client_id': 'api',
      'client_secret': SETTINGS?.iam.clientSecret
    }
    options = {
      url: injectParameter(URL_CALL_TOKEN_CREDENTIALS, process.env.REACT_APP_BASE_URL_IAM_DOMAIN_SMARTPUSH || process.env.NX_BASE_URL_IAM_DOMAIN_SMARTPUSH, getIamRealmName()),
      config: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        }
      },
      addAuthHeader: false,
      data: Qs.stringify(data),
    };
  } else {
    options = {
      url: SMARTPUSH_API_URL_TOKEN,
      data: {
        'grant_type': "client_credentials",
        'client_id': SETTINGS.clientId,
        'client_secret': SETTINGS.clientSecret,
      },
      addAuthHeader: false
    };
  }

  try {
    const {data} = yield call(SmartpushAPI.post, options);
    yield put(callCredentialTokenFrontSuccess(data.access_token))
    yield call(persistCredentialToken, {
      accessToken: data.access_token
    });
  } catch (e) {
    yield put(callCredentialTokenFrontError(e));
  }
}

export function* setIframeToken(action: any) {
  yield call(persistIframeToken, {
    iframeToken: action.token
  })
}

export function* callTokenExchange() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
  // @ts-ignore
  const iframeToken = get(LS_IFRAME_TOKEN)?.token;

  const data = {
    'grant_type': 'urn:ietf:params:oauth:grant-type:token-exchange',
    'client_id': 'app',
    'subject_token': iframeToken,
    'subject_issuer': SETTINGS?.iam?.subjectIssuer,
    'subject_token_type': 'urn:ietf:params:oauth:token-type:access_token',
    'audience': 'app',
  }
  const options = {
    url: injectParameter(
      URL_CALL_TOKEN_CREDENTIALS,
      process.env.REACT_APP_BASE_URL_IAM_DOMAIN_SMARTPUSH || process.env.NX_BASE_URL_IAM_DOMAIN_SMARTPUSH,
      getIamRealmName()
    ),
    config: {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      }
    },
    data: Qs.stringify(data)
  };
  try {
    const response = yield call(SmartpushAPI.post, options);
    const edfResponse = {'code': response?.status, 'libelle': response?.statusText};
    const edfResponseString = JSON.stringify(edfResponse);
    yield call(persistAccessTokenKeyCloak, {
      accessToken: response.data.access_token,
      refreshToken: response.data.refresh_token,
      responseKeyCloak: edfResponseString,
      responseCode: response?.status
    });
    yield put(callTokenExchangeKeycloakFrontSuccess(response.data.access_token, response.data.refresh_token, edfResponseString))
  } catch (err) {
    const edfResponse = {'code': err.statusCode, 'libelle': err.error};
    yield call(persistAccessTokenKeyCloak, {
      accessToken: '',
      refreshToken: '',
      responseKeyCloak: JSON.stringify(edfResponse)
    });
    yield put(callTokenExchangeKeycloakFrontError(err))
  }
}

export function* handleToken() {
  let token = yield select(getToken());

  if(!token) {
    token = yield select(getTokenAccessKeyCloak());
  }
  if (!token) {
    token = yield select(getCredentialToken());
  }

  return token;
}


/**
 * Root saga manages watcher lifecycle
 */
export default function* tokenSaga() {
  // Watches for SUBMIT_LOGIN actions and calls getUser when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(CALL_CREDENTIAL_TOKEN_FRONT, callCredentialToken);
  yield takeLatest(CALL_TOKEN_EXCHANGE_FRONT, callTokenExchange);
  yield takeLatest(SET_TOKEN_IFRAME_FRONT, setIframeToken);
  yield takeLatest(HANDLE_TOKEN_FRONT, handleToken);
}
