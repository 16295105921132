import {useStylesOrderContent} from "./styles";
import {Grid, Typography} from "@material-ui/core";
import {CardGiftcard} from "@material-ui/icons";
import * as React from "react";
import {addItemQuantityToCart, Price, PriceSize} from "@smartpush-front/payment";
import {useDispatch} from "react-redux";
import {OfferVariantFree} from "../../../components/OfferVariant/OfferVariantFree";

interface OrderContentFixVariantProps {
  element: any;
  pendingOrSuccess: boolean;
}

export const OrderContentFreeVariant = ({element, pendingOrSuccess}: OrderContentFixVariantProps) => {
  const classes = useStylesOrderContent();
  const dispatch = useDispatch();

  const getProductDiscountPrice = () => {
    return element.quantity - (element.quantity * element.discountAmount / 100);
  }

  const handleChange = (amount) => {
    dispatch(addItemQuantityToCart(element, false, element.id, amount));
  }

  return (
    <Grid container className={classes.offerVariant} justify="space-around">
      <Grid item xs={12} lg={10} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem'}}>
        <div style={{display: 'flex', justifyContent: 'flex-start', alignItems: 'center'}}>
          <CardGiftcard fontWeight={24} />
          <Typography>Bon d'une valeur de {element.quantity}€</Typography>
        </div>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <Price
            size={PriceSize.SMALL}
            amount={getProductDiscountPrice()}
            color={'#878B8D'}
          />
        </div>
      </Grid>
      {!pendingOrSuccess && (
        <OfferVariantFree variant={element} changeAmountValue={handleChange} onPaymentCard={true} />
      )}
    </Grid>
  )
}
