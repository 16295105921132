import React from "react";
import {makeStyles, darken, Theme, Divider, useMediaQuery} from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {COLORS, SETTINGS} from "@smartpush-front/styles";
import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import {SMARTPUSH_API_BASE_URL} from "@smartpush-front/api";
import axios from 'axios';
import {useSelector} from "react-redux";
import {getToken} from "@smartpush-front/store";
import "./style.scss";
import {isArray} from "lodash";

const useStylesSignUp = makeStyles((theme: Theme) => ({
  box: {
    padding: '4px'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 2px 4px 0 rgba(152,152,152,0.62)',
    padding: theme.spacing(2, 0, 3),
    maxWidth: '100%',
    width: '600px',
    borderRadius: '5px',
    paddingBottom: 32,
    outline: 0,
    maxHeight: "80vh",
    overflowY: "scroll"
  },
  button: {
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.main, 0.3)
    },
    minWidth: 175
  },
  closeIcon: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '8px 16px',
    '& button': {
      padding: 0,
      borderRadius: '5px',
      '& .closeButton': {
        color: COLORS.black
      }
    }
  },
  horizontalPadding: {
    paddingLeft: 16,
    paddingRight: 16
  },
  title: {
    fontFamily: 'ProximaNovaBold',
    fontSize: 25,
    marginTop: -8,
    marginBottom: -2,
    paddingBottom: 10
  },
  divider: {
    margin: '16px 32px',
    backgroundColor: 'rgba(0, 0, 0, 0.07)'
  },
  offerCode: (props: {isMobile: boolean}) => ({
    display: 'flex',
    flexDirection: props.isMobile ? 'column' : 'row',
    '& .offerContent': {
      display: 'flex',
      flexDirection: 'row',
      flexGrow: 1,
      '& img': {
        height: 75,
        boxShadow: '0px 2px 4px 0 rgba(152, 152, 152, 0.62)',
        borderRadius: 5,
        marginRight: '16px'
      },
      '& .content': {
        flexGrow: 1,
        alignContent: 'space-around',
        '& .title': {
          fontFamily: 'ProximaNovaBold',
          fontSize: '0.85rem',
          marginTop: 8
        },
        '& .code': {
          '& span': { display: 'block' },
          color: '#AAA',
          fontSize: '0.95rem',
          marginTop: 5
        }
      }
    },
    '& .download': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 8,
      '& .MuiFab-label': {
        fontFamily: 'ProximaNovaBold',
        fontSize: 16
      },
      '& .MuiFab-extended': {
        borderRadius: '5px'
      }
    }
  })
}));

async function getPdfFromSmartpushApi(url: string, token: string) {
  const response: any = await axios.get(url, {
    method: 'GET',
    responseType: 'blob', //Force to receive data in a Blob Format,
    headers: {
      'Authorization': 'Bearer '+token
    }
  });
  const file = new Blob(
    [response.data],
    {type: 'application/pdf'});
  return URL.createObjectURL(file);
}


export function displayPDForCodeTitle(product: any): string {
  let isPdfAvailable = false;
  let hasMany = false;
  if (product.redemptions?.length > 0) {
    isPdfAvailable = !! product.redemptions.find(redemption => redemption.urlPdf !== null)
    hasMany = product.redemptions.length > 1;
  }
  if (isArray(product) && product.length > 0) {
    isPdfAvailable = !! product.find(item => item.urlPdf !== null || item.offerCode.urlPdf !== null);
    hasMany = product.length > 1;
  }

  const s = hasMany ? 's' : ''
  return isPdfAvailable
    ? `Télécharger le${s} PDF`
    : `Afficher le${s} code${s}`;
}

interface OfferCodesModalProps {
  open: boolean,
  handleClose: () => void,
  offerCodes: any[],
  imageUrl: string,
  title: string
}

export function OfferCodesModal({
    open,
    handleClose,
    offerCodes,
    imageUrl,
    title
}: OfferCodesModalProps) {
  const isMobile = useMediaQuery('(max-width:767px)');
  const classes = useStylesSignUp({isMobile});
  const token = useSelector(getToken());

  function handleClickPdfButton(offerCode: any) {
    let url = offerCode.urlPdf || offerCode.offerCode?.urlPdf;
    if (url.startsWith('/voucher')) {
      url = SMARTPUSH_API_BASE_URL+'/v2'+url;
      getPdfFromSmartpushApi(url, token).then((url: string) => {
        window.open(url, "_blank");
      });
      return;
    }
    window.open(url, "_blank")
  }

  return (
      <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="user-modal-form"
          aria-describedby="user-modal-description"
          className={"offerCodes-modal "+classes.modal}
      >
        <div className={"paper "+classes.paper}>
          <div className={classes.closeIcon}>
            <div style={{cursor: 'pointer', fontSize: '1.5em'}} onClick={handleClose}>
              <FontAwesomeIcon icon={faTimes} className="closeButton"/>
            </div>
          </div>
          <div className={classes.horizontalPadding}>
            <Typography className={classes.title}>
                {displayPDForCodeTitle(offerCodes)}
            </Typography>

            <Typography>
              {SETTINGS.familiarity ? "Tu peux télécharger tes" : "Vous pouvez télécharger vos"} coupons ci-dessous.
            </Typography>

            {offerCodes.map((offerCode: any, index: number) => (
            <React.Fragment key={index}>
              <Divider variant="fullWidth" className={classes.divider} />
              <div className={classes.offerCode}>
                <div className="offerContent">
                  <div className="offerContent-img-container">
                    <img className="offerContent-img" alt="offer" src={imageUrl}/>
                  </div>
                  <div className="content">
                    <Typography className="title">
                        {title}
                    </Typography>

                    <Typography className="code">
                        <span>Code : {offerCode.offerCode?.code ?? '-'}</span>
                        <span style={{marginTop: -4}}>Pin : {offerCode.offerCode?.pin ?? '-'}</span>
                    </Typography>
                  </div>
                </div>
                {(offerCode.urlPdf || offerCode.offerCode?.urlPdf) &&
                  <div className="download">
                    <Fab
                      color="primary"
                      className={classes.button}
                      variant="extended"
                      disabled={offerCode.urlPdf === null && offerCode.offerCode && offerCode.offerCode.urlPdf === null}
                      onClick={() => handleClickPdfButton(offerCode)}
                    >
                      Télécharger le PDF
                    </Fab>
                  </div>
                }
              </div>
            </React.Fragment>
            ))}
          </div>
        </div>
      </Modal>
  );
}
