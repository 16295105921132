import React, {useEffect, useState} from "react";
import {Grid, TextField} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import clsx from "clsx";
import PaymentCardImages from "../PaymentCardImages";
import { usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import {useStylesPaymentFields} from "./styles";
import {useDispatch, useSelector} from "react-redux";
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  makeSelectCardData,
  makeSelectCardError,
  makeSelectCardFeesAmount,
  makeSelectCardFeesError,
  makeSelectCardFeesLoading,
  makeSelectCardFields,
  makeSelectCardIsLoading,
  makeSelectCardTokens,
  makeSelectIsOldCardSelected
} from "../../../store/selectors/selectors";
import {
  getCardTokens,
  saveCardData,
  updateCardFields,
  updateSelectedCard
} from "../../../store/actions/actions";
import {makeSelectUserData} from "@smartpush-front/store";
import {deburr, isEmpty} from "lodash";
import InfoIcon from "@material-ui/icons/Info";
import {COLORS} from "../../../../utils/styles/variables";
import {WarningOutlined} from "@material-ui/icons";
import {ImageService} from "../../../../../../../apps/phoenix/src/utils/imageUtil";

const ERROR_MESSAGES = {
    emptyCardNumber: 'Le numéro de carte est invalide',
    invalidCardNumber: 'Le numéro de carte est invalide',
    emptyExpiryDate: 'La date d\'expiration est invalide',
    monthOutOfRange: 'Le mois d\'expiration doit être en 01 et 12',
    yearOutOfRange: 'L\'année d\'expiration ne peut être dans le passé',
    dateOutOfRange: 'La date d\'expiration ne peut être dans le passé',
    invalidExpiryDate: 'La date d\'expiration est invalide',
    emptyCVC: 'Le code de sécurité est invalide',
    invalidCVC: 'Le code de sécurité est invalide'
};

/*
brand: "VISA"
browser_info: {java_enabled: false, javascript_enabled: true, language: "fr-FR", color_depth: 30, screen_height: 1050, …}
card_expiry_month: "12"
card_expiry_year: "2021"
card_holder: "John"
card_id: "9fd81707-8f41-4a01-b6ed-279954336ada"
card_type: "CREDIT"
country: "US"
forbidden_issuer_country: false
issuer: "JPMORGAN CHASE BANK, N.A."
multi_use: 1
pan: "411111xxxxxx1111"
payment_product: "visa"
request_id: "0"
token: "1fd9741785c9dee34bfc6a5b8c2458cd5efd3095516393df6c8bd4d0cf5d1b57"
*/

export default function PaymentFields(props) {
    const { isMobile } = props;
    const dispatch = useDispatch();
    const classes = useStylesPaymentFields({isMobile});
    const loading = useSelector(makeSelectCardIsLoading());
    const cardTokens = useSelector(makeSelectCardTokens());
    const isOldCardSelected = useSelector(makeSelectIsOldCardSelected());
    const hasCardTokens  = cardTokens && Array.isArray(cardTokens) && cardTokens.length > 0;

    const cardData = useSelector(makeSelectCardData());
    const cardError = useSelector(makeSelectCardError());
    const fields = useSelector(makeSelectCardFields());
    const userData = useSelector(makeSelectUserData());
    const fees = useSelector(makeSelectCardFeesAmount());
    const feesError = useSelector(makeSelectCardFeesError());
    const feesLoading = useSelector(makeSelectCardFeesLoading());
    const { meta, getCardNumberProps, getCardImageProps, getExpiryDateProps, getCVCProps } = usePaymentInputs({errorMessages: ERROR_MESSAGES});
    useEffect(() => {
        dispatch(getCardTokens());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // useEffect(() => {
    //   if (hasCardTokens) {
    //     dispatch(updateSelectedCard(true));
    //   }
    // }, [hasCardTokens]);

    const [check, setCheck] = useState(0);

    useEffect(() => {
        const cardNumber = fields.cardNumber;
        const expiryDate = fields.expirationDate;
        const cardNumberTrimmed = cardNumber.replace(/ /g, '');
        const expiryDateTrimmed = expiryDate.split(' / ');
        const firstNameTrimmed = userData?.firstName ? deburr(userData.firstName).normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, ' ') : '';
        const lastNameTrimmed = userData?.lastName ? deburr(userData.lastName).normalize('NFD').replace(/([\u0300-\u036f]|[^0-9a-zA-Z])/g, ' ') : '';
        dispatch(saveCardData({
            cardHolder: `${firstNameTrimmed} ${lastNameTrimmed}`,
            cardNumber: cardNumberTrimmed,
            expiryMonth: expiryDateTrimmed[0],
            expiryYear: expiryDateTrimmed[1],
            cvc: fields.cvc,
            multiUse: fields.multiUse
        }));
    }, [fields]);

    const brandImageFile = {
      visa: 'visa',
      mastercard: 'mastercard',
      'american-express': 'amex',
      maestro: 'maestro',
      cb: 'generic',
      cbmc: 'generic'
    }
    const brandImage = hasCardTokens && (<img className={classes.cardMini} alt='card' src={`${ImageService.getBaseUrlMedia()}/data/images/paymentCardIcons/${brandImageFile[cardTokens[0].name]}.svg`}/>);

    return (
        <Grid
            container
            className={classes.cardDiv}
        >
            {/*{hasCardTokens && (*/}
            {/*    <Grid*/}
            {/*        item*/}
            {/*        md={6}*/}
            {/*        sm={12}*/}
            {/*        xs={12}*/}
            {/*        className={clsx(classes.cardButton, classes.cardButtonLeft)}*/}
            {/*    >*/}
            {/*        <FormControlLabel*/}
            {/*            control={*/}
            {/*                <Checkbox*/}
            {/*                    disableRipple*/}
            {/*                    name="checkPayment"*/}
            {/*                    disabled={!hasCardTokens}*/}
            {/*                    icon={<RadioButtonUncheckedIcon color={!hasCardTokens ? "inherit": "primary"}/>}*/}
            {/*                    checkedIcon={<RadioButtonCheckedIcon color="primary"/>}*/}
            {/*                />*/}
            {/*            }*/}
            {/*            checked={isOldCardSelected}*/}
            {/*            disabled={!hasCardTokens}*/}
            {/*            onChange={() => dispatch(updateSelectedCard(true))}*/}
            {/*            className={classes.checkbox}*/}
            {/*            color="inherit"*/}
            {/*            label="Utiliser ma carte enregistrée"*/}
            {/*        />*/}
            {/*        <div className={classes.savedCardPan}>*/}
            {/*          {brandImage}*/}
            {/*          <span className={classes.cardPan}>{cardTokens[0].pan}</span>*/}
            {/*          <span className={clsx(classes.cardPan, classes.cardDateExp)}>xx/{cardTokens[0].expirationDate.substr(2)}</span>*/}
            {/*        </div>*/}
            {/*    </Grid>*/}
            {/*)}*/}
            <Grid
                item
                md={ !isMobile && hasCardTokens ? 6 : 12 }
                sm={12}
                xs={12}
                className={hasCardTokens ? clsx(classes.cardButton, classes.cardButtonRight) : ''}
            >
                <FormControlLabel
                    control={
                        <Checkbox
                            disableRipple
                            name="checkPayment"
                            color="primary"
                            icon={<RadioButtonUncheckedIcon color="primary"/>}
                            checkedIcon={<RadioButtonCheckedIcon/>}
                        />
                    }
                    checked={true}
                    onChange={() => dispatch(updateSelectedCard(false))}
                    className={classes.checkbox}
                    color="primary"
                    label="Utiliser une carte de paiement"
                />
                <PaymentCardImages/>
            </Grid>
            {!isOldCardSelected && (
                <Grid
                    item
                    xs={12}
                >
                    <Grid
                        container
                        direction="column"
                        justify="space-evenly"
                        alignItems="center"
                        className={classes.paymentFields}
                    >
                        <Grid item>
                            <Grid container justify="space-between" alignItems="center" direction="column">
                              {feesError && (
                                <div style={{maxWidth: "354.5px", display: 'flex'}}>
                                  <div style={{display: 'flex', alignItems: 'center'}}>
                                    <WarningOutlined style={{color: COLORS.error}} />
                                  </div>
                                  <p style={{marginLeft: "0.5rem", fontSize: "12px", color: COLORS.error}}>
                                    Votre carte n'est pas reconnue par notre système de paiement.
                                  </p>
                                </div>
                              )}
                                <div className={classes.fields}>
                                    <div className={classes.fields}>
                                        <div className="cardSvg">
                                          <svg {...getCardImageProps({ images })} />
                                        </div>
                                        <TextField
                                            inputProps={{
                                              ...getCardNumberProps({
                                                onChange: ({target: {value}}: any) => {
                                                  setCheck(check + 1);
                                                  dispatch(updateCardFields({
                                                    ...fields,
                                                    cardNumber: value || ''
                                                  }));
                                                }
                                              }),
                                              placeholder: "Numéro de carte"
                                            }}
                                            className={classes.placeholderColor + ' cardNumber'}
                                            label="Numéro de carte"
                                            value={fields.cardNumber}
                                            variant="outlined"
                                        />
                                    </div>
                                    <div className="row">
                                        <TextField
                                            inputProps={{
                                              ...getExpiryDateProps({
                                                onChange: ({target: {value}}: any) => {
                                                  setCheck(check + 1)
                                                  dispatch(updateCardFields({
                                                    ...fields,
                                                    expirationDate: value
                                                  }))
                                                }
                                              }),
                                              placeholder: "MM/AA"
                                            }}
                                            className={classes.placeholderColor + ' smallInput'}
                                            placeholder="MM/AA"
                                            label="MM/AA"
                                            value={fields.expirationDate}
                                            variant="outlined"
                                            style={{marginRight: 4}}
                                        />

                                        <TextField
                                            inputProps={{
                                              ...getCVCProps({
                                                onChange: ({target: {value}}: any) => {
                                                  setCheck(check + 1);
                                                  dispatch(updateCardFields({
                                                    ...fields,
                                                    cvc: value
                                                  }));
                                                }
                                              })
                                            }}
                                            className={classes.placeholderColor + ' smallInput'}
                                            placeholder="CCV"
                                            value={fields.cvc}
                                            variant="outlined"
                                            style={{marginLeft: 4}}
                                        />
                                    </div>
                                    <div className={classes.cardValidity}>
                                        {((loading || feesLoading) && check > 0) && (
                                            <CircularProgress size={20}/>
                                        )}
                                        {((!isEmpty(cardData) && (fees !== null && !feesLoading)) && check > 0) && (
                                            <DoneIcon style={{color: 'green'}}/>
                                        )}
                                        {((cardError !== null || (feesError && !feesLoading)) && check > 0) && (
                                            <ErrorIcon style={{color: 'red'}}/>
                                        )}
                                    </div>
                                </div>
                                <div style={{maxWidth: "354.5px", display: 'flex'}}>
                                  <div style={{display: 'flex', alignItems: 'center'}}>
                                    <InfoIcon style={{color: COLORS.grey}} />
                                  </div>
                                  <p style={{marginLeft: "0.5rem", fontSize: "12px", color: COLORS.grey}}>
                                    Si vous utilisez une carte bancaire hors France, des frais de gestion seront rajoutés à votre commande au moment de régler.
                                  </p>
                                </div>
                            </Grid>
                        </Grid>
                        {/*<Grid item xs={12}>*/}
                        {/*    <Grid container justify="center">*/}
                        {/*        <FormControlLabel*/}
                        {/*            control={*/}
                        {/*                <Checkbox*/}
                        {/*                    disableRipple*/}
                        {/*                    name="checkPayment"*/}
                        {/*                    color="primary"*/}
                        {/*                    onChange={(evt: any) =>*/}
                        {/*                        dispatch(updateCardFields({*/}
                        {/*                            ...fields,*/}
                        {/*                            multiUse: evt.target.checked*/}
                        {/*                        }))*/}
                        {/*                    }*/}
                        {/*                />*/}
                        {/*            }*/}
                        {/*            value={fields.multiUse}*/}
                        {/*            className={classes.checkboxSaveCard}*/}
                        {/*            color="primary"*/}
                        {/*            label="Enregistrer cette carte pour mes futurs achats"*/}
                        {/*        />*/}
                        {/*    </Grid>*/}
                        {/*</Grid>*/}
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
}
