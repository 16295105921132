import { makeStyles, Theme } from '@material-ui/core';

export const useWidgetStyles = makeStyles((theme: Theme) => ({
  centered: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16
  },
  alert: {
    width: 600,
    maxWidth: '100%',
    margin: '16px auto',
    backgroundColor: '#FFFCF7 !important',
    border: '1px solid #FAD390',
    lineHeight: '16px',
    fontSize: '16px',
    '& .MuiAlert-icon': {
        color: '#FAD390',
        alignItems: 'center'
    },
    '& .MuiTypography-root': {
        color: '#878B8D',
        fontWeight: 300,
        fontSize: 14
    },
    '& .MuiAlertTitle-root': {
        color: '#232323',
        fontWeight: 700,
        fontFamily: 'ProximaNovaBold',
        marginBottom: 0,
        fontSize: 16
    },
  }
}));
