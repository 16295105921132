/*
 * User Data Profile Actions
 */

import {
  PUT_USER_DATA_PROFILE, PUT_USER_DATA_PROFILE_ERROR, PUT_USER_DATA_PROFILE_SUCCESS
} from './constants';
// import UserModel from "../../../../app/pages/LoginPage/UserModel";

/**
 * Modify user data, this action starts the request saga
 *
 * @param  {object} userData  User data profile
 *
 * @return {object}           An action object with a type of PUT_USER_DATA_PROFILE
 */
export function putUserDataProfile(userData: any) {
  return {
    type: PUT_USER_DATA_PROFILE,
    userData
  };
}

/**
 * Dispatched when the user data is correctly send by the request saga
 *
 * @return {object} An action object with a type of PUT_USER_DATA_PROFILE_SUCCESS passing the offers
 */
export function putUserDataProfileSuccess(user) {
  return {
    type: PUT_USER_DATA_PROFILE_SUCCESS,
    user
  };
}

/**
 * Dispatched when user data sending fail
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of PUT_USER_DATA_PROFILE_ERROR passing the error
 */
export function putUserDataProfileError(error: any) {
  return {
    type: PUT_USER_DATA_PROFILE_ERROR,
    error,
  };
}
