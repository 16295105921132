import React from "react";

import { Grid } from "@material-ui/core";
import {useStylesPaymentModal} from "../styles";

import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import {history} from "@smartpush-front/store";
import {SETTINGS} from "@smartpush-front/styles";

export function OrderPendingMessage(props) {
  const classes = useStylesPaymentModal();
  const { userData, ctaRoute } = props;

  return (
    <Grid container alignItems="center" justify="center">
        <Grid item>
            <AccessTimeIcon
                fontSize="large"
                className={classes.pendingLogo}
            />
        </Grid>
        <Grid item xs={12}>
            <Typography align="center" className={classes.sectionText} color="textPrimary">
                { userData.firstname && userData.lastname ? (userData.firstname + ' ' +userData.lastname) : userData.email }
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Typography align="center" className={classes.mainTitleSuccess}>
              {SETTINGS.familiarity ? "Ta" : "Votre"} commande est en cours de traitement !
            </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography align="center" gutterBottom className={classes.sectionText} color="textPrimary">
            Notre équipe met tout en œuvre pour la traiter le plus rapidement possible.
          </Typography>
        </Grid>

        <Grid item xs={12}>
            <Typography align="center" gutterBottom className={classes.sectionText} color="textPrimary">
              {SETTINGS.familiarity ? "Tu recevras" : "Vous recevrez"} un e-mail de confirmation dès sa validation.
            </Typography>
        </Grid>
        <Grid item >
            <Fab color="primary"  className={classes.orderButton} onClick={() => history.push(ctaRoute)}>
                <Typography className={classes.paymentButtonTypo}>
                    Suivre ma commande
                </Typography>
            </Fab>
        </Grid>
    </Grid>

  );
}
