import { Model } from '@smartpush-front/api';

class CityModel extends Model {
  public id: string;
  public name: string;
  public formattedName: string;
  public inseeCode: string;
  public population: string;
  public coordinates: any;
  public zipCodes: string

  constructor(props: any) {
    super(props);
    this.id = props.id;
    this.name = props.name;
    this.formattedName = props.formattedName;
    this.inseeCode = props.inseeCode;
    this.population = props.population;
    this.coordinates = props.coordinates;
    this.zipCodes = props.zipCodes;
  }
}

export default CityModel;
