import React from "react";

import clsx from "clsx";

import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import UndoIcon from '@material-ui/icons/Undo';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { makeStyles, Theme } from "@material-ui/core";

interface Props {
  status: string,
  verbose?: boolean,
  isMobile: boolean
}

const useStylesOrderStyle = makeStyles((theme: Theme) => ({
  statusIcon: ({verbose}: any) => ({
    fontSize: verbose ? '28px' : '16px',
    position: 'relative',
    top: verbose ? 8 : 2
  }),
  pendingColor: {
    color: "#F6B93B"
  },
  completedColor: {
    color: "#38ada9"
  },
  refundedColor: {
    color: "#DD054A"
  },
  canceledColor: {
    color: '#de0b4e'
  },
  text: ({verbose, isMobile}: any) => ({
    marginLeft: isMobile ? 0 : 8,
    fontFamily: verbose ? 'ProximaNovaBold' : 'ProximaNova',
  })
}));

export function  OrderStatus({status, verbose = false, isMobile}: Props) {
  const classes = useStylesOrderStyle({verbose, isMobile});

  return (
    <>
      <span>
          {status === 'completed' && !isMobile && (
            <CheckCircleOutlineIcon className={"statusIcon "+clsx(classes.statusIcon, classes.completedColor)}/>
          )}
          {(status === 'pending' || status === 'pending_action' || status === 'forwarding') && (
            <AccessTimeIcon className={"statusIcon "+clsx(classes.statusIcon, classes.pendingColor)}/>
          )}
          { status === 'refunded' && (
            <UndoIcon className={"statusIcon "+clsx(classes.statusIcon, classes.refundedColor)}/>
          )}
          { status === 'canceled' && (
            <HighlightOffIcon className={"statusIcon "+clsx(classes.statusIcon, classes.refundedColor)}/>
          )}
      </span>
      <span className={classes.text}>
        {status === 'completed' && (verbose ? 'Votre commande a été validée !' : 'Validée')}
        {(status === 'pending' || status === 'forwarding' || status === 'pending_action') && (verbose ? 'Votre commande est en attente !' : 'En attente')}
        {status === 'refunded' && (verbose ? 'Votre commande a été remboursée' : 'Remboursée')}
        {status === 'cancel_user' && (verbose ? 'Votre commande a été annulée' : 'Annulée')}
      </span>

    </>
  )
}
