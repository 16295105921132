import { Model } from '@smartpush-front/api';

class BrandModel extends Model {
  public id!: string;
  public logo!: string;
  public name!: string;

  constructor(data: any) {
    super(data);
    if (data) {
      Object.assign(this, data);
    }
  }
}

export default BrandModel;
