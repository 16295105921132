/*
 * Offer Constants
 */

/**
 * Offer CONSTANTS
 *
 * @type {string}
 */
export const PUT_OFFER_IN_WALLET = 'SmartPush/Offer/PUT_OFFER_IN_WALLET';
export const PUT_OFFER_IN_WALLET_SUCCESS = 'SmartPush/Offer/PUT_OFFER_IN_WALLET_SUCCESS';
export const PUT_OFFER_IN_WALLET_ERROR = 'SmartPush/Offer/PUT_OFFER_IN_WALLET_ERROR';

//
// ADD COUPON FOR CURRENT USER
//
export const ADD_COUPON = 'SmartPush/Offer/ADD_COUPON';
export const ADD_COUPON_SUCCESS = 'SmartPush/Offer/ADD_COUPON_SUCCESS';
export const ADD_COUPON_ERROR = 'SmartPush/Offer/ADD_COUPON_ERROR';

// CLOSE COUPON MODAL BY CLEARING CURRENT COUPON ON REDUX
export const CLEAR_COUPON = 'SmartPush/Offer/CLEAR_COUPON';

// REDIRECT TO EXTERNAL OFFER
export const REDIRECT_TO_EXTERNAL_OFFER = 'SmartPush/Offer/REDIRECT_TO_EXTERNAL_OFFER';
export const REDIRECT_TO_EXTERNAL_OFFER_SUCCESS = 'SmartPush/Offer/REDIRECT_TO_EXTERNAL_OFFER_SUCCESS';
export const REDIRECT_TO_EXTERNAL_OFFER_ERROR = 'SmartPush/Offer/REDIRECT_TO_EXTERNAL_OFFER_ERROR';
export const CLOSE_EXTERNAL_OFFER_MODAL = 'SmartPush/Offer/CLOSE_EXTERNAL_OFFER_MODAL';