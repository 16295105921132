import React from "react";

import {useStylesPaymentModal} from "../styles";

import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";

import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from 'react-redux';
import { makeSelectCardOrderDataSending } from '../../../../store/selectors/selectors';

interface Props {
  disabled: boolean,
  total: number,
  onClick: () => void;
  isMobile: boolean,
}

export function OrderButton(props: Props) {

  const classes = useStylesPaymentModal();

  const orderDataSending: boolean = useSelector(makeSelectCardOrderDataSending());
  const { disabled, total, onClick, isMobile } = props;

  return (
    <Fab color="primary"  disabled={disabled} className={isMobile ? classes.paymentButtonMobile : classes.paymentButton} onClick={onClick} style={{borderRadius: 5}}>
      {orderDataSending && (
          <CircularProgress size={20} color="secondary"/>
      )}
      {!orderDataSending && !isMobile && (
          <Typography className={classes.paymentButtonTypo}>
              {"Payer "+total.toFixed(2)+"€"}
          </Typography>
      )}
      {!orderDataSending && isMobile && (
      <Typography className={classes.paymentButtonTypo}>
      Payer
      </Typography>
    )}
    </Fab>
  );
}
