import BrandModel         from './BrandModel';
import { Model }          from '@smartpush-front/api';
import OfferCategoryModel from './OfferCategoryModel';

export const OFFER_TYPE_SHOP      = 'shopCoupon';
export const OFFER_TYPE_WEB       = 'webCoupon';
export const OFFER_TYPE_CINE      = 'cine';
export const OFFER_TYPE_PROMO     = 'promoparcs';
export const OFFER_TYPE_DEAL      = 'deal';
export const OFFER_TYPE_GSA       = 'gsa';
export const OFFER_TYPE_CASH_BACK = 'cashback';
export const OFFER_TYPE_CO_FIN    = 'bacs';
export const OFFER_TYPE_ECARD     = 'ecard';

export const MAG        = 'MAG';
export const MAG_URL    = 'MAG-URL';
export const MAG_L      = 'MAG-L';
export const MAG_L_EUR  = 'MAG-L-€';
export const MAG_L_URL  = 'MAG-L-URL';
export const MAG_G_URL  = 'MAG-G-URL';
export const MAG_G      = 'MAG-G';
export const WEB        = 'WEB';
export const WEB_L      = 'WEB_L';
export const WEB_G      = 'WEB-G';
export const WEB_BACK   = 'WEB-BACK';

export class OfferModel extends Model {
  public brand?: any;
  public checkedByAtipikAt?: string;
  public conditions?: string;
  public couponSingleUsage?: boolean;
  public createdAt?: string;
  public description?: string;
  public disabledAt?: string;
  public discountAmount?: string;
  public discountType?: string;
  public discountLabel?: string;
  public endedAt?: Date;
  public externalOffer?: boolean;
  public filterAgeFrom?: string;
  public filterAgeTo?: string;
  public filterGender?: string;
  public fullImage?: string;
  public hasCustomOfferCode?: string;
  public hasCode: boolean;
  public id: string;
  public inWallet: boolean;
  public initialPrice?: string;
  public isWebExit: boolean;
  public isWeb: boolean;
  public isCashback: boolean;
  public isPurchasable: boolean;
  public logo?: string;
  public minimumPurchase?: string;
  public nbDownload?: string;
  public offer?: string;
  public offerCategories?: OfferCategoryModel[];
  public offerQualification?: string;
  public offerType?: string;
  public offerTypeEnhanced?: string;
  public price?: string;
  public picture?: string;
  public requiredBuy?: string;
  public savingPrice?: string;
  public shops?: any;
  public shopCouponCodepromo?: string;
  public hasCodeList?: boolean;
  public startedAt?: Date;
  public status?: string;
  public updatedAt?: string;
  public webCouponLink?: string;
  public webCouponMobilelink?: string;
  public title: string;
  public heading: string;
  public legend: string;
  public isJackpot: string;
  public provider?: any;
  public images?: any

  constructor(data: any) {
    super(data);
    if (data.brand) {
      this.brand = new BrandModel(data.brand);
    }
    this.checkedByAtipikAt = data.checkedByAtipikAt;
    this.conditions = data.conditions;
    this.couponSingleUsage = data.couponSingleUsage;
    this.createdAt = data.createdAt;
    this.description = data.description;
    this.disabledAt = data.disabledAt;
    this.discountAmount = data.discountAmount;
    this.discountType = data.discountType;
    this.discountLabel = data.discountLabel;
    this.endedAt = data.endedAt;
    this.externalOffer = data.externalOffer;
    this.filterAgeFrom = data.filterAgeFrom;
    this.filterAgeTo = data.filterAgeTo;
    this.filterGender = data.filterGender;
    this.fullImage = data.fullImage;
    this.hasCustomOfferCode = data.hasCustomOfferCode;
    this.id = data.id;
    this.inWallet = data.inWallet;
    this.initialPrice = data.initialPrice;
    this.isWebExit = data.isWebExit;
    this.isWeb = data.isWeb;
    this.isPurchasable = data.isPurchasable;
    this.isCashback = data.isCashBack;
    this.logo = data.logo;
    this.minimumPurchase = data.minimumPurchase;
    this.nbDownload = data.nbDownload;
    this.offer = data.offer;
    this.offerQualification = data.offerQualification;
    this.offerType = data.offerType;
    this.price = data.price;
    this.picture = data.picture;
    this.requiredBuy = data.requiredBuy;
    this.savingPrice = data.savingPrice;
    this.shopCouponCodepromo = data.shopCouponCodepromo;
    this.hasCodeList = data.hasCodeList;
    this.shops = data.shops;
    this.startedAt = data.startedAt;
    this.status = data.status;
    this.updatedAt = data.updatedAt;
    this.webCouponLink = data.webCouponLink;
    this.webCouponMobilelink = data.webCouponMobilelink;
    this.title = data.title;
    this.heading = data.heading;
    this.legend = data.legend;
    this.isJackpot = data.isJackpot;
    this.provider = data?.provider;

    this.offerTypeEnhanced = null;
    this.hasCode = Boolean(data.hasCodeList || data.shopCouponCodepromo && data.shopCouponCodepromo.length > 0);
    if (this.hasCode === false && data.isWeb === true && data.isCashBack === false) {
      this.offerTypeEnhanced = WEB;
    }
    if (this.hasCode === false && data.isWeb === true && data.isCashBack === true) {
      this.offerTypeEnhanced = WEB_BACK;
    }
    if (this.hasCode === true && data.couponSingleUsage === false && data.isWeb === false && data.isWebExit === false) {
      this.offerTypeEnhanced = MAG_G;
    }
    if (this.hasCode === true && data.couponSingleUsage === false && data.isWeb === false && data.isWebExit === true) {
      this.offerTypeEnhanced = MAG_G_URL;
    }
    if (this.hasCode === true && data.couponSingleUsage === true && data.isPurchasable === false && data.isWeb === false && data.isWebExit === true) {
      this.offerTypeEnhanced = MAG_L_URL;
    }
    if (this.hasCode === true && data.couponSingleUsage === true && data.isPurchasable === false && data.isWeb === false && data.isWebExit === false) {
      this.offerTypeEnhanced = MAG_L;
    }
    if (this.hasCode === true && data.couponSingleUsage === true && data.isPurchasable === false && data.isWeb === true) {
      this.offerTypeEnhanced = WEB_L;
    }
    if (this.hasCode === true && data.couponSingleUsage === false && data.isWeb === true) {
      this.offerTypeEnhanced = WEB_G;
    }
    if (this.hasCode === true && data.couponSingleUsage === true && data.purchasable === true) {
      this.offerTypeEnhanced = MAG_L_EUR;
    }
    if (this.hasCode === false && data.isWeb === false && data.isWebExit === false) {
      this.offerTypeEnhanced = MAG;
    }
    if (this.hasCode === false && data.isWeb === false && data.isWebExit === true) {
      this.offerTypeEnhanced = MAG_URL;
    }

    if (data.offerCategories) {
      this.offerCategories = Model.loadCollection<OfferCategoryModel>(data.offerCategories, OfferCategoryModel);
    }
  }
}
