/**
 * User cities CGU Modal selectors
 */

import { createSelector } from 'reselect';
import {initialStateUserCitiesReducer} from "./reducer";

export const userCitiesCGUKey = 'userCityCGUPage'

const selectCitiesGCGU = (state: any) => state[userCitiesCGUKey] || initialStateUserCitiesReducer;

export const makeSelectUserCitiesCguCities = () =>
  createSelector(
    selectCitiesGCGU,
    citiesState => citiesState.cities,
  );
export const makeSelectUserCitiesCguLoading = () =>
  createSelector(
    selectCitiesGCGU,
    citiesState => citiesState.loading,
  );
export const makeSelectUserCitiesCguCity = () =>
  createSelector(
    selectCitiesGCGU,
    citiesState => citiesState.city
  );
