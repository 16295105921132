import React from 'react';
import {Typography} from "@material-ui/core";
import {useStylesPaymentModal} from "../styles";

interface OrderSavingPropsI {
  savings: number;
}

export const OrderSaving = ({savings}: OrderSavingPropsI) => {
  const classes = useStylesPaymentModal();

  return (
    <div className={classes.totalSaving}>
      <Typography variant="body2" style={{fontSize: 14}}>Economies réalisées</Typography>
      <Typography className={classes.savingsAmountCommon}>{savings}€</Typography>
    </div>
  )
}
