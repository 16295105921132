import { makeStyles, darken } from '@material-ui/core/styles';

const description = {
  fontSize: '16px',
  lineHeight: '19px',
  fontWeight: 200,
  color: '#878B8D',
  fontFamily: 'ProximaNovaBold'
}

const title = {
  fontFamily: 'ProximaNovaBold',
  fontSize: '16px',
  marginBottom: '4px',
  lineHeight: '19px'
}

interface Props {
  isMobile: boolean,
  hasOfferCodes: boolean
}

export const useStylesProductFields = makeStyles((theme) => ({
  root: (props: Props) => ({
    display: 'flex',
    flexDirection: props.isMobile && props.hasOfferCodes ? 'column' : 'row',
    flexGrow: 1,
    '& .root-container': {
      marginBottom: '16px',
      display: 'flex',
      flexGrow: 1,
      '& .card-div': {
        display: "flex",
        alignItems: "center",
        borderRadius: 5,
        marginRight: '16px',
        boxShadow: '0 2px 4px 0 rgba(152,152,152,0.62)',

      },
      '& img': {
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'contain',
        width: "100%",
        height: "auto"
      },
      '& .content': {
        flexGrow: 1,
        display: 'flex',
        flexDirection: (props.isMobile || props.hasOfferCodes) ? 'column' : 'row',
        flexWrap: 'nowrap',
        '& .text': {
          flexGrow: 1,
          marginRight: props.isMobile ? 0 : 8,
          marginTop: 12,
          '& .title': {
            ...title
          },
          '& .description': {
            ...description
          }
        }
      }
    },
    '& .offerCode': {
      marginLeft: 16,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }
  }),
  quantity: (props: Props) => ({
    marginTop: (props.isMobile || props.hasOfferCodes) ? '8px' : 0,
    display: 'flex',
    flexDirection: (props.isMobile || props.hasOfferCodes) ? 'row' : 'column',
    '& .container': {
      display: 'flex',
      flexFlow: 'row nowrap',
      '& .counter': {
        display: 'flex',
        flexDirection: 'row',
        marginRight: '8px',
        '& button': {
          marginTop: -1,
          '& .MuiSvgIcon-root': {
            height: '20px',
            width: '20px',
          },
          padding: 4,
        },
        '& p': {
          ...description,
          position: 'relative',
          top: '1px'
        }
      }
    }
  }),
  divider: (props: Props) => ({
    marginBottom: '8px',
    marginTop: props.isMobile ? '11px' : 8,
    marginRight: (props.isMobile || props.hasOfferCodes) ? 8 : 0,
    backgroundColor: theme.palette.primary.main,
    flexGrow: (props.isMobile || props.hasOfferCodes) ? 1 : 0
  }),
  productDivider: {
    margin: '16px 0'
  },
  button: (props: Props) => ({
    minWidth: props.isMobile ? 250 : 165,
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.main, 0.3)
    }
  })
}));
