/**
 * Update Password Saga
 */

import {call, put, select, takeLatest} from 'redux-saga/effects';
import { SmartpushAPI, SMARTPUSH_API_URL_USER_UPDATE_PASSWORD } from "@smartpush-front/api";
import {SUBMIT_UPDATE_PASSWORD} from "./constants";
import {submitUpdatePasswordError, submitUpdatePasswordSuccess} from "./actions";
import {getToken} from "../../../store/selectors/userSelector";

/**
 * Update password request/response handler
 */
export function* updatePasswordCall(action: any) {
  const token = yield select(getToken());
  const options = {
    url          : SMARTPUSH_API_URL_USER_UPDATE_PASSWORD,
    data         : {
      currentPassword   : action.oldPassword,
      newPassword       : action.newPassword
    },
    config: {
      headers: {
        Authorization: token
      }
    }
  };

  try {

    yield call(SmartpushAPI.post, options);
    /** Put tokens in redux */
    yield put(submitUpdatePasswordSuccess());
  } catch (err) {
    yield put(submitUpdatePasswordError(err));
  }
}


/**
 * Root saga manages watcher lifecycle
 */
export default function* updatePasswordSaga() {
  // Watches for SUBMIT_UPDATE_PASSWORD actions and calls getUser when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(SUBMIT_UPDATE_PASSWORD, updatePasswordCall);
}
