import React, {useEffect} from 'react';
import {signUpSchema} from "./formSchema";
import {Grid, makeStyles, Theme} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {
  CustomDatePicker,
  CustomEmailWidget,
  CustomSelectWidget,
  CustomTextFieldWidget
} from "../../../../utils/forms/widgets";
import {AjvError, withTheme} from "@rjsf/core";
import {Theme as MuiTheme} from "@rjsf/material-ui";
import {useDispatch, useSelector} from "react-redux";
import {putUserDataProfile} from "./actions";
import {useInjectSaga} from "../../../../utils/injectSaga";
import saga from './saga';
import Modal from "@material-ui/core/Modal";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons";
import {COLORS} from "@smartpush-front/styles";
import Divider from "@material-ui/core/Divider";
import Fab from "@material-ui/core/Fab";
import {makeSelectUserData} from "../../../store/selectors/userSelector";
import {userCitiesCGUKey} from '../../../../utils/forms/cityWidget/CityWidget';
import {useInjectReducer} from '@smartpush-front/store';
import userCitySaga from '../../../../utils/forms/cityWidget/saga';
import {userCityReducer} from '../../../../utils/forms/cityWidget/reducer';
import {getUserCityById} from '../../../../utils/forms/cityWidget/actions';
import {CustomCheckboxUserMarketingUserDataModal} from '../../../../utils/forms/widgets';

const useStylesSignUp = makeStyles((theme: Theme) => ({
  box: {
    padding: theme.spacing(4, 2, 2, 2)
  },
  closeButton: {
    color: COLORS.black
  },
  iconNoPadding: {
    padding: 0,
    borderRadius: '5px'
  },
  modal: {
    root: {
      outline: 'none',
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&$focus': {
      outline: 0
    }
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: '0 2px 4px 0 rgba(152,152,152,0.62)',
    padding: theme.spacing(2, 0, 3),
    maxWidth: '600px',
    borderRadius: '5px',
    outline: 0
  },
  divider: {
    marginTop: 24,
    marginBottom: 24
  },
  title: {
    fontSize: 24,
    lineHeight: '29px',
    fontFamily: 'ProximaNovaBold',
    fontWeight: 700,
    paddingBottom: 8,
    paddingLeft: 16
  },
  description: {
    fontSize: 16,
    lineHeight: '19px',
    fontWeight: 300,
    paddingLeft: 16,
    paddingRight: 16
  },
  horizontalPadding: {
    paddingLeft: 16,
    paddingRight: 16
  },
  sectionInput: {
    '& .MuiInputBase-input': {
      marginBottom: 0
    },
    '& .MuiOutlinedInput-root': {
      backgroundColor: '#E1E9EC',
      height: 34,
      borderRadius: 5,
      color: '#232323'
    },
    '& .MuiSelect-root': {
      backgroundColor: '#E1E9EC',
      borderRadius: 5,
      color: '#232323'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'white',
      borderRadius: 5
    },
    '& .MuiOutlinedInput-input': {
      padding: 8,
      fontSize: 16
    },
    '& .MuiInputLabel-outlined': {
      transform: 'translate(10px, 10px) scale(1)'
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      transform: 'translate(14px, -6px) scale(0.75);'
    },
    '& .MuiSelect-iconOutlined': {
      top: 'auto'
    }
  },
  button: {
    borderRadius: 5,
    color: COLORS.white,
    fontFamily: 'ProximaNovaBold',
    fontSize: 16,
    heightLine: '18px'
  },
  sectionInputAutocomplete: {
    '& .MuiAutocomplete-input': {
      height: '19px !important'
    }
  }
}));

const Form = withTheme(MuiTheme);

function transformErrors(errors: AjvError[]) {
  let errorsEnhanced = [...errors];
  errorsEnhanced = errorsEnhanced.filter((error: any) => (error.property !== ".birthDate"));

  return errorsEnhanced.map((error: any) => {
    return error;
  });
}

interface UserDataPageProps {
  open: boolean,
  handleClose: () => void
}

const key = 'userDataProfile';

export function UserDataModal({
                                open,
                                handleClose
                              }: UserDataPageProps) {
  const dispatch = useDispatch();
  useInjectSaga({key: userCitiesCGUKey, saga: userCitySaga});
  useInjectReducer({key: userCitiesCGUKey, reducer: userCityReducer});
  const userData = useSelector(makeSelectUserData());
  const classes = useStylesSignUp();

  useEffect(() => {
    if (userData?.onBoardingSettings?.cityId) {
      dispatch(getUserCityById(userData.onBoardingSettings.cityId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const UISchema = {
    'gender': {
      "ui:widget": "radio"
    },
    'tosMarketing': {
      "ui:widget": (props: any) => (
        <CustomCheckboxUserMarketingUserDataModal {...props} userTosMarketing={userData?.marketingConsentedAt}/>)
    },
    'email': {
      'ui:disabled': true
    }
  };
  const widgets = {
    DateTimeWidget: (props: any) => (<CustomDatePicker {...props} className={classes.sectionInput}/>),
    EmailWidget: (props: any) => (<CustomEmailWidget {...props} className={classes.sectionInput}/>),
    TextWidget: (props: any) => (<CustomTextFieldWidget {...props} className={classes.sectionInput}/>),
    SelectWidget: (props: any) => (<CustomSelectWidget {...props} className={classes.sectionInput}/>),
  };
  useInjectSaga({key, saga});
  useInjectSaga({key: userCitiesCGUKey, saga: userCitySaga});
  useInjectReducer({key: userCitiesCGUKey, reducer: userCityReducer});

  function ObjectFieldTemplate(props: any): any {
    const formContainers = [
      {
        'title': '',
        'fields': [
          'gender',
          'lastName',
          'firstName',
          'email',
          'tosMarketing'
        ]
      }
    ];

    return (
      <div>
        <Grid container justify="center">
          <Grid item xs={12} color={"#b0b0b0"}>
            <Typography className={classes.title}>
              {props.title}
            </Typography>
          </Grid>
          <Grid item xs={12} color={"#b0b0b0"}>
            <Typography className={classes.description}>
              {props.description}
            </Typography>
          </Grid>
        </Grid>
        <Divider className={classes.divider}/>
        <div>
          <Grid container
                justify="center"
          >
            {
              formContainers.map((container: any, index: number) => {
                return (
                  <Grid key={index} item xs={12} sm={9} md={8} lg={7} color={"#b0b0b0"}>
                    <Paper key={index} elevation={0}>
                      <Typography variant="h2" component="h2" gutterBottom>
                        {container.title}
                      </Typography>
                      <Grid container>
                        {props.properties.filter((e: any) => {
                          return container.fields.includes(e.name)
                        }).map((element: any, i: number) => {
                          return (
                            <Grid item xs={12}>
                              <Box key={i} className={classes.box}>
                                {element.content}
                              </Box>
                            </Grid>
                          );
                        })}
                      </Grid>
                    </Paper>
                  </Grid>
                );
              })
            }
          </Grid>
        </div>
        <Divider className={classes.divider}/>
      </div>
    );
  }

  const handleSubmit = (evt: any) => {
    if (!evt && evt.preventDefault) evt.preventDefault();

    dispatch(putUserDataProfile({
      gender: evt.formData.gender,
      firstName: evt.formData.firstName,
      lastName: evt.formData.lastName,
      marketingCookies: evt.formData.tosMarketing
    }))
    handleClose();
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="user-modal-form"
      aria-describedby="user-modal-description"
      className={classes.modal}
    >
      <Grid container className={classes.paper}>
        <Grid item xs={12}>
          <Grid container direction="row-reverse" alignItems="flex-start" className={classes.horizontalPadding}>
            <Grid item>
              <div className={classes.iconNoPadding} onClick={handleClose}>
                <FontAwesomeIcon icon={faTimes} className={classes.closeButton}/>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Form
            schema={signUpSchema}
            ObjectFieldTemplate={ObjectFieldTemplate}
            widgets={widgets}
            uiSchema={UISchema}
            transformErrors={transformErrors}
            formData={{
              gender: userData.gender,
              email: userData.email,
              firstName: userData.firstName,
              lastName: userData.lastName,
              tosMarketing: !!userData.marketingConsentedAt
            }}
            //onChange={console.log('changed')}
            onSubmit={(e: any) => {
              handleSubmit(e);
            }}
            onError={(e: any) => console.log(e)}
          >
            <Grid
              container
              justify="flex-end"
              className={classes.horizontalPadding}
            >
              <Grid item color={"#b0b0b0"}>
                <Fab color="primary" type="submit" variant="extended" className={classes.button}>
                  Enregistrer
                </Fab>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      </Grid>
    </Modal>
  );
}
