import React from 'react'
import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import {ProductFields} from "@smartpush-front/payment";
import {useStylesPaymentModal} from "../styles";
import OrderContent from "../../OrderContent/OrderContent";
import {SETTINGS} from "@smartpush-front/styles";

interface OrderContentContainerProps {
  isMobile: boolean;
  items: any[];
  pendingOrSuccess: boolean;
}

export const OrderContentContainer = ({isMobile, items, pendingOrSuccess}: OrderContentContainerProps) => {
  const classes = useStylesPaymentModal();

  const getSpecificCss = () => {
    return clsx(classes.sectionTitle, classes.orderContent)
  }

  return (
    <Grid container alignItems="flex-start" className={classes.sectionPaddingOrder}>
      <Grid item xs={12}>
        <Typography className={getSpecificCss()}>
          Contenu de la commande
        </Typography>
      </Grid>
      {!isMobile && <><OrderContent items={items} pendingOrSuccess={pendingOrSuccess} /></> }
      {isMobile && <><ProductFields isMobile={isMobile} readOnly={true} items={items} /></> }
    </Grid>
  )
}
