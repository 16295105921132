import React from 'react';
import ReactDOM from 'react-dom';

import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { StylesProvider } from '@material-ui/core/styles';

import { store, history } from "@smartpush-front/store";

import App from './app/app';

ReactDOM.render(
    <Provider store={store} >
      <ConnectedRouter history={history}>
        <StylesProvider injectFirst>
          <App />
        </StylesProvider>
      </ConnectedRouter>
    </Provider>,
  document.getElementById('root')
);
