/*
 * User Data Constants
 */

/**
 * USER DATA PAGE
 *
 * @type {string}
 */
export const PUT_USER_DATA_PROFILE = 'SmartPush/User/PUT_USER_DATA_PROFILE';
export const PUT_USER_DATA_PROFILE_SUCCESS = 'SmartPush/User/PUT_USER_DATA_PROFILE_SUCCESS';
export const PUT_USER_DATA_PROFILE_ERROR = 'SmartPush/User/PUT_USER_DATA_PROFILE_ERROR';
