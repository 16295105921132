import { Model } from '@smartpush-front/api';

class OfferCategoryModel extends Model {
  public id: string;
  public parent?: any;
  public name: string;
  public image: string;
  public children?: OfferCategoryModel[] = [];

  constructor(data: any) {
    super(data);

    this.id = data.id;
    this.parent = data.parent;
    this.name = data.name;
    this.image = data.image;
    this.children = data.children;

    if (data) {
      if (data.parent) {
        this.parent = new OfferCategoryModel(data.parent);
      }

      if (data.children) {
        this.children = Model.loadCollection<OfferCategoryModel>(data.children, OfferCategoryModel);
      }
    }
  }
}

export default OfferCategoryModel;
