/*
 * Offers Page Actions
 *
 * Actions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */

import {
  GET_BRAND,
  GET_BRAND_ERROR,
  GET_BRAND_SUCCESS,
  GET_OFFERS,
  GET_OFFERS_SUCCESS,
  GET_OFFERS_ERROR,
  TOGGLE_DISPLAY_MAP,
  TOGGLE_DISPLAY_OFFER_MODAL,
  TOGGLE_SELECTED_SHOP_DISPLAY_MAP,
  GET_MORE_OFFERS,
  GET_MORE_OFFERS_SUCCESS,
  GET_MORE_OFFERS_ERROR,
  GET_MORE_OFFERS_FOR_EDF,
  GET_MORE_OFFERS_FOR_EDF_SUCCESS,
  GET_MORE_OFFERS_FOR_EDF_ERROR,
  GET_OFFER,
  GET_OFFER_ERROR,
  GET_OFFER_SUCCESS,
  LOAD_OFFERS,
  LOAD_OFFERS_SUCCESS,
  LOAD_OFFERS_ERROR,
  GET_CITY,
  GET_CITY_ERROR,
  GET_CITY_SUCCESS,
  GET_RECOMMENDED_OFFERS,
  GET_RECOMMENDED_OFFERS_SUCCESS,
  GET_RECOMMENDED_OFFERS_ERROR,
  GET_HIGHLIGHTED_OFFERS,
  GET_HIGHLIGHTED_OFFERS_SUCCESS,
  GET_HIGHLIGHTED_OFFERS_ERROR,
  GET_BRAND_WITH_OFFERS,
  GET_BRAND_WITH_OFFERS_SUCCESS,
  GET_BRAND_WITH_OFFERS_ERROR,
  TOGGLE_DISPLAY_BRAND_MODAL,
  TOGGLE_DISPLAY_SEARCH_MODAL,
  SEND_ANALYTICS_OFFER_VIEW,
  SEND_ANALYTICS_OFFER_VIEW_SUCCESS,
  SEND_ANALYTICS_OFFER_VIEW_ERROR,
  SEND_ANALYTICS_OFFER_WALLET,
  SEND_ANALYTICS_OFFER_WALLET_SUCCESS,
  SEND_ANALYTICS_OFFER_WALLET_ERROR,
  SEND_ANALYTICS_OFFER_PRINT,
  SEND_ANALYTICS_OFFER_PRINT_SUCCESS,
  SEND_ANALYTICS_OFFER_PRINT_ERROR,
  GET_MORE_OFFERS_PAGINATION,
  GET_MORE_OFFERS_PAGINATION_SUCCESS,
  GET_MORE_OFFERS_PAGINATION_ERROR,
  GET_OFFERS_MAP,
  GET_OFFERS_MAP_SUCCESS,
  RESET_CITY,
} from './constants';
import { OfferModel } from "./Offer/OfferModel";

/**
 * Load the offers, this action starts the request saga
 * @param  {object} searchOptions   All options to search offers
 * @param  {object} userCoordinates Coordinates of user, if there is any, for recommended offers
 * @return {object}                 An action object with a type of GET_OFFERS
 */
export function getOffers(searchOptions: any, userCoordinates: any) {
  return {
    type: GET_OFFERS,
    searchOptions,
    userCoordinates
  };
}

/**
 * Load offers with related shops
 *
 * @param {object} userCoordinates Coordinates of user, if there is any,
 */
export function getOffersGeolocation(userCoordinates: any, categoryId: any) {
  return {
    type: GET_OFFERS_MAP,
    userCoordinates,
    categoryId
  }
}

/**
 * Dispatched when the offers are correctly retrieved by the request saga
 * @param  {object} offers                All offers retrieved by the authenticated route
 *
 * @return {object}                     An action object with a type of GET_OFFERS_SUCCESS passing the offers
 */
export function getOffersSuccess(offers: any) {
  return {
    type: GET_OFFERS_SUCCESS,
    offers,
  };
}

/**
 * Dispatch offers with shops
 *
 * @param offers
 *
 * @return {object} An action with offers
 */
export function getOffersMapSuccess(offers: any) {
  return {
    type: GET_OFFERS_MAP_SUCCESS,
    offers
  }
}

/**
 * Dispatched when offers loading fail
 * @param  {object} error The error
 * @return {object}       An action object with a type of GET_OFFERS_ERROR passing the error
 */
export function getOffersError(error: any) {
  return {
    type: GET_OFFERS_ERROR,
    error
  };
}

/**
 * Load an offer, this action starts the request saga
 * @param  {string} offerId   Offer id to retrieve
 * @param  {boolean} switchBrandPage  from brandPage boolean to stay or change of page
 * @return {object}           An action object with a type of GET_OFFER
 */
export function getOffer(offerId: string, switchBrandPage?: boolean) {
  return {
    type: GET_OFFER,
    offerId,
    switchBrandPage
  };
}

/**
 * Load the recommended offers, this action starts the request saga
 * @return {object}                 An action object with a type of GET_RECOMMENDED_OFFERS
 */
export function getRecommendedOffers() {
  return {
    type: GET_RECOMMENDED_OFFERS
  };
}

/**
 * Dispatched when the recommended offers are correctly retrieved by the request saga
 * @param  {object} recommendedOffers   All offers retrieved by the authenticated route
 *
 * @return {object}                     An action object with a type of GET_RECOMMENDED_OFFERS_SUCCESS passing the offers
 */
export function getRecommendedOffersSuccess(recommendedOffers: any) {
  return {
    type: GET_RECOMMENDED_OFFERS_SUCCESS,
    recommendedOffers
  };
}

/**
 * Dispatched when recommended offers loading fail
 * @param  {object} error The error
 * @return {object}       An action object with a type of GET_RECOMMENDED_OFFERS_ERROR passing the error
 */
export function getRecommendedOffersError(error: any) {
  return {
    type: GET_RECOMMENDED_OFFERS_ERROR,
    error
  };
}

/**
 * Load the highlighted offers, this action starts the request saga
 *
 * @return {object}                 An action object with a type of GET_HIGHLIGHTED_OFFERS
 */
export function getHighlightedOffers() {
  return {
    type: GET_HIGHLIGHTED_OFFERS
  };
}

/**
 * Dispatched when the highlighted offers are correctly retrieved by the request saga
 * @param  {object} highlightedOffers   All offers retrieved by the authenticated route
 *
 * @return {object}                     An action object with a type of GET_HIGHLIGHTED_OFFERS_SUCCESS passing the offers
 */
export function getHighlightedOffersSuccess(highlightedOffers: any,) {
  return {
    type: GET_HIGHLIGHTED_OFFERS_SUCCESS,
    highlightedOffers
  };
}

/**
 * Dispatched when highlighted offers loading fail
 * @param  {object} error The error
 * @return {object}       An action object with a type of GET_HIGHLIGHTED_OFFERS_ERROR passing the error
 */
export function getHighlightedOffersError(error: any) {
  return {
    type: GET_HIGHLIGHTED_OFFERS_ERROR,
    error
  };
}

/**
 * Dispatched when the offer is correctly retrieved by the request saga
 * @param  {object} offer   Offer retrieved by the authenticated route
 * @param pagination
 * @param  {object} switchBrandPage   Retrieve the boolean if page is still brand page or retail page
 * @return {object}         An action object with a type of GET_OFFER_SUCCESS passing the offer
 */
export function getOfferSuccess(offer: any, pagination: any, switchBrandPage?: boolean) {
  return {
    type: GET_OFFER_SUCCESS,
    offer,
    pagination,
    switchBrandPage
  };
}

/**
 * Dispatched when offer loading fail
 * @param  {object} error The error
 * @return {object}       An action object with a type of GET_OFFER_ERROR passing the error
 */
export function getOfferError(error: any) {
  return {
    type: GET_OFFER_ERROR,
    error
  };
}

/**
 * Load multiple offers, this action starts the request saga
 * @param  {string[]} offerIds   Offer id to retrieve
 * @return {object}           An action object with a type of GET_OFFER
 */
export function loadOffers(offerIds: string[]) {
  return {
    type: LOAD_OFFERS,
    offerIds
  };
}

/**
 * Dispatched when the offer is correctly retrieved by the request saga
 * @param  {object} offers   Offer retrieved by the authenticated route
 * @return {object}         An action object with a type of GET_OFFER_SUCCESS passing the offer
 */
export function loadOffersSuccess(offers: any[]) {
  return {
    type: LOAD_OFFERS_SUCCESS,
    offers
  };
}

/**
 * Dispatched when offer loading fail
 * @param  {object} error The error
 * @return {object}       An action object with a type of GET_OFFER_ERROR passing the error
 */
export function loadOffersError(error: any) {
  return {
    type: LOAD_OFFERS_ERROR,
    error
  };
}

/**
 * Load more offers, this action starts the request saga
 * @param  {object}       searchOptions           All options to search offers
 * @param  {object}       userCoordinates         Coordinates of user, if there is any, for recommmended offers
 * @param  {object}       pagination              Pagination for the request
 * @param  {object|null}  webOfferPagination      Web offers pagination for the request
 *
 * @return {object}                               An action object with a type of GET_OFFERS
 */
export function getMoreOffers(searchOptions: any, userCoordinates: any, pagination: any, webOfferPagination?: any) {
  return {
    type: GET_MORE_OFFERS,
    searchOptions,
    userCoordinates,
    pagination,
    webOfferPagination
  };
}

/**
 * Dispatched when the more offers are correctly retrieved by the request saga
 * @param  {object} offers              All offers retrieved by the authenticated route
 *
 * @return {object}                     An action object with a type of GET_MORE_OFFERS_SUCCESS passing the offers
 */
export function getMoreOffersSuccess(offers: any) {
  return {
    type: GET_MORE_OFFERS_SUCCESS,
    offers
  };
}

/**
 * Dispatched when more offers loading fail
 * @param  {object} error The error
 * @return {object}       An action object with a type of GET_MORE_OFFERS_ERROR passing the error
 */
export function getMoreOffersError(error: any) {
  return {
    type: GET_MORE_OFFERS_ERROR,
    error
  };
}


/**
 * Load more offers, this action starts the request saga
 * @param  {object}       pagination              Pagination for the request
 * @param  {object|null}  webOfferPagination      Web offers pagination for the request
 * @param  {object|null}  currentPage             CurrentPage for the request
 * @param  {object|null}  limit                   Limit pagination for the request
 *
 * @return {object}                               An action object with a type of GET_MORE_OFFERS_PAGINATION
 */
export function getOffersPagination(pagination?: any, webOfferPagination?: any, currentPage?: number, limit?: number) {
  return {
    type: GET_MORE_OFFERS_PAGINATION,
    pagination,
    webOfferPagination,
    currentPage,
    limit
  };
}

/**
 * Dispatched when the more offers are correctly retrieved by the request saga
 * @param  {object} webOffers           Web offers retrieved by the authenticated route
 * @param  {object} offers              Offers retrieved by the authenticated route
 *
 * @return {object}                     An action object with a type of GET_MORE_OFFERS_PAGINATION_SUCCESS passing the offers
 */
export function getOffersPaginationSuccess(offers: any, webOffers?: any) {
  return {
    type: GET_MORE_OFFERS_PAGINATION_SUCCESS,
    offers,
    webOffers
  };
}

/**
 * Dispatched when more offers loading fail
 * @param  {object} error The error
 * @return {object}       An action object with a type of GET_MORE_OFFERS_PAGINATION_ERROR passing the error
 */
export function getOffersPaginationError(error: any) {
  return {
    type: GET_MORE_OFFERS_PAGINATION_ERROR,
    error
  };
}


/**
 * Dispatched for display or not google map
 * @return {object}       An action object with a type of TOGGLE_DISPLAY_MAP passing the action
 */
export function toggleMap() {
  return {
    type: TOGGLE_DISPLAY_MAP
  };
}

/**
 * Dispatched for display or not google map
 *
 * @param  {any}    shop  Shop where we will leading to on map
 *
 * @return {object}       An action object with a type of TOGGLE_DISPLAY_MAP passing the action
 */
export function toggleSelectedShopInListMap(shop: any) {
  return {
    type: TOGGLE_SELECTED_SHOP_DISPLAY_MAP,
    shop
  };
}

/**
 * Dispatched for display or not offer modal
 *
 * @param  {boolean}  isDisplayed             Is modal displayed
 * @param  {offer}    offer                   Offer to display in modal
 * @param  {string}   componentName           Component name using by walletOffer to retrieve categorized offer from Redux
 * @param  {any?}     preSelectedShopFromMap  Component name using by walletOffer to retrieve categorized offer from Redux
 *
 * @return {object}               An action object with a type of TOGGLE_DISPLAY_MAP passing the action
 */
export function toggleOfferModal(isDisplayed: boolean, offer: OfferModel, componentName: string, preSelectedShopFromMap?: any) {
  return {
    type: TOGGLE_DISPLAY_OFFER_MODAL,
    isDisplayed,
    offer,
    componentName,
    preSelectedShopFromMap
  };
}

/**
 * Dispatched for display or not brand modal
 *
 * @param  {boolean}  isDisplayed             Is modal displayed
 * @param  {offer}    offer                   Offer to display in modal
 * @param  {string}   componentName           Component name using by walletOffer to retrieve categorized offer from Redux
 * @param  {any?}     preSelectedShopFromMap  Component name using by walletOffer to retrieve categorized offer from Redux
 *
 * @return {object}               An action object with a type of TOGGLE_DISPLAY_MAP passing the action
 */
export function toggleBrandModal(isDisplayed: boolean, offer: OfferModel, componentName: string, preSelectedShopFromMap?: any) {
  return {
    type: TOGGLE_DISPLAY_BRAND_MODAL,
    isDisplayed,
    offer,
    componentName,
    preSelectedShopFromMap
  };
}

export function toggleSearchModal() {
  return {
    type: TOGGLE_DISPLAY_SEARCH_MODAL
  }
}

export function getCity(cityId: string) {
  return {
    type: GET_CITY,
    cityId
  }
}

export function getCitySuccess(city: any) {
  return {
    type: GET_CITY_SUCCESS,
    city
  }
}

export function getCityError(error: Error) {
  return {
    type: GET_CITY_ERROR,
    error
  }
}

export function resetCity() {
  return {
    type: RESET_CITY
  }
}

/**
 * Load the brand with list of offers, this action starts the request saga
 * @param  {string} brandId   Brand id to retrieve
 * @return {object}           An action object with a type of GET_BRAND_WITH_OFFERS
 */
export function getBrandWithOffers(brandId: string) {
  return {
    type: GET_BRAND_WITH_OFFERS,
    brandId
  }
}

/**
 * Load the brand, this action starts the request saga
 * @param  {string} brandId   Brand id to retrieve
 * @return {object}           An action object with a type of GET_BRAND_WITH_OFFERS
 */
export function getBrand(brandId: string) {
  return {
    type: GET_BRAND,
    brandId
  }
}

/**
 * Dispatched when the brand with list of offers is correctly retrieved by the request saga
 * @param  {object} brandOffers   Offers retrieved by the authenticated route
 * @return {object}               An action object with a type of GET_BRAND_WITH_OFFERS_SUCCESS passing the offers
 */
export function getBrandWithOffersSuccess(brandOffers: any) {
  return {
    type: GET_BRAND_WITH_OFFERS_SUCCESS,
    brandOffers
  }
}

/**
 * Dispatched when brand with list of offers loading fail
 * @param  {object} error The error
 * @return {object}       An action object with a type of GET_BRAND_WITH_OFFERS_ERROR passing the error
 */
export function getBrandWithOffersError(error: Error) {
  return {
    type: GET_BRAND_WITH_OFFERS_ERROR,
    error
  }
}

/**
 * Dispatched when the brand with list of offers is correctly retrieved by the request saga
 * @param  {object} brandOffers   Offers retrieved by the authenticated route
 * @return {object}               An action object with a type of GET_BRAND_WITH_OFFERS_SUCCESS passing the offers
 */
export function getBrandSuccess(brandOffers: any) {
  return {
    type: GET_BRAND_SUCCESS,
    brandOffers
  }
}

/**
 * Dispatched when the brand with list of offers is correctly retrieved by the request saga
 * @param  {object} brandOffers   Offers retrieved by the authenticated route
 * @return {object}               An action object with a type of GET_BRAND_WITH_OFFERS_SUCCESS passing the offers
 */
export function getBrandError(brandOffers: any) {
  return {
    type: GET_BRAND_ERROR,
    brandOffers
  }
}

/**
 * Send data in analytics_offer_view table
 * @param  {object} data      Data that must be sent
 * @return {object}           An action object with a type of SEND_ANALYTICS_OFFER_VIEW
 */
export function sendAnalyticsOfferView(data: any) {
  return {
    type: SEND_ANALYTICS_OFFER_VIEW,
    data
  }
}

/**
 * Dispatched when datas are correctly sent to the table
 * @return {object}               An action object with a type of SEND_ANALYTICS_OFFER_VIEW_SUCCESS passing datas
 */
export function sendAnalyticsOfferViewSuccess() {
  return {
    type: SEND_ANALYTICS_OFFER_VIEW_SUCCESS,
  }
}

/**
 * Dispatched when sending data to the table fails
 * @param  {object} error The error
 * @return {object}       An action object with a type of SEND_ANALYTICS_OFFER_VIEW_ERROR passing the error
 */
export function sendAnalyticsOfferViewError(error: Error) {
  return {
    type: SEND_ANALYTICS_OFFER_VIEW_ERROR,
    error
  }
}

/**
 * Send data in analytics_offer_wallet table
 * @param  {object} data      Data that must be sent
 * @return {object}           An action object with a type of SEND_ANALYTICS_OFFER_WALLET
 */
export function sendAnalyticsOfferWallet(data: any) {
  return {
    type: SEND_ANALYTICS_OFFER_WALLET,
    data
  }
}

/**
 * Dispatched when datas are correctly sent to the table
 * @return {object}               An action object with a type of SEND_ANALYTICS_OFFER_WALLET_SUCCESS passing datas
 */
export function sendAnalyticsOfferWalletSuccess() {
  return {
    type: SEND_ANALYTICS_OFFER_WALLET_SUCCESS,
  }
}

/**
 * Dispatched when sending data to the table fails
 * @param  {object} error The error
 * @return {object}       An action object with a type of SEND_ANALYTICS_OFFER_WALLET_ERROR passing the error
 */
export function sendAnalyticsOfferWalletError(error: Error) {
  return {
    type: SEND_ANALYTICS_OFFER_WALLET_ERROR,
    error
  }
}

/**
 * Send data in analytics_offer_print table
 * @param  {object} data      Data that must be sent
 * @return {object}           An action object with a type of SEND_ANALYTICS_OFFER_PRINT
 */
export function sendAnalyticsOfferPrint(data: any) {
  return {
    type: SEND_ANALYTICS_OFFER_PRINT,
    data
  }
}

/**
 * Dispatched when datas are correctly sent to the table
 * @return {object}               An action object with a type of SEND_ANALYTICS_OFFER_PRINT_SUCCESS passing datas
 */
export function sendAnalyticsOfferPrintSuccess() {
  return {
    type: SEND_ANALYTICS_OFFER_PRINT_SUCCESS,
  }
}

/**
 * Dispatched when sending data to the table fails
 * @param  {object} error The error
 * @return {object}       An action object with a type of SEND_ANALYTICS_OFFER_PRINT_ERROR passing the error
 */
export function sendAnalyticsOfferPrintError(error: Error) {
  return {
    type: SEND_ANALYTICS_OFFER_PRINT_ERROR,
    error
  }
}

export function getMoreOffersForEdf(searchOptions: any, userCoordinates: any, pagination: any, webOfferPagination?: any, pages?: any, totalPagination?: any) {
  return {
    type: GET_MORE_OFFERS_FOR_EDF,
    pages,
    searchOptions,
    userCoordinates,
    pagination,
    webOfferPagination,
    totalPagination
  };
}

export function getMoreOffersForEdfSuccess(offers: any, webOffers?: any, pagination?: any) {
  return {
    type: GET_MORE_OFFERS_FOR_EDF_SUCCESS,
    offers,
    webOffers,
    pagination
  };
}

export function getMoreOffersForEdfError(error: any) {
  return {
    type: GET_MORE_OFFERS_FOR_EDF_ERROR,
    error
  };
}
