/*
 * Update Password Constants
 */

/**
 * USER UPDATE PASSWORD
 *
 * @type {string}
 */
export const SUBMIT_UPDATE_PASSWORD = 'SmartPush/User/SUBMIT_UPDATE_PASSWORD';
export const SUBMIT_UPDATE_PASSWORD_SUCCESS = 'SmartPush/User/SUBMIT_UPDATE_PASSWORD_SUCCESS';
export const SUBMIT_UPDATE_PASSWORD_ERROR = 'SmartPush/User/SUBMIT_UPDATE_PASSWORD_ERROR';
export const CLEAR_UPDATE_PASSWORD = 'SmartPush/User/CLEAR_UPDATE_PASSWORD';
