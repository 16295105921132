/**
 * Gets the token of the user
 */

import {call, put, select, takeLatest} from 'redux-saga/effects';
import {
  getUserDataWithTokenError,
  getUserDataWithTokenSuccess,
  GET_USER_DATA_WITH_TOKEN,
  SUBMIT_REFRESH_TOKEN_SUCCESS,
  getToken,
  LS_OAUTH_ACCESS_TOKEN_KEY,
  LS_OAUTH_REFRESH_TOKEN_KEY,
  LS_OAUTH_TOKENS
} from '@smartpush-front/store';
import {
  SmartpushAPI,
  SMARTPUSH_API_URL_USER_ME
} from '@smartpush-front/api';
import UserModel from "./UserModel";

/**
 * User data request/response handler
 */
export function* getUserDataWithToken() {
  const token = yield select(getToken());
  const options = {
    url  : SMARTPUSH_API_URL_USER_ME,
    config: {
      headers: {
        Authorization: token
      }
    }
  };

  try {
    const response = yield call(SmartpushAPI.get, options);
    /** Retrieve User data to redux */
    yield put(getUserDataWithTokenSuccess(response.data));
  } catch (err) {
    yield put(getUserDataWithTokenError(err));
  }
}

export function* userSetTokenWithRefreshCall(action: any) {
  const optionsMe = {
    url  : SMARTPUSH_API_URL_USER_ME,
    model: UserModel,
    config: {
      headers: {
        Authorization: action.accessToken
      }
    }
  };

  try {
    /** Put tokens in local storage for further use */
    yield call(persistAccessToken, action);
    const response = yield call(SmartpushAPI.get, optionsMe);
    /** Retrieve User data to redux */
    yield put(getUserDataWithTokenSuccess(response.data));
  } catch (err) {
    yield put(getUserDataWithTokenError(err));
  }
}

function* persistAccessToken(action: any) {
  try {
    const tokens: any                       = {};
    tokens[LS_OAUTH_ACCESS_TOKEN_KEY]  = action.accessToken;
    tokens[LS_OAUTH_REFRESH_TOKEN_KEY] = action.refreshToken;
    yield localStorage.setItem(LS_OAUTH_TOKENS, JSON.stringify(tokens));
  } catch (e) {
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* userSaga() {
  // Watches for SUBMIT_LOGIN actions and calls getUser when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(GET_USER_DATA_WITH_TOKEN, getUserDataWithToken);
  yield takeLatest(SUBMIT_REFRESH_TOKEN_SUCCESS, userSetTokenWithRefreshCall);
}
