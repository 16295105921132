import React from "react";
import { Grid } from "@material-ui/core";
import {useStylesPaymentModal} from "../styles";
import Typography from "@material-ui/core/Typography";
import {Alert} from "@material-ui/lab";
import InfoIcon from "@material-ui/icons/Info";
import AlertTitle from "@material-ui/lab/AlertTitle";
import {SETTINGS} from "@smartpush-front/styles";

export const OrderRequestInvalidMessage = () => {
  const classes = useStylesPaymentModal();
  return (
    <Grid item xs={12}>
        <Alert
            icon={<InfoIcon/>}
            variant="filled"
            severity="warning"
            className={classes.alert}>
            <AlertTitle>Paiement non validé</AlertTitle>
            <Typography>
              Votre paiement n'a pu aboutir. {SETTINGS?.familiarity ? 'Tu peux' : 'Veuillez'} vérifier les informations saisies pour régler {SETTINGS?.familiarity ? 'ta' : 'votre'} commande.
            </Typography>
        </Alert>
    </Grid>
  );
}
