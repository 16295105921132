import {store} from "@smartpush-front/store";
import {ImageService} from "../../../../apps/phoenix/src/utils/imageUtil";

/** Media types **/
export const LOGO_TYPE = 'logo';
export const OFFER_TYPE = 'offer';
export const VIDEO_TYPE = 'video';

/** Media names **/
export const LOGO_MEDIA = 'logo_full';
export const VIDEO_BOOSTER_MEDIA = 'video_booster';
export const SHOP_COUPON_MEDIA = 'shop_coupon';
export const WEB_COUPON_MEDIA = 'web_coupon';
export const CASHBACK_MEDIA = 'cashback';
export const ECARD_MEDIA = 'ecard';
export const LOGO_MOBILE_MEDIA = "logo_mobile";

/** Features **/
export const SMARTACCOUNT = 'smartaccount';
export const OPTIONAL_ONBOARDING = 'optionalOnboarding';
export const HIDE_COOKIE_BAR = 'hideCookieBar';
export const HIDE_FOOTER = 'hideFooter';
export const HIDE_LOGO = 'hideLogo';
export const HIDE_MENU = 'hideMenu';
export const DISPLAY_SAVINGS = 'displaySavings';


export class PartnerSettingsService {
  static getMediaPath = (type: string|null, name: string|null): string|undefined => {
    if (!type || !name) {
      return null;
    }

    const partnerSettings = store.getState().global?.partnerSettings;

    const media = partnerSettings?.media?.find(
      (m:any) => m.name === name && m.type === type
    );

    if (media) {
      return ImageService.getBaseUrlMedia() + media.path;
    }

    return undefined;
  }

  static isFeatureEnable = (name: string): boolean => {
    const partnerSettings = store.getState().global?.partnerSettings;

    return !!partnerSettings?.features?.find((f:any) => f.name === name);
  }
}
