
export const objectToParams = (obj: any) => {
  return Object.keys(obj)
    .map((key) => {
      if (typeof obj[key] === 'object' && obj[key] !== null) {
        return Object.keys(obj[key]).map(
          subKey => `${key}[${subKey}]=${encodeURIComponent(obj[key][subKey])}`
        ).join('&')
      } else if (key !== 'limit' && key !== 'page') {
        return `filters[${key}]=${encodeURIComponent(obj[key])}`;
      } else {
        return `${key}=${obj[key]}`;
      }
    }

    )
    .join('&');
};

