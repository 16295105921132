/*
 * Offers Page Constants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * config here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

/**
 * OFFERS PAGE
 *
 * @type {string}
 */

//
// OFFERS WITHOUT HIGHLIGHTED AND RECOMMENDED
//
export const GET_OFFERS = 'SmartPush/OffersPage/GET_OFFERS';
export const GET_OFFERS_SUCCESS = 'SmartPush/OffersPage/GET_OFFERS_SUCCESS';
export const GET_OFFERS_ERROR = 'SmartPush/OffersPage/GET_OFFERS_ERROR';

//
// HIGHLIGHTED OFFERS
//
export const GET_HIGHLIGHTED_OFFERS = 'SmartPush/OffersPage/GET_HIGHLIGHTED_OFFERS';
export const GET_HIGHLIGHTED_OFFERS_SUCCESS = 'SmartPush/OffersPage/GET_HIGHLIGHTED_OFFERS_SUCCESS';
export const GET_HIGHLIGHTED_OFFERS_ERROR = 'SmartPush/OffersPage/GET_HIGHLIGHTED_OFFERS_ERROR';

//
// RECOMMENDED OFFERS
//
export const GET_RECOMMENDED_OFFERS = 'SmartPush/OffersPage/GET_RECOMMENDED_OFFERS';
export const GET_RECOMMENDED_OFFERS_SUCCESS = 'SmartPush/OffersPage/GET_RECOMMENDED_OFFERS_SUCCESS';
export const GET_RECOMMENDED_OFFERS_ERROR = 'SmartPush/OffersPage/GET_RECOMMENDED_OFFERS_ERROR';


//
// GET OFFER BY ID
//
export const GET_OFFER = 'SmartPush/OffersPage/GET_OFFER';
export const GET_OFFER_SUCCESS = 'SmartPush/OffersPage/GET_OFFER_SUCCESS';
export const GET_OFFER_ERROR = 'SmartPush/OffersPage/GET_OFFER_ERROR';

//
// LOAD OFFERS BY IDs
//
export const LOAD_OFFERS = 'SmartPush/OffersPage/LOAD_OFFERS';
export const LOAD_OFFERS_SUCCESS = 'SmartPush/OffersPage/LOAD_OFFERS_SUCCESS';
export const LOAD_OFFERS_ERROR = 'SmartPush/OffersPage/LOAD_OFFERS_ERROR';

//
// ADD MORE OFFERS TO LIST WITH PAGINATION FOR EDF
//
export const GET_MORE_OFFERS_FOR_EDF = 'SmartPush/OffersPage/GET_MORE_OFFERS_FOR_EDF';
export const GET_MORE_OFFERS_FOR_EDF_SUCCESS = 'SmartPush/OffersPage/GET_MORE_OFFERS_FOR_EDF_SUCCESS';
export const GET_MORE_OFFERS_FOR_EDF_ERROR = 'SmartPush/OffersPage/GET_MORE_OFFERS_FOR_EDF_ERROR';

//
// ADD MORE OFFERS TO LIST WITH PAGINATION
//
export const GET_MORE_OFFERS = 'SmartPush/OffersPage/GET_MORE_OFFERS';
export const GET_MORE_OFFERS_SUCCESS = 'SmartPush/OffersPage/GET_MORE_OFFERS_SUCCESS';
export const GET_MORE_OFFERS_ERROR = 'SmartPush/OffersPage/GET_MORE_OFFERS_ERROR';


//
// ADD MORE OFFERS TO LIST WITH PAGE CALL
//
export const GET_MORE_OFFERS_PAGINATION = 'SmartPush/OffersPage/GET_MORE_OFFERS_PAGINATION';
export const GET_MORE_OFFERS_PAGINATION_SUCCESS = 'SmartPush/OffersPage/GET_MORE_OFFERS_PAGINATION_SUCCESS';
export const GET_MORE_OFFERS_PAGINATION_ERROR = 'SmartPush/OffersPage/GET_MORE_OFFERS_PAGINATION_ERROR';
export const GET_OFFERS_MAP = 'SmartPush/OffersPage/GET_OFFERS_MAP'
export const GET_OFFERS_MAP_SUCCESS = "SmartPush/OffersPage/GET_OFFERS_MAP_SUCCESS"

//
// FILTERS
//
export const SET_IS_WEB = 'SmartPush/OffersPage/SET_IS_WEB';
export const SET_OFFER_BRAND = 'SmartPush/OffersPage/SET_OFFER_BRAND';
export const SET_OFFER_CITY = 'SmartPush/OffersPage/SET_OFFER_CITY';
export const SET_CATEGORY = 'SmartPush/OffersPage/SET_CATEGORY';
export const SET_TYPES = 'SmartPush/OffersPage/SET_TYPES';
export const SET_TAGS = 'SmartPush/OffersPage/SET_TAGS';
export const CLEAR_ALL_FILTERS = 'SmartPush/OffersPage/CLEAR_ALL_FILTERS';

//
// TOGGLE DISPLAY MAP
//
export const TOGGLE_DISPLAY_MAP = 'SmartPush/OffersPage/TOGGLE_DISPLAY_MAP';

//
// TOGGLE SELECTED SHOP IN MAP LIST
//
export const TOGGLE_SELECTED_SHOP_DISPLAY_MAP = 'SmartPush/OffersPage/TOGGLE_SELECTED_SHOP_DISPLAY_MAP';

//
// TOGGLE DISPLAY MODAL
//
export const TOGGLE_DISPLAY_OFFER_MODAL = 'SmartPush/OffersPage/TOGGLE_DISPLAY_OFFER_MODAL';
export const TOGGLE_DISPLAY_BRAND_MODAL = 'SmartPush/OffersPage/TOGGLE_DISPLAY_BRAND_MODAL';
export const TOGGLE_DISPLAY_SEARCH_MODAL = 'SmartPush/OffersPage/TOGGLE_DISPLAY_SEARCH_MODAL';


export const GET_CITY = 'SmartPush/OffersPage/GET_CITY';
export const GET_CITY_SUCCESS = 'SmartPush/OffersPage/GET_CITY_SUCCESS';
export const GET_CITY_ERROR = 'SmartPush/OffersPage/GET_CITY_ERROR';
export const RESET_CITY = 'SmartPush/OfersPage/RESET_CITY';


//
//BRAND BY OFFERS
//
export const GET_BRAND = 'SmartPush/BrandPage/GET_BRAND';
export const GET_BRAND_SUCCESS = 'SmartPush/BrandPage/GET_BRAND_SUCCESS';
export const GET_BRAND_ERROR = 'SmartPush/BrandPage/GET_BRAND_ERROR';
export const GET_BRAND_WITH_OFFERS = 'SmartPush/OffersPage/GET_BRAND_WITH_OFFERS';
export const GET_BRAND_WITH_OFFERS_SUCCESS = 'SmartPush/OffersPage/GET_BRAND_WITH_OFFERS_SUCCESS';
export const GET_BRAND_WITH_OFFERS_ERROR = 'SmartPush/OffersPage/GET_BRAND_WITH_OFFERS_ERROR';

//
//ANALYTICS DATA
//
export const SEND_ANALYTICS_OFFER_VIEW = 'SmartPush/OffersPage/SEND_ANALYTICS_OFFER_VIEW'
export const SEND_ANALYTICS_OFFER_VIEW_SUCCESS = 'SmartPush/OffersPage/SEND_ANALYTICS_OFFER_VIEW_SUCCESS'
export const SEND_ANALYTICS_OFFER_VIEW_ERROR = 'SmartPush/OffersPage/SEND_ANALYTICS_OFFER_VIEW_ERROR'

export const SEND_ANALYTICS_OFFER_WALLET = 'SmartPush/OffersPage/SEND_ANALYTICS_OFFER_WALLET'
export const SEND_ANALYTICS_OFFER_WALLET_SUCCESS = 'SmartPush/OffersPage/SEND_ANALYTICS_OFFER_WALLET_SUCCESS'
export const SEND_ANALYTICS_OFFER_WALLET_ERROR = 'SmartPush/OffersPage/SEND_ANALYTICS_OFFER_WALLET_ERROR'

export const SEND_ANALYTICS_OFFER_PRINT = 'SmartPush/OffersPage/SEND_ANALYTICS_OFFER_PRINT'
export const SEND_ANALYTICS_OFFER_PRINT_SUCCESS = 'SmartPush/OffersPage/SEND_ANALYTICS_OFFER_PRINT_SUCCESS'
export const SEND_ANALYTICS_OFFER_PRINT_ERROR = 'SmartPush/OffersPage/SEND_ANALYTICS_OFFER_PRINT_ERROR'
