import React, {useEffect, useState} from 'react';
import * as qs from 'qs';

import './app.css';

import { Routes } from './routes';
import {CircularProgress} from "@material-ui/core";
import {getUserDataWithToken, makeSelectUserData, userLogged} from "@smartpush-front/store";
import {useDispatch, useSelector} from "react-redux";
import {
  initializeKeycloak,
  isKeycloakAuthentication,
  keycloak
} from "../../../../libs/api/src/utils/keycloak";
import {useWidgetStyles} from "./components/WidgetError/styles";
import { UserContext } from 'libs/tracking/src/context/user-context';
import {useInjectSaga} from "@smartpush-front/api";
import userSaga from "./saga/user/userSaga";
import settingsSaga from "../../../phoenix/src/app/components/settings/settingsSaga";
import {callSettings} from "../../../phoenix/src/app/components/settings/actions";
import {createInstance, MatomoProvider} from "@jonkoops/matomo-tracker-react";
import {GTMProvider} from "@elgorditosalsero/react-gtm-hook";
import {makeSelectPartnerSettings} from "../../../phoenix/src/app/pages/LoginPage/selectors";

const withAnalyticsProviders = (partnerSettings) => {
  const gtmParams = {
    id: partnerSettings?.analytics?.idGtm,
    injectScript: partnerSettings?.analytics?.gtm,
  };
  const matomoParams = partnerSettings.analytics?.matomo ? createInstance({
    urlBase: process.env.NX_URL_MATOMO ?? "",
    siteId: partnerSettings?.analytics?.siteIdMatomo ?? 0,
    srcUrl: partnerSettings?.analytics?.tagManagerUrlMatomo ?? "", // Trackers are loaded by MTM on DOM ready
    disabled: false
  }) : null;

  if (matomoParams && gtmParams) {
    return (
      <MatomoProvider value={matomoParams}>
        <GTMProvider state={gtmParams}>
          <Routes/>
        </GTMProvider>
      </MatomoProvider>
    );
  }

  if (matomoParams && !gtmParams) {
    return (
      <MatomoProvider value={matomoParams}>
          <Routes/>
      </MatomoProvider>
    )
  }

  if (!matomoParams && gtmParams) {
    return (<GTMProvider state={gtmParams}>
      <Routes/>
    </GTMProvider>)
  }

  return (<Routes/>)
}

export const App = () => {
  useInjectSaga({key: 'user', saga: userSaga});
  useInjectSaga({key: 'partnerSettings', saga: settingsSaga});

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);
  const [user, setUser] = useState(undefined);
  const [isReady, setIsReady] = useState(false)
  const dispatch = useDispatch();
  const classes = useWidgetStyles();
  const queryParams: any = qs.parse(location.search, {ignoreQueryPrefix: true});
  const accessToken = queryParams.access_token;
  const refreshToken = queryParams.refresh_token;
  const userData = useSelector(makeSelectUserData());
  const partnerSettings = useSelector(makeSelectPartnerSettings());

  useEffect(() => {
    if (isKeycloakAuthentication()) {
      initializeKeycloak(accessToken, refreshToken)
        .then((result) => {
          if (result.authenticated) {
            setIsAuthenticated(true)
          }
        })
    } else {
      setIsAuthenticated(true)
    }
  }, [setIsAuthenticated]);

  useEffect(() => {
      dispatch(userLogged(keycloak.token ?? accessToken, keycloak.refreshToken ?? refreshToken))
  }, [])

  useEffect(() => {
    if (isAuthenticated) {
      dispatch(callSettings());
      dispatch(getUserDataWithToken());
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (userData) {
      setUser(() => userData);
      setIsReady(true);
    }
  }, [userData]);

  function renderContent() {
    if (!isAuthenticated || !isReady || !partnerSettings?.id) {
      return (<div className={classes.centered}><CircularProgress size={24} /></div>)
    }

    return (
        <UserContext.Provider value={{user, setUser}}>
          {withAnalyticsProviders(partnerSettings)}
        </UserContext.Provider>

    )
  }

   return (
    <>
      {renderContent()}
    </>
  );
};

export default App;
