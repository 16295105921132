declare const window: any;

export function sendMessageToNative(message) {
  if (typeof window.ReactNativeWebView === "undefined") {
    console.error("No native interface found.");
    return;
  }
  window.ReactNativeWebView.postMessage(JSON.stringify(message));
}

export function logEvent(name, params) {
  if (!name) {
    return;
  }
  sendMessageToNative({
    command: "logEvent",
    name: name,
    parameters: params,
  });
}

export function setUserProperty(name, value) {
  if (!name || !value) {
    return;
  }
  sendMessageToNative({
    command: "setUserProperty",
    name: name,
    value: value,
  });
}

export function setUserId(id) {
  if (!id) {
    return;
  }
  sendMessageToNative({
    command: "setUserId",
    id: id,
  });
}
