import {SETTINGS} from "@smartpush-front/styles";
import {isKeycloakAuthentication} from "@smartpush-front/api";
import {getPartnerFromHostname} from "../../../styles/src/lib/partners";

let SMARTPUSH_API_EMAIL: string | undefined;
let SMARTPUSH_API_PASSWORD: string | undefined;
if (process.env.NODE_ENV === 'development') {
  SMARTPUSH_API_EMAIL    = process.env.REACT_APP_SMARTPUSH_API_EMAIL;
  SMARTPUSH_API_PASSWORD = process.env.REACT_APP_SMARTPUSH_API_PASSWORD;
}

//
//    URLS
//
let url = process.env.REACT_APP_SMARTPUSH_API_BASE_URL || process.env.NX_SMARTPUSH_API_BASE_URL;
if (SETTINGS.apiUrl) {
  url = SETTINGS.apiUrl
}

if (isKeycloakAuthentication()) {
  url = url.replace('https://','')
  const prefix = SETTINGS.api?.prefix ?? getPartnerFromHostname();
  url = `https://${prefix}-${url}`;
}
const SMARTPUSH_API_BASE_URL = url;
// USER
const SMARTPUSH_API_URL_TOKEN               = SMARTPUSH_API_BASE_URL + '/token';
const SMARTPUSH_API_URL_USER_ME             = SMARTPUSH_API_BASE_URL + '/v1/user/me';
const SMARTPUSH_API_URL_USER_ME_CASHBACK    = SMARTPUSH_API_BASE_URL + '/user/{userId}/cashback';
const SMARTPUSH_API_URL_USER_ORDERS         = SMARTPUSH_API_BASE_URL + '/orders';
const SMARTPUSH_API_URL_USER_ORDER          = SMARTPUSH_API_BASE_URL + '/orders/:orderId';
const SMARTPUSH_API_URL_USER_CARD_TOKENS    = SMARTPUSH_API_BASE_URL + '/v2/cardtokens';
const SMARTPUSH_API_URL_USER_CARD_TOKEN     = SMARTPUSH_API_BASE_URL + '/v2/cardtokens/:cardTokenId';
const SMARTPUSH_API_URL_CARD_TYPES          = SMARTPUSH_API_BASE_URL + '/v2/cardtypes';
const SMARTPUSH_API_URL_USER_REGISTER       = SMARTPUSH_API_BASE_URL + '/v1/user/register';
const SMARTPUSH_API_URL_USER_WALLET         = SMARTPUSH_API_BASE_URL + '/v1/user/wallet';
const SMARTPUSH_API_URL_USER_RESET_PASSWORD = SMARTPUSH_API_BASE_URL + '/v1/user/reset/password';
const SMARTPUSH_API_URL_USER_UPDATE_PASSWORD = SMARTPUSH_API_BASE_URL + '/v1/user/update/password';

// FILTERS
const SMARTPUSH_API_URL_CITY_SEARCH         = SMARTPUSH_API_BASE_URL + '/v1/places/cities/search';

// OFFER
const SMARTPUSH_API_URL_CREATE_ORDER        = SMARTPUSH_API_BASE_URL + '/orders';

//
//  GOOGLE MAP API KEY
//

const GOOGLE_MAP_API_KEY = 'AIzaSyDVBT4lud-MBDl-t__RBCqc0l41e_Uc8pk';

export {
  SMARTPUSH_API_EMAIL,
  SMARTPUSH_API_PASSWORD,
  SMARTPUSH_API_BASE_URL,
  SMARTPUSH_API_URL_TOKEN,
  SMARTPUSH_API_URL_CITY_SEARCH,
  SMARTPUSH_API_URL_USER_ME_CASHBACK,
  SMARTPUSH_API_URL_USER_ORDERS,
  SMARTPUSH_API_URL_USER_ORDER,
  SMARTPUSH_API_URL_USER_CARD_TOKENS,
  SMARTPUSH_API_URL_USER_CARD_TOKEN,
  SMARTPUSH_API_URL_CARD_TYPES,
  SMARTPUSH_API_URL_USER_REGISTER,
  SMARTPUSH_API_URL_USER_WALLET,
  SMARTPUSH_API_URL_USER_RESET_PASSWORD,
  SMARTPUSH_API_URL_USER_UPDATE_PASSWORD,
  SMARTPUSH_API_URL_CREATE_ORDER,
  GOOGLE_MAP_API_KEY,
};
