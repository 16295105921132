export const resetPasswordSchema: any = {
  "title": " Modifier votre mot de passe",
  "description": "Ici vous pouvez modifier votre mot de passe.",
  "type": "object",
  "required": [
    "oldPassword",
    "pass1",
    "pass2",
  ],
  "properties": {
    "oldPassword": {
      "type": "string",
      "format": "password",
      "title": "Mot de passe actuel",
      "default": ""
    },
    "pass1": {
      "type": "string",
      "format": "password",
      "title": "Nouveau mot de passe",
      "description": "Au moins: • 8 caractères • 1 chiffre • 1 caractère spécial • 1 majuscule • 1 minuscule",
      "default": ""
    },
    "pass2": {
      "type": "string",
      "format": "password",
      "title": "Confirmation",
      "default": ""
    }
  }
};
