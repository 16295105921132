/**
 * Offers Page selectors
 */

import { createSelector } from 'reselect';
import {initialState} from "./reducer";

export const selectOffers = (state: any) => state.offers || initialState;

export const makeSelectOffers = () =>
    createSelector(
        selectOffers,
        offerState => offerState.offers,
);
export const makeSelectOffersPagination = () =>
    createSelector(
        selectOffers,
        offerState => offerState.offersPagination,
);
export const makeSelectWebOffersPagination = () =>
    createSelector(
        selectOffers,
        offerState => offerState.webOffersPagination,
);

//
// FILTERS
//
export const makeSelectOffersCategory = () =>
    createSelector(
        selectOffers,
        offerState => offerState.category,
    );
export const makeSelectOffersCity = () =>
    createSelector(
        selectOffers,
        offerState => offerState.offerCity,
    );

export const makeSelectOffersCityName = () =>
    createSelector(
        selectOffers,
        offerState => offerState.cityInfos?.formattedName,
    );

export const makeSelectOffersCityId = () =>
    createSelector(
        selectOffers,
        offerState => offerState.cityInfos?.id,
    );

export const makeSelectOffersIsWeb = () =>
    createSelector(
        selectOffers,
        offerState => offerState.isWeb,
    );

export const makeSelectOffersBrand = () =>
    createSelector(
        selectOffers,
        offerState => offerState.offerBrand,
    );

export const makeSelectOffersMapDisplayed = () =>
    createSelector(
        selectOffers,
        offerState => offerState.mapDisplayed,
    );
//
// END FILTERS
//

export const makeSelectHighlighted = () =>
    createSelector(
        selectOffers,
        offerState => offerState.highlighted,
    );
export const makeSelectRecommended = () =>
    createSelector(
        selectOffers,
        offerState => offerState.recommended,
    );
export const makeSelectOffersGlobalLoading = () =>
    createSelector(
        selectOffers,
        offerState => offerState.loading,
    );
export const makeSelectOffersLoading = () =>
  createSelector(
    selectOffers,
    offerState => offerState.offersLoading,
  );
export const makeSelectMoreOffersLoading = () =>
    createSelector(
        selectOffers,
        offerState => offerState.moreOfferLoading,
    );
export const makeSelectOffersError = () =>
    createSelector(
        selectOffers,
        offerState => offerState.error,
);
export const makeSelectOfferByIdAndComponentName = (offerId: string, componentName: string) => {
    return createSelector(
        selectOffers,
        offerState => {

            return resolveArray(offerId, offerState[componentName]);
        });
};

export const makeSelectCartOffers = () =>
  createSelector(
    selectOffers, offerState => offerState.cartOffers
  );

export const makeSelectErrorOnGenerateCoupon = () =>
  createSelector(
    selectOffers, offerState => offerState.couponHasError
  );

function resolveArray(path: string, arr: [any]) {
    return arr.find((value: any, index: number) => {
        return value.id === path;
    });
    //return path.split('.').reduce(function(prev, curr) {
    //    return prev ? prev[curr] : null
    //}, obj || window.self)
}
