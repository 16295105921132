import React from "react";

import Table from "@material-ui/core/Table";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";

import Link from "@material-ui/core/Link";

import moment from "moment";

import {Grid, makeStyles, Theme, useMediaQuery} from "@material-ui/core";

import {OrderStatus} from './OrderStatus';

import { injectParameter } from "@smartpush-front/payment";
import { history } from '@smartpush-front/store';

import { buildUrl } from '@smartpush-front/api';
import {SETTINGS} from "@smartpush-front/styles";

const useStylesOrdersHistory = makeStyles((theme: Theme) => ({
  table: {
    fontSize: '14px',
    marginTop: 16
  },
  minHeightRow: {
    height: '47px'
  },
  cardItem: {
    padding: '4px',
  },
  cardPictureDiv: {
    height: '64px',
    width: '64px',
    display: "flex",
    flex: 1,
    alignItems: "center",
    boxShadow: '0 2px 4px 0 rgba(152,152,152,0.62)',
    borderRadius: 5,
  },
  cardPicture: {
    borderRadius: 5,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    maxHeight: '64px',
    maxWidth: '64px',
  },
  link: {
    cursor: 'pointer',
    fontSize: 14
  }
}));

export function OrdersHistoryDesktop({ordersData, detailRoute}) {
  const classes = useStylesOrdersHistory();
  const isMobile = useMediaQuery('(max-width:767px)');

  const getDate = (date: any)  => {
    if (SETTINGS?.dateFormat?.orderHistory){
      return moment(date).format(SETTINGS?.dateFormat?.orderHistory)
    }

    return  moment(date).format('lll')
  }

  return (
    <Table className={"orderHistory-table "+classes.table} size="small" padding="none">
      <TableBody>
      {ordersData.map((row: any, index: number) => (
          <TableRow key={index} className={classes.minHeightRow}>
            <TableCell component="th" scope="row" className="cardPictureCell">
              <Grid item className={classes.cardItem}>
                <div className={"cardPictureDiv "+classes.cardPictureDiv}>
                  <img
                    alt='card'
                    src={ buildUrl(row.firstOrderLineFullImage) }
                    className={"cardPicture "+classes.cardPicture}/>
                </div>
              </Grid>
            </TableCell>
            <TableCell component="th" scope="row">
              {getDate(row.createdAt.date)}
            </TableCell>
            <TableCell>
              {row.readableId}
            </TableCell>
            <TableCell>
              <OrderStatus status={row.status} isMobile={isMobile}/>
            </TableCell>
            <TableCell className="priceCell">
              {row.amount.toFixed(2)}€
            </TableCell>
            <TableCell>
              <Link className={classes.link} onClick={() => history.push(injectParameter(detailRoute, row.id))} underline="always">
                Voir les détails
              </Link>
            </TableCell>
          </TableRow>
      ))}
      </TableBody>
    </Table>
  );
}
