import React, { useEffect, useState }  from 'react';
import * as qs from 'qs';
import { exceptParameters } from '../../utils/expectParameters';
import { useDispatch, useSelector } from 'react-redux';
import {
  userLogged,
  getUserDataWithToken,
  makeSelectUserData,
  makeSelectPartnerPrimaryColor,
  makeSelectUserDataLoading,
  makeSelectRefreshToken, getToken
} from '@smartpush-front/store';

import { OrdersHistory } from '@smartpush-front/orders';
import {useInjectSaga} from "@smartpush-front/api";
import userSaga from '../../saga/user/userSaga';
import { MuiThemeProvider, Container, Grid, makeStyles, Theme } from '@material-ui/core';
import { theme, COLORS } from '@smartpush-front/styles';
import { hasError, WidgetError } from '../../components/WidgetError/WidgetError';

const useStylesOrdersPage = makeStyles((theme: Theme) => ({
  card: {
    margin: '16px 0',
    maxWidth: 745,
    borderRadius: 5,
    backgroundColor: COLORS.white,
    padding: theme.spacing(2, 0, 2, 0),
    paddingBottom: 32,
    boxShadow: '0 2px 4px 0 rgba(152,152,152,0.62)',
  }
}));

export function OrdersPage({location}) {
  const dispatch = useDispatch();
  useInjectSaga({key: 'user', saga: userSaga});
  const classes = useStylesOrdersPage();

  const queryParams: any = qs.parse(location.search, { ignoreQueryPrefix: true });

  const refreshToken = useSelector(makeSelectRefreshToken())
  const token = useSelector(getToken())

  const [unauthorizedError, setUnauthorizedError] = useState(true);

  useEffect(() => {
      dispatch(userLogged(token, refreshToken ?? ''));
      dispatch(getUserDataWithToken());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // eslint-disable-next-line react-hooks/exhaustive-deps
    JSON.stringify(queryParams)
  ]);

  const userData = useSelector(makeSelectUserData());
  const loadingUserData = useSelector(makeSelectUserDataLoading());
  const primaryColor = useSelector(makeSelectPartnerPrimaryColor());

  useEffect(() => {
    if ( !userData?.email) {
      setUnauthorizedError(true);
    } else {
      setUnauthorizedError(false);
    }
  }, [userData]);

  if (hasError([], loadingUserData, unauthorizedError)) {
    return (
      <WidgetError
        missingParameters={[]}
        isLoading={loadingUserData}
        unauthorizedError={unauthorizedError}
        invalidOfferError={false} />
    );
  }

  const themeWithPrimary = {...theme};
  themeWithPrimary.palette.primary.main = primaryColor || themeWithPrimary.palette.primary.main;
  return (
    <MuiThemeProvider theme={themeWithPrimary}>

      <Container maxWidth="md" style={{display: 'flex', justifyContent: 'center'}}>
        <Grid container className={classes.card}>
          <OrdersHistory
            detailRoute={`/orders/:orderId?access_token=${token}&refresh_token=${refreshToken}`}
          />
        </Grid>
      </Container>
    </MuiThemeProvider>
  );
}
