import React from 'react';
import {Grid, Typography} from "@material-ui/core";
import {useStylesPaymentModal} from "../styles";
import clsx from "clsx";
import {SETTINGS} from "@smartpush-front/styles";

interface OrderContentBillingProps {
  userName?: string|null;
  address?: string|null;
  zipCode?: string|null;
  city?: string|null;
}

export const OrderContentBilling = ({userName, address, zipCode, city}: OrderContentBillingProps) => {
  const classes = useStylesPaymentModal();

  const getSpecificCss = () => {
    return clsx(classes.sectionTitle, classes.orderContent)
  }

  const isAddressProvided = (): boolean => {
    return (userName && userName.length > 0) || (address && address.length > 0) || (zipCode && zipCode.length > 0) || (city && city.length > 0);
  }

  return (
    <Grid container alignItems="flex-start" className={classes.sectionPaddingOrder}>
      <Grid item xs={12}>
        <Typography className={getSpecificCss()}>
          Mon adresse de facturation
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {!isAddressProvided() && (
          <Typography className={clsx(classes.sectionTitle, classes.orderContent)}>
            {SETTINGS.familiarity ? 'Renseigne ton' : 'Renseignez votre'} adresse de facturation pour pouvoir payer en ligne.
          </Typography>
        )}
        {isAddressProvided() && (
          <Grid container direction="column" justify="flex-start">
            {userName && (<Typography className={classes.sectionText}>{userName}</Typography>)}
            {address && (<Typography className={classes.sectionText}>{address}</Typography>)}
            {(zipCode || city) && (<Typography className={classes.sectionText}>{zipCode} {city}</Typography>)}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}
