import React, {useEffect} from "react";

import { Grid } from "@material-ui/core";
import {useStylesPaymentModal} from "../styles";

import Typography from "@material-ui/core/Typography";

import {Alert} from "@material-ui/lab";
import InfoIcon from "@material-ui/icons/Info";
import AlertTitle from "@material-ui/lab/AlertTitle";
import {useSelector} from "react-redux";
import {makeSelectCardOrderDataErrorOrderId, makeSelectShoppingCartTotalPrice} from "@smartpush-front/payment";
import {SETTINGS} from "@smartpush-front/styles";
import { makeSelectCartOffers } from '@smartpush-front/offers';
import {useTracking} from "@smartpush-front/tracking";

export function OrderDeclinedMessage(props) {
  const classes = useStylesPaymentModal();

  const userAgent = navigator.userAgent;
  const isWebview = require('is-ua-webview')
  const isApp = isWebview(userAgent)
  const failedOrderId = useSelector(makeSelectCardOrderDataErrorOrderId())
  const offers = useSelector(makeSelectCartOffers());
  const total = useSelector(makeSelectShoppingCartTotalPrice());
  const tracking = useTracking()
  const arrayCategories: any[] = []

  offers[0]?.offerCategories?.forEach((category: any) => {
    arrayCategories.push(category.name)
  })

  let data;

  if (total && total != 0) {
     data = {
       event: "purchase_failed",
       currency: "EUR",
       item_id: offers[0].id,
       value: total,
       discount_type: offers[0].discountType,
       discount_amount: offers[0].discountAmount,
       item_brand: offers[0].brand?.name,
       item_name: offers[0].offer,
       item_category: arrayCategories[0],
       item_variant: offers[0].offerTypeEnhanced,
       quantity: offers.length,
       transaction_id: String(failedOrderId),
       item_provider: offers[0]?.provider?.name,
       item_type: offers[0]?.offerType
    }
  } else {
     data = { event: "purchase_failed",
       transaction_id: String(failedOrderId)
     }
  }

  if (!isApp) {
    useEffect( () => {
        tracking.triggerTrackingEvent({
          category: "ecommerce",
          data: data
        })
    }, [])
  }


  return (
    <Grid item xs={12}>
        <Alert
            icon={<InfoIcon/>}
            variant="filled"
            severity="warning"
            className={classes.alert}>
            <AlertTitle>Paiement refusé</AlertTitle>
            <Typography>
              {SETTINGS.familiarity ? "Ton" : "Votre"} paiement a été refusé. Merci de réessayer avec une autre carte.
            </Typography>
        </Alert>
    </Grid>
  );
}
