
/**
 * Offer Shop List CONSTANTS
 *
 * @type {string}
 */
/*
 * CART
 */
export const ADD_ITEM_QUANTITY_TO_CART      = 'SmartPush/Order/ADD_ITEM_QUANTITY_TO_CART';
export const REMOVE_ITEM_QUANTITY_FROM_CART = 'SmartPush/Order/REMOVE_ITEM_QUANTITY_FROM_CART'
export const CLEAR_CART                     = 'SmartPush/Order/CLEAR_CART'

/*
 * PAYMENT CARD
 */
export const UPDATE_CARD_FIELDS      = 'SmartPush/Order/UPDATE_CARD_FIELDS';
export const UPDATE_SELECTED_CARD    = 'SmartPush/Order/UPDATE_SELECTED_CARD';
export const SAVE_CARD_DATA          = 'SmartPush/Order/SAVE_CARD_DATA';
export const SAVE_CARD_DATA_SUCCESS  = 'SmartPush/Order/SAVE_CARD_DATA_SUCCESS';
export const SAVE_CARD_DATA_ERROR    = 'SmartPush/Order/SAVE_CARD_DATA_ERROR';

export const GET_CARD_TOKENS_DATA          = 'SmartPush/Order/GET_CARD_TOKENS_DATA';
export const GET_CARD_TOKENS_DATA_SUCCESS  = 'SmartPush/Order/GET_CARD_TOKENS_DATA_SUCCESS';
export const GET_CARD_TOKENS_DATA_ERROR    = 'SmartPush/Order/GET_CARD_TOKENS_DATA_ERROR';

export const SAVE_CARD_TOKEN_DATA          = 'SmartPush/Order/SAVE_CARD_TOKEN_DATA';
export const SAVE_CARD_TOKEN_DATA_SUCCESS  = 'SmartPush/Order/SAVE_CARD_TOKEN_DATA_SUCCESS';
export const SAVE_CARD_TOKEN_DATA_ERROR    = 'SmartPush/Order/SAVE_CARD_TOKEN_DATA_ERROR';

export const CLEAR_CARD_TOKEN_DATA          = 'SmartPush/Order/CLEAR_CARD_TOKEN_DATA';
export const CLEAR_CARD_TOKEN_DATA_SUCCESS  = 'SmartPush/Order/CLEAR_CARD_TOKEN_DATA_SUCCESS';
export const CLEAR_CARD_TOKEN_DATA_ERROR    = 'SmartPush/Order/CLEAR_CARD_TOKEN_DATA_ERROR';

export const GET_CARD_TYPES_DATA          = 'SmartPush/Order/GET_CARD_TYPES_DATA';
export const GET_CARD_TYPES_DATA_SUCCESS  = 'SmartPush/Order/GET_CARD_TYPES_DATA_SUCCESS';
export const GET_CARD_TYPES_DATA_ERROR    = 'SmartPush/Order/GET_CARD_TYPES_DATA_ERROR';


/*
 * SEND DATA TO API
 */
export const POST_ORDER_DATA         = 'SmartPush/Order/POST_ORDER_DATA';
export const POST_ORDER_DATA_SUCCESS = 'SmartPush/Order/POST_ORDER_DATA_SUCCESS';
export const POST_ORDER_DATA_ERROR   = 'SmartPush/Order/POST_ORDER_DATA_ERROR';

/*
 * GET ORDER DATA FROM API FOR 3DS PROCESS
 */
export const GET_ORDER_DATA_AFTER_REDIRECT         = 'SmartPush/Order/GET_ORDER_DATA_AFTER_REDIRECT';
export const GET_ORDER_DATA_AFTER_REDIRECT_SUCCESS = 'SmartPush/Order/GET_ORDER_DATA_AFTER_REDIRECT_SUCCESS';
export const GET_ORDER_DATA_AFTER_REDIRECT_ERROR   = 'SmartPush/Order/GET_ORDER_DATA_AFTER_REDIRECT_ERROR';


/*
 * CLEAR ALL REDUCER STATE
 */

export const CLEAR_PAYMENT_PAGE      = 'SmartPush/Order/CLEAR_PAYMENT_PAGE';

export const SET_USER_TOKEN = 'SmartPush/User/SET_USER_TOKEN';

export const GET_USER_DATA_WITH_TOKEN = 'SmartPush/User/GET_USER_DATA_WITH_TOKEN';
export const GET_USER_DATA_WITH_TOKEN_SUCCESS = 'SmartPush/User/GET_USER_DATA_WITH_TOKEN_SUCCESS';
export const GET_USER_DATA_WITH_TOKEN_ERROR = 'SmartPush/User/GET_USER_DATA_WITH_TOKEN_ERROR';
export const SNACKBAR_SYSTEM_OPEN = 'SmartPush/User/SNACKBAR_SYSTEM_OPEN';
export const SNACKBAR_SYSTEM_CLOSE = 'SmartPush/User/SNACKBAR_SYSTEM_CLOSE';

export const GET_ORDERS           = 'SmartPush/User/GET_ORDERS';
export const GET_ORDERS_SUCCESS   = 'SmartPush/User/GET_ORDERS_SUCCESS';
export const GET_ORDERS_ERROR     = 'SmartPush/User/GET_ORDERS_ERROR';

export const GET_ORDER           = 'SmartPush/User/GET_ORDER';
export const GET_ORDER_SUCCESS   = 'SmartPush/User/GET_ORDER_SUCCESS';
export const GET_ORDER_ERROR     = 'SmartPush/User/GET_ORDER_ERROR';

export const GET_CARD_FEES = 'SmartPush/Order/GET_CARD_FEES';
export const SAVE_CARD_FEES = 'SmartPush/Order/SAVE_CARD_FEES';
export const SAVE_CARD_FEES_ERROR = 'SmartPush/Order/SAVE_CARD_FEES_ERROR';
