/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */
import produce from 'immer';
import {
    GET_USER_DATA_WITH_TOKEN, GET_USER_DATA_WITH_TOKEN_ERROR, GET_USER_DATA_WITH_TOKEN_SUCCESS,
    SNACKBAR_SYSTEM_CLOSE,
    SNACKBAR_SYSTEM_OPEN,
    GET_ORDERS, GET_ORDERS_ERROR, GET_ORDERS_SUCCESS,
    GET_ORDER, GET_ORDER_ERROR, GET_ORDER_SUCCESS
} from '../constants/constants';
import {get}       from 'local-storage';

export const LS_OAUTH_TOKENS            = 'SmartpushOauthTokens';
export const LS_OAUTH_ACCESS_TOKEN_KEY  = 'AccessToken';
export const LS_OAUTH_REFRESH_TOKEN_KEY = 'RefreshToken';

export interface Tokens {
    [LS_OAUTH_ACCESS_TOKEN_KEY]: string
    [LS_OAUTH_REFRESH_TOKEN_KEY]: string
}


// The initial state of the App
export const initialState: InitialGlobalStateInterface = {
    loading: false,
    userRegisterLoading: false,
    auth: {
        error: undefined,
        snackbar: {
            open: false,
            type: null,
            content: null
        },
        userData: {
            accessToken: '',
            refreshToken: '',
            cashback: {},
            orders: [],
            order: {},
            loadingProfile: false,
            loadingOrders: false,
            loadingCashback: false,
            profile: {
                reload: false,
                loading: false,
                error: undefined,
                data: {}
            }
        }
    },
    navigation: {
      isCGUOpen: false
    }
};

export interface UserDataProfileStateInterface {
    coordinates?: any
}

export interface InitialGlobalStateInterface {
    loading: boolean,
    userRegisterLoading: boolean,
    auth: {
        error?: Error,
        snackbar: {
            open: boolean,
            type: any,
            content: any
        },
        userData: {
            accessToken: string,
            refreshToken: string,
            cashback: any,
            orders: any,
            order: any,
            loadingProfile: boolean,
            loadingOrders: boolean,
            loadingCashback: boolean,
            profile: {
                reload?: boolean,
                loading: boolean,
                error?: Error,
                data: UserDataProfileStateInterface
            }
        }
    },
    navigation: {
        isCGUOpen: boolean
    }
}

const hydrateUserState = () => {
    try{
        const savedTokens: Tokens = get(LS_OAUTH_TOKENS);
        let accessToken = '';
        let refreshToken = '';
        if (savedTokens) {
            accessToken  = savedTokens[LS_OAUTH_ACCESS_TOKEN_KEY];
            refreshToken = savedTokens[LS_OAUTH_REFRESH_TOKEN_KEY];
        }
        if(accessToken === '' && refreshToken === ''){
            return initialState;
        }
        return {
            loading: false,
            userRegisterLoading: false,
            auth: {
                error: undefined,
                snackbar: {
                    open: false,
                    type: null,
                    content: null
                },
                userData: {
                    accessToken: accessToken,
                    refreshToken: refreshToken,
                    cashback: {},
                    orders: [],
                    order: {},
                    loadingProfile: false,
                    loadingOrders: false,
                    loadingCashback: false,
                    profile: {
                        loading: false,
                        error: undefined,
                        data: {}
                    }
                }
            },
            navigation: {
                isCGUOpen: false
            }
        };
    } catch (error) {
        return initialState;
    }
};


/* eslint-disable default-case, no-param-reassign */
const appReducer = (state: InitialGlobalStateInterface = hydrateUserState(), action: any) =>
    produce(state, draft => {
        switch (action.type) {
            case GET_USER_DATA_WITH_TOKEN:
                draft.loading = true;
                draft.auth.userData.profile.loading = true;
                break;
            case GET_USER_DATA_WITH_TOKEN_SUCCESS:
                draft.loading = false;
                draft.auth.userData.profile.loading = false;
                draft.auth.userData.profile.data = action.userData;
                break;
            case GET_USER_DATA_WITH_TOKEN_ERROR:
                draft.loading = false;
                draft.auth.userData.profile.error = action.error;
                break;
            case SNACKBAR_SYSTEM_OPEN:
                draft.auth.snackbar.open = true;
                draft.auth.snackbar.type = action.typeOfAlert;
                draft.auth.snackbar.content = action.content;
                break;
            case SNACKBAR_SYSTEM_CLOSE:
                draft.auth.snackbar.open = false;
                draft.auth.snackbar.type = action.typeOfAlert;
                draft.auth.snackbar.content = action.content;
                break;
            case GET_ORDERS:
                draft.auth.userData.loadingOrders = true;
                break;
            case GET_ORDERS_SUCCESS:
                draft.auth.userData.loadingOrders = false;
                draft.auth.userData.orders = action.ordersData;
                break;
            case GET_ORDERS_ERROR:
                draft.auth.userData.loadingOrders = false;
                draft.auth.userData.orders = {};
                break;
            case GET_ORDER:
                draft.auth.userData.loadingOrders = true;
                break;
            case GET_ORDER_SUCCESS:
                draft.auth.userData.loadingOrders = false;
                draft.auth.userData.order = action.orderData;
                break;
            case GET_ORDER_ERROR:
                draft.auth.userData.loadingOrders = false;
                draft.auth.userData.order = {};
                break;

        }
    });

export default appReducer;
