/*
 * UpdatePassword reducer
 */
import produce from 'immer';
import {
  CLEAR_UPDATE_PASSWORD,
  SUBMIT_UPDATE_PASSWORD, SUBMIT_UPDATE_PASSWORD_ERROR, SUBMIT_UPDATE_PASSWORD_SUCCESS,
} from './constants';
import { APIError } from '@smartpush-front/api';

// The initial state of the UpdatePassword reducer
export const initialState = {
    username: '',
    loading: false,
    error: undefined,
    success: false
};

interface InitialStateProps {
    username: string,
    loading: boolean,
    error?: APIError,
    success: boolean
}

/* eslint-disable default-case, no-param-reassign */
const updatePasswordReducer = (state: InitialStateProps = initialState, action: any) =>
    produce(state, draft => {
        switch (action.type) {
            case SUBMIT_UPDATE_PASSWORD:
                draft.loading = true;
                draft.username = action.username;
                break;
            case SUBMIT_UPDATE_PASSWORD_SUCCESS:
                draft.loading = false;
                draft.success = true;
                break;
            case SUBMIT_UPDATE_PASSWORD_ERROR:
                draft.loading = false;
                draft.error = action.error;
                break;
            case CLEAR_UPDATE_PASSWORD:
                return initialState;
        }
    });

export default updatePasswordReducer;
