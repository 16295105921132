/*
 * Update Password Actions
 */

import {
  CLEAR_UPDATE_PASSWORD,
  SUBMIT_UPDATE_PASSWORD, SUBMIT_UPDATE_PASSWORD_ERROR, SUBMIT_UPDATE_PASSWORD_SUCCESS
} from './constants';

/**
 * Submit email for updateten password, this action starts the request saga
 *
 * @return {object} An action object with a type of SUBMIT_UPDATE_PASSWORD
 */
export function submitUpdatePassword(oldPassword: string, newPassword: string) {
  return {
    type: SUBMIT_UPDATE_PASSWORD,
    oldPassword,
    newPassword
  };
}

/**
 * Dispatched when the email is correctly send by the request saga
 *
 * @return {object}      An action object with a type of SUBMIT_UPDATE_PASSWORD_SUCCESS passing the repos
 */
export function submitUpdatePasswordSuccess() {
  return {
    type: SUBMIT_UPDATE_PASSWORD_SUCCESS,
  };
}

/**
 * Dispatched when sending request password failed
 *
 * @param  {object} error The error
 *
 * @return {object}       An action object with a type of SUBMIT_UPDATE_PASSWORD_ERROR passing the error
 */
export function submitUpdatePasswordError(error: any) {
  return {
    type: SUBMIT_UPDATE_PASSWORD_ERROR,
    error,
  };
}

/**
 * Clear update password
 *
 * @return {object} An action object with a type of CLEAR_UPDATE_PASSWORD
 */
export function clearUpdatePassword() {
  return {
    type: CLEAR_UPDATE_PASSWORD
  };
}
