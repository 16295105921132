import React, {useEffect} from "react";
import {useInjectSaga, buildUrl}       from "@smartpush-front/api";
import saga                  from '../../store/saga';
import {useDispatch, useSelector} from "react-redux";
import {makeSelectUserOrder, getUserOrder} from "@smartpush-front/store";
import { useMediaQuery } from '@material-ui/core';

import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Container from "@material-ui/core/Container";
import clsx from "clsx";
import moment from "moment";
import isEmpty from "lodash/isEmpty";
import Link from "@material-ui/core/Link";
import { history, makeSelectUserData } from '@smartpush-front/store';
import {useStylesUserOrder} from "./styles";
import {ProductFields, OrderSummary, Offer, currencyFormatFR} from "@smartpush-front/payment";
import { OrderStatus } from '../OrdersHistory/components/OrderStatus';

import { Card }     from '@smartpush-front/payment';
import { SETTINGS } from '@smartpush-front/styles';
import "./styles.scss";
import {makeSelectPartnerSettings} from "../../../../../apps/phoenix/src/app/pages/LoginPage/selectors";
import {DISPLAY_SAVINGS, HIDE_FOOTER, PartnerSettingsService} from "../../../../api/src/utils/PartnerSettingsService";

const key = 'userOrder';

export function OrderDetails({id, backRoute = '/user/account'}) {
  const dispatch = useDispatch();
  useInjectSaga({key, saga});

  const userData = useSelector(makeSelectUserData());
  const partnerSettings = useSelector(makeSelectPartnerSettings());

  useEffect(() => {
    if (id && userData?.email) {
      dispatch(getUserOrder(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, userData]);

  const isMobile = useMediaQuery('(max-width:767px)');
  const order = useSelector(makeSelectUserOrder());

  const card: Card = Object.assign({}, order.transactions?.[0]?.card, {
    expiryDate: order.transactions?.[0]?.card?.expirationDate,
    isLoading: false
  });

  const classes = useStylesUserOrder({isMobile});

  const getOfferCodes = (redemptions) => {
    return  redemptions.map((redemption) => {
      return redemption.offerCode;
    });
  }

  const items: Offer[] = order?.orderLines?.length > 0 && order.orderLines.map(orderLine => ({
      offer: orderLine.variantName || orderLine.summary,
      description: orderLine.description,
      quantity: orderLine.quantity,
      price: orderLine.price,
      img: buildUrl(orderLine.fullImage),
      offerCodes: getOfferCodes(orderLine.redemptions),
      parentId: orderLine.variantName ? orderLine.offerId : null,
      parentOffer: orderLine.variantName ? orderLine.summary : null,
      redemptions: orderLine.redemptions,
      isFreeValue: orderLine.isFreeValue,
      faceValue: orderLine.faceValue
    })
  );

  const titleOrder = () => {
    if (isMobile) {
      return `Ma commande n°${order.readableId}`;
    }

    return SETTINGS?.familiarity ? 'Ta commande' : 'Votre commande'
  }

  const getSavings = () => {
    let savings = 0;

    items.forEach(item => {
      if (item.isFreeValue) {
        savings += item.faceValue - item.price
      } else {
        savings += (item.faceValue - item.price) * item.quantity;
      }
    })

    return savings;
  }

  return (
    <Container maxWidth="md" className="orderDetails">
      { !isEmpty(order) && (
        <Grid container justify="center">
          {!isMobile && (
            <Grid container className={classes.breadCrumbsMobile}>
              <Link className="back" color="initial" onClick={() => history.push(backRoute)}>
                <Typography className={classes.breadCrumbsMain}>
                  MON COMPTE
                </Typography>
              </Link>
              <Typography className={clsx(classes.breadCrumbsMain, classes.breadCrumbsSeparator)}>
                {" / "}
              </Typography>
              <Typography className="orderNumber">
                Commande n°{order.readableId}
              </Typography>
            </Grid>
          )}
          <Grid container className={"card "+classes.card}>
            <Grid container direction="column" justify="flex-start">

              {isMobile && (
                <Grid  container justify="center">
                  <OrderStatus verbose={true} status={order.status} isMobile={isMobile}/>
                </Grid>
              )}
              <Typography variant="h6" className={clsx(classes.title, classes.topPadding, classes.horizontalPadding, classes.black)} style={{paddingLeft: "32px", paddingRight: "32px"}}>
                { titleOrder() }
              </Typography>
              {!isMobile && (
                <Typography variant="body1" className={clsx(classes.summary, classes.horizontalPadding)}>
                  {SETTINGS?.familiarity ? 'Retrouves' : 'Retrouvez'} ci-dessous les détails de {SETTINGS?.familiarity ? 'ta' : 'votre'} commande {order.readableId}.
                </Typography>
              )}

              <Divider className={classes.divider}/>
              {!isMobile && (
                <>
                  <Typography variant="h6" className={clsx(classes.title, classes.titleSub, classes.horizontalPadding, classes.completedColor)}>
                    Détails de la commande
                  </Typography>
                  <Grid container className={clsx(classes.horizontalPadding, classes.smBottomPadding)} alignItems="center">
                      <Typography className={classes.subtitle}>
                        ÉTAT
                      </Typography>
                      <div className={classes.subtitleValue}>
                        <OrderStatus status={order.status} isMobile={isMobile}/>
                      </div>
                  </Grid>
                  <Grid container className={classes.horizontalPadding}>
                    <Typography className={classes.subtitle}>
                      DATE
                    </Typography>
                    <Typography className={classes.subtitleValue}>
                      {moment(order.createdAt.date).format("DD/MM/YYYY")}
                    </Typography>
                    <Typography className={classes.subtitle}>
                      N° DE COMMANDE
                    </Typography>
                    <Typography className={classes.subtitleValue}>
                      {order.readableId}
                    </Typography>
                    <div>
                      <div style={{display: 'flex'}}>
                        <Typography className={classes.subtitle}>
                          TOTAL
                        </Typography>
                        <Typography className={classes.subtitleValue}>
                          {currencyFormatFR(order.amount)}€
                        </Typography>
                      </div>
                      {order.fees > 0 && (
                        <Typography className={classes.subtitle}>
                          dont frais de gestion : {currencyFormatFR(order.fees)}€
                        </Typography>
                      )}
                    </div>
                  </Grid>
                  {PartnerSettingsService.isFeatureEnable(DISPLAY_SAVINGS) &&
                  <Grid container className={clsx(classes.horizontalPadding, classes.smTopPadding)} alignItems="center">
                    <Typography className={classes.subtitle}>
                      MONTANT DU CASHBACK
                    </Typography>
                    <Typography className={classes.subtitleValue}>
                      {getSavings().toFixed(2)}€
                    </Typography>
                  </Grid>
                  }
                  <Divider className={classes.divider}/>
                  <Grid container>
                    <Grid item xs={6}>
                      <Typography variant="h6" className={clsx(classes.title, classes.titleSub, classes.horizontalPadding, classes.completedColor)}>
                        Informations sur le paiement
                      </Typography>
                      <Grid container direction="row" className={clsx(classes.horizontalPadding, classes.bottomPadding)}>
                        <Grid item xs={12}>
                          <Grid container className={classes.smBottomPadding}>
                            <Grid item xs={4}>
                              <Typography className={classes.subtitle}>
                                MOYEN DE PAIEMENT
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Typography className={classes.subtitleValue}>
                                {card?.brand?.toUpperCase()}
                                <span style={{display: 'block'}}>{card?.pan?.substr(10)} xx/{card?.expirationDate?.substr(2)}</span>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <Grid container>
                            <Grid item xs={4}>
                              <Typography className={classes.subtitle}>
                                ADRESSE DE FACTURATION
                              </Typography>
                            </Grid>
                            <Grid item xs={8}>
                              <Typography className={classes.subtitleValue}>
                                {order?.address}
                                <span style={{display: 'block'}}>{order?.zipCode} {order?.city}</span>
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography variant="h6" className={clsx(classes.title, classes.titleSub, classes.horizontalPadding, classes.completedColor)}>
                        Informations sur la livraison
                      </Typography>
                      <Grid container direction="row" className={classes.horizontalPadding}>
                        <Grid item xs={12}>
                          <Typography className={clsx(classes.subtitleValue, classes.noPaddingRight, classes.bottomPadding)}>
                            Commande envoyée à l’adresse email ci-dessous :
                          </Typography>
                          <Grid container>
                            <Grid item xs={2}>
                              <Typography className={classes.subtitle}>
                                EMAIL
                              </Typography>
                            </Grid>
                            <Grid item xs={10}>
                              <Typography className={clsx(classes.subtitleValue, classes.noPaddingRight)}>
                                {order.email}
                              </Typography>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider className={classes.divider}/>
                </>
              )}
              {isMobile && (
                <div>
                  <OrderSummary
                    total={order.amount}
                    items={items}
                    card={card}
                    deliveryEmail={order.email}
                    pendingOrSuccess={true}
                    savings={getSavings()}
                    address={order.address}
                    zipCode={order.zipCode}
                    city={order.city}
                    fees={order.fees}
                    totalWithoutFees={order.amount - order.fees}
                  />
                </div>
              )}

              {!isMobile &&
                <Typography variant="h6" className={clsx(classes.title, classes.titleSub, classes.horizontalPadding, classes.black)}>
                  Contenu de {SETTINGS.familiarity ? "ta" : "votre"} commande
                </Typography>
              }
              {isMobile &&
                <Typography style={{textAlign: "center"}} variant="h6" className={clsx(classes.title, classes.titleSub, classes.horizontalPadding, classes.black)}>
                 Il  ne {SETTINGS.familiarity ? "te" : "vous"}  reste plus qu'à récupérer {SETTINGS.familiarity ? "ta" : "votre"} commande pour en  profiter !
                </Typography>
              }
              {!isMobile &&
              <Grid container className={classes.horizontalPadding} alignItems="center">
                <ProductFields isMobile={isMobile} items={items} readOnly={true} orderStatus={order.status} hasOfferCodes={true} />
              </Grid>
              }
              {isMobile &&
                <Grid container className={classes.horizontalPadding} alignItems="center">
                  <ProductFields onlyButton={true} isMobile={isMobile} items={items} readOnly={true} orderStatus={order.status} hasOfferCodes={true} />
                </Grid>
              }

              <Divider className={classes.divider}/>
              {!isMobile &&
              <>
              <Typography variant="h6" className={clsx(classes.title, classes.titleSub, classes.horizontalPadding, classes.xsBottomPadding, classes.black)}>
                Besoin d'aide suite à {SETTINGS.familiarity ? "ta" : "votre"} commande ?
              </Typography>
              <Typography variant="h6" className={clsx(classes.subtitleValue, classes.titleSub, classes.horizontalPadding, classes.black)} style={{fontFamily: 'ProximaNova'}}>
                <Link className={classes.subtitleValue} underline="always" href={SETTINGS?.links?.contact} target='_blank'>{SETTINGS.familiarity ? "Contacte" : "Contactez"}-nous</Link>
              </Typography>
              </>
              }
              {isMobile &&
                <>
                  <Typography variant="h6" style={{textAlign: "center"}}  className={clsx(classes.title, classes.titleSub, classes.horizontalPadding, classes.xsBottomPadding, classes.black)}>
                    Besoin d'aide suite à {SETTINGS.familiarity ? "ta" : "votre"} commande ?
                  </Typography>
                  <Typography variant="h6" className={clsx(classes.subtitleValue, classes.titleSub, classes.horizontalPadding, classes.black)} style={{fontFamily: 'ProximaNova', textAlign: "center"}}>
                    <Link className={classes.subtitleValue} underline="always"  style={{paddingRight: "0px"}} href={SETTINGS?.links?.contact} target='_blank'>{SETTINGS.familiarity ? "Contacte" : "Contactez"}-nous</Link>
                  </Typography>
                </>
              }
            </Grid>
          </Grid>
        </Grid>
      )}
    </Container>
  );
}
