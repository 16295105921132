import React from 'react';
import { Typography } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import SvgIcon from '@material-ui/core/SvgIcon';
import { useStylesProductFields } from './styles';
import { useDispatch } from 'react-redux';
import {
  addItemQuantityToCart,
  removeItemQuantityFromCart,
} from '../../../store/actions/actions';
import { Price, PriceSize } from '../../../Price/Price';

export function ProductQuantity(props) {
  const dispatch = useDispatch();
  const { product, readOnly, isMobile, hasOfferCodes } = props;
  const classes = useStylesProductFields({ isMobile, hasOfferCodes });

  return (
    <div className={"quantity "+classes.quantity}>
        <div className="container">
          <div className="counter">
            {!readOnly && (
              <>
                <IconButton
                  disabled={product.quantity === 1}
                  onClick={() =>
                    dispatch(removeItemQuantityFromCart(product.id))
                  }
                >
                  <SvgIcon viewBox="0 0 15 15">
                    <path d="M7.5,0 C11.6421356,0 15,3.35786438 15,7.5 C15,11.6421356 11.6421356,15 7.5,15 C3.35786438,15 0,11.6421356 0,7.5 C0,3.35786438 3.35786438,0 7.5,0 Z M7.5,1 C3.91014913,1 1,3.91014913 1,7.5 C1,11.0898509 3.91014913,14 7.5,14 C11.0898509,14 14,11.0898509 14,7.5 C14,3.91014913 11.0898509,1 7.5,1 Z M10.038,7.038 C10.2931556,7.038 10.5,7.24484445 10.5,7.5 C10.5,7.75515555 10.2931556,7.962 10.038,7.962 L10.038,7.962 L4.962,7.962 C4.70684445,7.962 4.5,7.75515555 4.5,7.5 C4.5,7.24484445 4.70684445,7.038 4.962,7.038 L4.962,7.038 Z"></path>
                  </SvgIcon>
                </IconButton>
                <Typography
                  align="center"
                  style={{fontSize: 18, position: 'relative', top: 4, fontFamily: 'ProximaNova'}}
                >
                  {(product.quantity || 0)}
                </Typography>

                <IconButton
                  onClick={() => {
                    dispatch(addItemQuantityToCart(product))
                    }
                  }
                >
                  <SvgIcon viewBox="0 0 15 15">
                    <path d="M7.5,0 C11.6421356,0 15,3.35786438 15,7.5 C15,11.6421356 11.6421356,15 7.5,15 C3.35786438,15 0,11.6421356 0,7.5 C0,3.35786438 3.35786438,0 7.5,0 Z M7.5,1 C3.91014913,1 1,3.91014913 1,7.5 C1,11.0898509 3.91014913,14 7.5,14 C11.0898509,14 14,11.0898509 14,7.5 C14,3.91014913 11.0898509,1 7.5,1 Z M7.5,4.5 C7.77614237,4.5 8,4.72385763 8,5 L8,5 L8,7.038 L10.038,7.038 C10.2931556,7.038 10.5,7.24484445 10.5,7.5 C10.5,7.75515555 10.2931556,7.962 10.038,7.962 L10.038,7.962 L8,7.962 L8,10 C8,10.2761424 7.77614237,10.5 7.5,10.5 C7.22385763,10.5 7,10.2761424 7,10 L7,10 L7,7.962 L4.962,7.962 C4.70684445,7.962 4.5,7.75515555 4.5,7.5 C4.5,7.24484445 4.70684445,7.038 4.962,7.038 L4.962,7.038 L7,7.038 L7,5 C7,4.72385763 7.22385763,4.5 7.5,4.5 Z"></path>
                  </SvgIcon>
                </IconButton>
              </>
            )}
          </div>
          {isMobile &&
            <div style={{display: 'flex', position: 'relative', top: isMobile ? 3 : 0, justifyContent: 'flex-end'}}>
              <Price
                size={PriceSize.SMALL}
                amount={product.price * product.quantity}
              />
            </div>
          }
          {!isMobile &&
            <div style={{position: 'relative', top: 4}}>
              <Price
                size={PriceSize.SMALL}
                amount={product.price * product.quantity}
                color={'#878B8D'}
              />
            </div>
          }
        </div>
    </div>
  );
}
