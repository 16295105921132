/**
 * LoginPage selectors
 */

import { createSelector } from 'reselect';
import {initialState} from "../reducers/globalReducer";

const selectGlobal = (state: any) => state.global || initialState;

/** User token selector */
const makeSelectLoading = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.loading,
    );
const makeSelectUserRegisterLoading = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.userRegisterLoading,
    );
const getToken = () =>
  createSelector(
      selectGlobal,
    globalState => globalState.auth.userData.accessToken,
  );
const getRefreshToken = () =>
  createSelector(
    selectGlobal,
    globalState => globalState.auth.userData.refreshToken
  )
const makeSelectLoginError = () =>
    createSelector(
        selectGlobal,
      globalState => globalState.auth.error
    );

/** User profile selectors*/
const makeSelectUserData = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.auth.userData.profile.data,
    );
const makeSelectUserDataCoordinates = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.auth.userData.profile.data.coordinates,
    );
const makeSelectUserDataError = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.auth.userData.profile.error
    );
const makeSelectUserDataLoading = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.auth.userData.profile.loading
    );

const makeSelectUserDataProfileloading = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.auth.userData.loadingProfile
    );

const makeSelectUserDataOrderloading = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.auth.userData.loadingOrders
    );
const makeSelectSnackBar = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.auth.snackbar
    );
const makeSelectCGUOpen = () =>
    createSelector(
        selectGlobal,
        globalState => globalState.navigation.isCGUOpen
    );

export {
    selectGlobal,
    getToken,
    makeSelectLoading,
    makeSelectLoginError,
    makeSelectUserData,
    makeSelectUserDataError,
    makeSelectUserDataLoading,
    makeSelectUserDataProfileloading,
    makeSelectUserDataOrderloading,
    makeSelectUserDataCoordinates,
    makeSelectSnackBar,
    makeSelectCGUOpen,
    makeSelectUserRegisterLoading,
    getRefreshToken
};
