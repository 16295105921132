import makeStyles from "@material-ui/core/styles/makeStyles";
import { Theme } from '@material-ui/core';

interface Props {
  isMobile: boolean
}

export const useStylesPaymentFields = makeStyles((theme: Theme) => ({
    checkbox: {
        color: theme.palette.primary.main,
        marginLeft: 0,
        fontSize: '16px'
    },
    cardDiv: {
        border: 'solid 1px #E1E9EC',
        borderRadius: '5px'
    },
    cardButton: {
        borderBottom: 'solid 1px #E1E9EC',
    },

    cardButtonLeft: {
        borderRight: 'solid 1px #E1E9EC',
    },
    cardButtonRight: {
        borderLeft: 'solid 1px #E1E9EC',
    },

    cardMini: {
      position: 'relative',
      top: -6,
      marginTop: -4,
      height: 14,
      borderRadius: 3
    },
    cardPan: {
      fontSize: 12,
      color: '#888',
      position: 'relative',
      top: -9,
      marginLeft: 8
    },
    cardDateExp: {
      marginLeft: 16
    },
    paymentFields: {
        '& .MuiOutlinedInput-root': {
            backgroundColor: '#E1E9EC',
            borderRadius: 5
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
            borderRadius: 5
        },
        '& .MuiOutlinedInput-input': {
            padding: 8,
            fontSize: 16
        },
        '& .MuiFormHelperText-root': {
            margin: 0,
            marginTop: 4,
        },
        padding: 16
    },
    savedCardPan: {
      marginLeft: 44, marginTop: 8
    },
    cardNumber: {
        '& .MuiOutlinedInput-root': {
            width: 360,
            height: 34,
        },
    },
    expirationDate: {
        '& .MuiOutlinedInput-root': {
            width: 70,
            height: 34,
        },
    },
    cvc: {
        '& .MuiOutlinedInput-root': {
            width: 50,
            height: 34,
        },
    },
    checkboxSaveCard: {
        '& .MuiFormControlLabel-label': {
            fontSize: '16px',
            lineHeight: '18px',
            fontWeight: 400
        },
        color: "#878B8D",
    },
    placeholderColor: {
        "&::placeholder": {
            color: "#232323"
        },
    },
    fields: ({isMobile}: Props) => ({
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      '& .MuiOutlinedInput-input': {
        marginBottom: 0,
        fontSize: 15
      },
      '& .cardSvg': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      },
      '& .cardNumber': {
          margin: isMobile ? '8px 0' : '0 8px'
      },
      '& .row': {
          display: 'flex', flexDirection: 'row'
      },
      '& .smallInput': {
        marginBottom: isMobile ? 8 : 0, maxWidth: isMobile ? 'auto' : 68
      }
    }),
    cardValidity: ({isMobile}: Props) => ({
      padding: isMobile ? '4px 0' : '4px 0 0',
      display: 'flex',
      justifyContent: 'center'
    })
}));
