import React from 'react';
import {Typography} from "@material-ui/core";
import {useStylesPaymentModal} from "../styles";

export const CardFees = ({fees}) => {
  const classes = useStylesPaymentModal();

  return (
    <div style={{display: 'flex', justifyContent: 'space-between', color: "#928f8f"}}>
      <div className="text">
        <Typography style={{fontSize: '14px'}}>Frais de gestion</Typography>
      </div>
      <div className="amount">
        <Typography className={classes.totalFees}>{fees}€</Typography>
      </div>
    </div>
  )
}
