import React from "react";
import FormControl from "@material-ui/core/FormControl";
import {useDispatch, useSelector} from "react-redux";
import TextField from "@material-ui/core/TextField";
import {Autocomplete} from "@material-ui/lab";
import {
  makeSelectUserCitiesCguCities,
  makeSelectUserCitiesCguLoading,
} from './selectors';
import CityModel from "./CityModel";
import {getUserCity} from "./actions";
import Typography from "@material-ui/core/Typography";

export const userCitiesCGUKey = 'userCityCGUPage'

export default function CustomCityWidget(props: any) {
  const dispatch = useDispatch();
  const cities = useSelector(makeSelectUserCitiesCguCities());
  const loading = useSelector(makeSelectUserCitiesCguLoading());

  function handleChange(event: any) {
    dispatch(getUserCity(event.target.value))
  }

  return (
    <FormControl variant="outlined">
      {!props.noExternalLabel && (
        <Typography style={{color: 'rgba(0,0,0,0.54)'}}>
          {props.label+' '}
          {props.required &&
            <span style={{color: 'red'}}>*</span>
          }
        </Typography>
      )}
      <Autocomplete
        autoComplete
        id="user-city-autocomplete"
        value={props.value ?? undefined}
        onChange={(event: any, value: any) => props.onChange(value)}
        openOnFocus={false}
        options={cities ?? []}
        loading={loading}
        noOptionsText="Rechercher votre ville"
        loadingText="Recherche en cours..."
        getOptionLabel={(option: CityModel) => option?.name}
        onInputChange={(evt: any, value: string, reason: string) => {
          if (reason === "clear") {
            props.onChange(undefined);
            return;
          }
        }}
        renderInput={(params: any) =>
          <TextField
            {...params}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'off',
              form: {
                ...params.inputProps.form,
                autocomplete: 'off',
              }
            }}
            required={props.required}
            className={props.className ?? "custom"}
            style={{ background: '#E1E9EC', borderRadius: 5 }}
            label={props.noExternalLabel ? props.label : undefined}
            variant="outlined"
            onChange={handleChange}
          />
        }
      />
    </FormControl>
  );
};
