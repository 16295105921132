import {useGTMDispatch} from "@elgorditosalsero/react-gtm-hook";
import {useContext} from "react";
import {UserContext} from "../context/user-context";
import {useEdfConsent} from "../../../../apps/phoenix/src/utils/hooks";
import {get} from 'local-storage';
import { SETTINGS } from '@smartpush-front/styles';
import {useTrackingMatomo} from "./useTrackingMatomo";
import {useSelector} from "react-redux";
import {makeSelectPartnerSettings} from "../../../../apps/phoenix/src/app/pages/LoginPage/selectors";

const trackingAudience = 'tracking';
const trackingRecommandation = 'recommandation';
const fullTracking = 'tracking_recommendation';
const listEventWithScreenName = ["page_view", "select_item", "add_to_wishlist", "coupon_download", "view_item", "select_item", "sign_up_funnel"];

const useTracking = () => {
  const {user} = useContext(UserContext);
  const {sendMatomo} = useTrackingMatomo();
  const sendDataToGTM = useGTMDispatch()
  const LSCookies: any = get('consentGDPR')
  const consentEdf = useEdfConsent();
  const partnerSettings = useSelector(makeSelectPartnerSettings());

  const triggerTrackingEvent = (trackingObj) => {
    trackingObj.data = {...trackingObj.data}
    if (listEventWithScreenName.includes(trackingObj.data.event)) {
      trackingObj.data = {...trackingObj.data};
    }
    if (user) {
      trackingObj.data = {...trackingObj.data, "user_id": user.id, usage: user.statisticalCookiesAt !== null || (LSCookies?.consent.statisticalCookies === "1") || consentEdf   ? fullTracking : trackingRecommandation}
    } else {
      trackingObj.usage = (LSCookies?.consent?.statisticalCookies === "1") || consentEdf ? trackingAudience : null;
    }

    if (partnerSettings?.analytics?.gtm) {
      sendDataToGTM(trackingObj?.data)
    }
    if (partnerSettings?.analytics?.matomo && (user || trackingObj.usage)) {
      sendMatomo(trackingObj.data, trackingObj?.category)
    }
    if (partnerSettings?.analytics?.postMessage) {
      window.parent.postMessage(trackingObj?.data, SETTINGS.iFrameConfig.targetOrigin)
    }
  }

  return {triggerTrackingEvent}
}

export {useTracking}
