/**
 * Gets the cities for CGU Modal
 */

import {call, put, select, takeLatest} from 'redux-saga/effects';
import {
  SmartpushAPI,
  RequestOptions,
  SMARTPUSH_API_URL_CITY_BY_ID,
  SMARTPUSH_API_URL_CITIES
} from "@smartpush-front/api";
import {getToken} from "@smartpush-front/store";
import {
  GET_USER_CITY,
  GET_USER_CITY_BY_ID, getUserCityByIdError,
  getUserCityByIdSuccess,
  getUserCityError,
  getUserCitySuccess
} from "./actions";
import {injectParameter} from "../../../../../../libs/offers/src/utils/url/injectParameter";
import {SETTINGS} from "@smartpush-front/styles";
import {getOffersError} from "@smartpush-front/offers";

/**
 * Suggestions for Autocomplete request/response handler
 */
export function* getUserCitiesCall(action: any) {
  const token = yield select(getToken());
  const options: RequestOptions = {
    url: SMARTPUSH_API_URL_CITIES + '?filters[name]=' + action.cityName,
    model: action.model,
    config: {
      headers: {
        Authorization: token
      }
    }
  };

  try {
    const {data} = yield call(SmartpushAPI.get, options);

    /** Put cities in redux */
    yield put(getUserCitySuccess(data.data));

  } catch (err) {
    yield put(getUserCityError(err));
  }
}

export function* getCityByIdCall(action: any) {
  const token = yield select(getToken());
  const cityOptions = {
    url   : injectParameter(SMARTPUSH_API_URL_CITY_BY_ID, action.cityId),
    config: {
      headers: {
        Authorization: token
      }
    }
  };
  try {
    const {data} = yield call(SmartpushAPI.get, cityOptions);
    /** Retrieve city through redux */
    yield put(getUserCityByIdSuccess(data));
  } catch (err) {
    yield put(getUserCityByIdError(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* getSuggestionsAPI() {
  // Watches for GET_USER_CITY actions and calls getUser when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(GET_USER_CITY, getUserCitiesCall);
  yield takeLatest(GET_USER_CITY_BY_ID, getCityByIdCall);
}
