import {Typography} from "@material-ui/core";
import React from "react";
import {useStylesPaymentModal} from "../../../../../payment/src/lib/Payment/components/OrderMain/styles";
import {OrderSaving} from "../../../../../payment/src/lib/Payment/components/OrderMain/components/OrderSaving";
import {
  CardFees,
} from "../../../../../payment/src/lib/Payment/components/OrderMain/components/CardFees";
import {
  SubTotalWithoutFees
} from "../../../../../payment/src/lib/Payment/components/OrderMain/components/SubTotalWithoutFees";

export const OrderContentTotal = (props: any) => {
  const classes = useStylesPaymentModal();

  const {total, savings, fees, totalWithoutFees} = props;

  return (
    <div className={classes.totalPrice}>
      {fees && (
        <>
          <SubTotalWithoutFees totalWithoutFees={totalWithoutFees} />
          <OrderSaving savings={savings}/>
          <CardFees fees={fees} />
        </>
      )}
      <div style={{marginBottom: "0.2rem", marginTop: fees ? '1rem' : 0}}>
        <div className="text">
          <Typography className="title" style={{fontSize: '18px'}}>TOTAL</Typography>
        </div>
        <div className="amount">
          <Typography className={classes.totalAmountCommon}>{total}€</Typography>
        </div>
      </div>
      {!fees && (
        <OrderSaving savings={savings} />
      )}
    </div>
  )
}
