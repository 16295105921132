/*
 * OffersPage reducer
 *
 * The reducer takes care of our data. Using actions, we can
 * update our application state. To add a new action,
 * add it to the switch statement in the reducer function
 *
 */
import produce from 'immer';
import {
  CLEAR_ALL_FILTERS,
  GET_MORE_OFFERS,
  GET_MORE_OFFERS_ERROR,
  GET_MORE_OFFERS_SUCCESS,
  GET_MORE_OFFERS_FOR_EDF,
  GET_MORE_OFFERS_FOR_EDF_ERROR,
  GET_MORE_OFFERS_FOR_EDF_SUCCESS,
  GET_OFFER_SUCCESS,
  GET_OFFERS,
  GET_OFFERS_ERROR,
  GET_OFFERS_SUCCESS,
  SET_CATEGORY,
  SET_TYPES,
  SET_TAGS,
  SET_OFFER_BRAND,
  SET_OFFER_CITY,
  SET_IS_WEB,
  TOGGLE_DISPLAY_MAP,
  TOGGLE_DISPLAY_OFFER_MODAL,
  TOGGLE_SELECTED_SHOP_DISPLAY_MAP,
  LOAD_OFFERS,
  LOAD_OFFERS_ERROR,
  LOAD_OFFERS_SUCCESS,
  GET_CITY_SUCCESS,
  GET_RECOMMENDED_OFFERS_SUCCESS,
  GET_HIGHLIGHTED_OFFERS_SUCCESS,
  GET_RECOMMENDED_OFFERS,
  GET_HIGHLIGHTED_OFFERS,
  GET_RECOMMENDED_OFFERS_ERROR,
  GET_HIGHLIGHTED_OFFERS_ERROR,
  GET_BRAND_WITH_OFFERS,
  GET_BRAND_WITH_OFFERS_SUCCESS,
  GET_BRAND_WITH_OFFERS_ERROR,
  GET_BRAND_ERROR,
  GET_BRAND_SUCCESS,
  TOGGLE_DISPLAY_BRAND_MODAL,
  TOGGLE_DISPLAY_SEARCH_MODAL,
  GET_OFFER,
  GET_MORE_OFFERS_PAGINATION,
  GET_MORE_OFFERS_PAGINATION_SUCCESS,
  GET_MORE_OFFERS_PAGINATION_ERROR,
  GET_OFFERS_MAP_SUCCESS, GET_OFFERS_MAP, GET_OFFER_ERROR, RESET_CITY,
} from './constants';
import {
  ADD_COUPON, ADD_COUPON_ERROR,
  ADD_COUPON_SUCCESS,
  CLEAR_COUPON, CLOSE_EXTERNAL_OFFER_MODAL,
  PUT_OFFER_IN_WALLET_SUCCESS, REDIRECT_TO_EXTERNAL_OFFER_SUCCESS
} from "./Offer/constants";

// The initial state of the Offers reducer
export const initialState = {
  offers: [],
  offersPagination: {},
  webOffersPagination: {},
  highlighted: [],
  recommended: [],
  offersLoading: false,
  highlightedOffersLoading: false,
  recommendedOffersLoading: false,
  currentCoupon: {},
  isWeb: "",
  offerBrand: "",
  offerCity: {},
  cityInfos: {},
  category: "",
  types: "",
  tags: "",
  loading: false,
  moreOfferLoading: false,
  error: undefined,
  shopSelectedMap: {},
  preSelectedShop: {},
  mapDisplayed: false,
  modalDisplayed: false,
  searchModalDisplayed: false,
  externalOffer: {},
  modalOffer: {},
  modalOfferComponentName: "",
  modalBrandOffersDisplayed: false,
  modalBrandOffer: {},
  brandOffer: {},
  modalBrandOffersList: [],
  modalBrandOffersLoading: false,
  cartOffers: [],
  couponHasError: false
};

interface InitialStateProps {
  offers: any[],
  offersPagination: any,
  webOffersPagination: any,
  highlighted: any[],
  recommended: any[],
  offersLoading: boolean,
  highlightedOffersLoading: boolean,
  recommendedOffersLoading: boolean,
  currentCoupon: any,
  isWeb: any,
  offerBrand: string,
  offerCity: any,
  cityInfos: any,
  category: string,
  types: string,
  tags: string,
  loading: boolean,
  moreOfferLoading: boolean,
  error?: any,
  shopSelectedMap: any
  preSelectedShop: any
  mapDisplayed: boolean
  modalDisplayed: boolean
  searchModalDisplayed: boolean
  externalOffer: any
  modalOffer: any
  modalOfferComponentName: string,
  modalBrandOffersDisplayed: boolean
  modalBrandOffer: any
  brandOffer: any
  modalBrandOffersLoading: boolean
  modalBrandOffersList: any[]
  cartOffers: any[]
  couponHasError: boolean
}

function mergeOffers(action) {
  //Here we have to merge web and shop offers one by one
  let offersMerged = [];
  if (Array.isArray(action.offers?.data) && action.offers?.data?.length > 0) {
    action.offers.data.forEach((row, i) => {
      offersMerged = [...offersMerged, row];
      if (action.webOffers?.data?.length > i) {
        offersMerged = [...offersMerged, action.webOffers.data[i]];
      }
    });
  }
  if (action.webOffers?.data?.length > action.offers?.data?.length) {
    offersMerged = [...offersMerged, ...action.webOffers?.data.slice(action.offers.data?.length)];
  }

  return offersMerged;
}

function calculateDiscountValueFromOffer(offer) {
  if (offer.offerVariantPrices && offer.offerVariantPrices.length > 0) {
    const firstVariant = offer.offerVariantPrices[0];
    const price = firstVariant.price;
    if (offer.discountType === "%") {
      return (
        Math.ceil(((firstVariant.faceValue - price) / firstVariant.faceValue) * 100)
      );
    } else {
      return firstVariant.faceValue - price;
    }
  } else {
    return parseFloat(offer.discountAmount);
  }
}


/* eslint-disable default-case, no-param-reassign */
export const offersReducer = (state: InitialStateProps = initialState, action: any) =>
  produce(state, draft => {
    switch (action.type) {
      case LOAD_OFFERS:
      case GET_OFFERS_MAP:
        draft.loading = true;
        break;
      case GET_OFFERS:
        draft.offersLoading = true;
        break;
      case GET_MORE_OFFERS_PAGINATION:
        draft.offersLoading = true;
        break;
      case GET_RECOMMENDED_OFFERS:
        draft.loading = true;
        draft.recommendedOffersLoading = true;
        break;
      case GET_HIGHLIGHTED_OFFERS:
        draft.loading = true;
        draft.highlightedOffersLoading = true;
        break;
      case GET_OFFERS_SUCCESS:
      case GET_MORE_OFFERS_PAGINATION_SUCCESS:
        draft.loading = false;
        draft.offersLoading = false;
        draft.offers = action.offers.data
        draft.offersPagination = {
          pages: Number(action.offers?.headers['pagination-count'] ?? 1),
          page: Number(action.offers?.headers['pagination-page'] ?? 1),
          total: Number(action.offers?.headers['element-count'] ?? 0)
        };
        break;
      case GET_OFFERS_MAP_SUCCESS:
        draft.loading = false;
        draft.offersLoading = false;
        draft.offers = action.offers;
        draft.offersPagination = {
          pages: Number(action.offers?.headers['pagination-count'] ?? 1),
          page: Number(action.offers?.headers['pagination-page'] ?? 1),
          total: Number(action.offers?.headers['element-count'] ?? 0)
        };
        draft.webOffersPagination = {};
        break;
      case GET_RECOMMENDED_OFFERS_SUCCESS:
        draft.recommendedOffersLoading = false;
        if (!state.highlightedOffersLoading) {
          draft.loading = false;
        }
        draft.recommended = action.recommendedOffers;
        break;
      case GET_HIGHLIGHTED_OFFERS_SUCCESS:
        draft.highlightedOffersLoading = false;
        if (!state.recommendedOffersLoading) {
          draft.loading = false;
        }
        draft.highlighted = action.highlightedOffers;
        break;
      case GET_RECOMMENDED_OFFERS_ERROR:
        draft.recommendedOffersLoading = false;
        if (!state.highlightedOffersLoading) {
          draft.loading = false;
        }
        break;
      case GET_HIGHLIGHTED_OFFERS_ERROR:
        draft.highlightedOffersLoading = false;
        if (!state.recommendedOffersLoading) {
          draft.loading = false;
        }
        break;
      case GET_OFFERS_ERROR:
        draft.offersLoading = true;
        break;
      case GET_MORE_OFFERS_PAGINATION_ERROR:
        draft.offersLoading = false;
        break;
      case LOAD_OFFERS_ERROR:
        draft.loading = false;
        draft.error = action.error;
        break;
      case LOAD_OFFERS_SUCCESS:
        draft.cartOffers = action.offers;
        draft.loading = false;
        break;
      case GET_MORE_OFFERS:
        draft.moreOfferLoading = true;
        break;
      case GET_MORE_OFFERS_SUCCESS:
        draft.moreOfferLoading = false;
        draft.offers = [...state.offers, ...action.offers.data];
        draft.offersPagination = {
          pages: Number(action.offers?.headers['pagination-count'] ?? 1),
          page: Number(action.offers?.headers['pagination-page'] ?? 1),
          total: Number(action.offers?.headers['element-count'] ?? 0)
        };
        break;
      case GET_MORE_OFFERS_ERROR:
      case GET_MORE_OFFERS_FOR_EDF_ERROR:
        draft.moreOfferLoading = false;
        draft.error = action.error;
        break;
      case GET_MORE_OFFERS_FOR_EDF:
        draft.moreOfferLoading = true;
        break;
      case GET_MORE_OFFERS_FOR_EDF_SUCCESS:
        draft.moreOfferLoading = false;
        if (!action.webOffers) {
          draft.offers = action.offers;
        } else {
          draft.offers = mergeOffers(action);
        }
        draft.offersPagination = {
          pages: Number(action.offers?.headers['pagination-count'] ?? 1),
          page: Number(action.offers?.headers['pagination-page'] ?? 1),
          total: Number(action.offers?.headers['element-count'] ?? 0)
        };
        draft.webOffersPagination = action.webOffers?.pagination ?? {};
        break;
      case SET_IS_WEB:
        draft.isWeb = action.isWeb;
        break;
      case SET_OFFER_BRAND:
        draft.offerBrand = action.brandId;
        break;
      case SET_OFFER_CITY:
        draft.offerCity = action.cityId;
        break;
      case GET_CITY_SUCCESS:
        draft.offerCity = action.city.coordinates;
        draft.cityInfos = action.city;
        break;
      case RESET_CITY:
        draft.offerCity = {};
        draft.cityInfos = {};
        break;
      case SET_CATEGORY:
        draft.category = action.categoryId;
        break;
      case SET_TYPES:
        draft.types = action.typeId;
        break;
      case SET_TAGS:
        draft.tags = action.tagId;
        break;
      case CLEAR_ALL_FILTERS:
        draft.isWeb = "";
        draft.offerBrand = "";
        draft.offerCity = "";
        draft.category = "";
        draft.types = "";
        break;
      case PUT_OFFER_IN_WALLET_SUCCESS:
        const offersIndex = state.offers.findIndex((offer: any) => offer.id === action.offerId);
        if (offersIndex >= 0) {
          draft.offers[offersIndex] = {
            ...draft.offers[offersIndex],
            inWallet: action.inWallet
          };
        }
        const recommendedIndex = state.recommended.findIndex((offer: any) => offer.id === action.offerId);
        if (recommendedIndex >= 0) {
          draft.recommended[recommendedIndex] = {
            ...draft.recommended[recommendedIndex],
            inWallet: action.inWallet
          };
        }
        const highlightedIndex = state.highlighted.findIndex((offer: any) => offer.id === action.offerId);
        if (highlightedIndex >= 0) {
          draft.highlighted[highlightedIndex] = {
            ...draft.highlighted[highlightedIndex],
            inWallet: action.inWallet
          };
        }
        if (action.inModal) {
          draft.modalOffer = {
            ...state.modalOffer,
            inWallet: action.inWallet
          };
        }
        break;
      case ADD_COUPON:
        break;
      case ADD_COUPON_SUCCESS:
        draft.currentCoupon = action.couponData;
        break;
      case ADD_COUPON_ERROR:
        if (action.error.statusCode === 410) {
          draft.couponHasError = true;
        }
        break;
      case CLEAR_COUPON:
        draft.currentCoupon = {};
        draft.modalDisplayed = false;
        draft.modalBrandOffersDisplayed = false;
        draft.modalOffer = {};
        draft.modalBrandOffer = {};
        draft.modalOfferComponentName = "";
        draft.couponHasError = false;
        break;
      case REDIRECT_TO_EXTERNAL_OFFER_SUCCESS:
        draft.externalOffer = {
          url: action.url,
          data: action.data
        };
        break;
      case CLOSE_EXTERNAL_OFFER_MODAL:
        draft.externalOffer = {};
        break;
      case TOGGLE_DISPLAY_MAP:
        draft.mapDisplayed = !state.mapDisplayed;
        break;
      case TOGGLE_SELECTED_SHOP_DISPLAY_MAP:
        draft.shopSelectedMap = action.shop;
        break;
      case TOGGLE_DISPLAY_BRAND_MODAL:
        draft.modalBrandOffersDisplayed = action.isDisplayed;
        draft.modalBrandOffer = action.offer;
        draft.modalOfferComponentName = action.componentName;
        draft.preSelectedShop = action.preSelectedShopFromMap ? action.preSelectedShopFromMap : {};
        break;
      case TOGGLE_DISPLAY_OFFER_MODAL:
        draft.modalDisplayed = action.isDisplayed;
        draft.modalOffer = action.offer;
        draft.modalOfferComponentName = action.componentName;
        draft.modalBrandOffer = {};
        draft.modalBrandOffersList = [];
        draft.preSelectedShop = action.preSelectedShopFromMap ? action.preSelectedShopFromMap : {};
        break;
      case TOGGLE_DISPLAY_SEARCH_MODAL:
        draft.searchModalDisplayed = !state.searchModalDisplayed;
        break;
      case GET_OFFER:
        draft.loading = true;
        draft.modalOffer = {};
        break;
      case GET_OFFER_SUCCESS:
          draft.modalDisplayed = true;
          draft.modalOffer = action.offer;
          draft.modalOffer.calculatedDiscountAmount = calculateDiscountValueFromOffer(action.offer)
          draft.loading = false;
        break;
      case GET_OFFER_ERROR:
        draft.loading = false;
        draft.modalOffer = null;
        break;
      case GET_BRAND_WITH_OFFERS:
        draft.modalBrandOffersLoading = true;
        draft.modalBrandOffersList = [];
        break;
      case GET_BRAND_WITH_OFFERS_SUCCESS:
        draft.modalBrandOffersLoading = false;
        draft.modalBrandOffersList = action.brandOffers;
        break;
      case GET_BRAND_WITH_OFFERS_ERROR:
        draft.modalBrandOffersLoading = false;
        draft.modalBrandOffersList = [];
        break;
      case GET_BRAND_SUCCESS:
        draft.modalBrandOffersLoading = false;
        draft.brandOffer = action.brandOffers;
        break;
      case GET_BRAND_ERROR:
        draft.brandOffer = [];
        break;
    }
  });
