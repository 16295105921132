import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import OrderCard from "../../OrderCard/OrderCard";
import React from "react";
import {useStylesPaymentModal} from "../styles";
import {SETTINGS} from "@smartpush-front/styles";

interface OrderContentPaymentMethodProps {
  pendingOrSuccess: boolean;
  card: any;
}

export const OrderContentPaymentMethod = ({pendingOrSuccess, card}: OrderContentPaymentMethodProps) => {
  const classes = useStylesPaymentModal();

  const getSpecificCss = () => {
    return clsx(classes.sectionTitle, classes.orderContent)
  }

  if (pendingOrSuccess) {
    return null;
  }

  return (
    <Grid container alignItems="flex-start" className={classes.sectionPaddingOrder}>
      <Grid item xs={12}>
        <Typography className={getSpecificCss()}>
          Mon mode de paiement
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <OrderCard card={card}/>
      </Grid>
    </Grid>
  )
}
