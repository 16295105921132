import {Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import MailIcon from "@material-ui/icons/AlternateEmail";
import React from "react";
import {useStylesPaymentModal} from "../styles";

interface OrderContentDeliveryProps {
  deliveryEmail: string|null;
}

export const OrderContentDelivery = ({deliveryEmail}: OrderContentDeliveryProps) => {
  const classes = useStylesPaymentModal();
  if (!deliveryEmail) {
    return null;
  }

  return (
    <Grid container alignItems="flex-start" className={classes.sectionPaddingOrder}>
      <Grid item xs={12}>
        <Typography className={clsx(classes.sectionTitle, classes.orderContent)}>
          Mon adresse de livraison
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography style={{display: 'flex', alignItems: 'center', flexWrap: 'wrap'}} >
          <MailIcon/>
          <span style={{paddingLeft: '10px'}}>{deliveryEmail}</span>
        </Typography>
      </Grid>
    </Grid>
  )
}
