import React from "react";
import { Grid } from "@material-ui/core";
import {useStylesPaymentModal} from "../styles";
import Typography from "@material-ui/core/Typography";
import {Alert} from "@material-ui/lab";
import InfoIcon from "@material-ui/icons/Info";
import AlertTitle from "@material-ui/lab/AlertTitle";
import {SETTINGS} from "@smartpush-front/styles";

export const OrderPSPInternalError = () => {
  const classes = useStylesPaymentModal();
  const getTextContent = () => {
    if (SETTINGS?.familiarity) {
      return "Ton paiement n'a pu aboutir, tu ne seras pas débité. Nous t'invitons à renouveler ton paiement d'ici quelques instants."
    }

    return "Votre paiement n’a pu aboutir, vous ne serez donc pas débité. Nous vous invitons à renouveler votre paiement d’ici quelques instants."
  }
  return (
    <Grid item xs={12}>
        <Alert
            icon={<InfoIcon/>}
            variant="filled"
            severity="warning"
            className={classes.alert}>
            <AlertTitle>Paiement refusé.</AlertTitle>
            <Typography>
              {getTextContent()}
            </Typography>
        </Alert>
    </Grid>
  );
}
