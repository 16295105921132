import React from 'react';

import { Alert } from '@material-ui/lab';
import InfoIcon from "@material-ui/icons/Info";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { Typography, CircularProgress } from '@material-ui/core';

import { useWidgetStyles } from './styles';

export function hasError(missingParameters: string[], isLoading: boolean, unauthorizedError: boolean): boolean {
  return missingParameters.length > 0 || isLoading || unauthorizedError;
}

interface Props {
  missingParameters: string[],
  isLoading: boolean,
  unauthorizedError: boolean,
  invalidOfferError: boolean
}
export function WidgetError({ missingParameters, isLoading, unauthorizedError, invalidOfferError }: Props) {
  const classes = useWidgetStyles();

  const s = missingParameters.length > 1 ? 's' : '';
  const sont = missingParameters.length > 1 ? 'sont' : 'est';

  if (missingParameters.length > 0) {
    return (
      <Alert
          icon={<InfoIcon/>}
          variant="filled"
          severity="warning"
          className={classes.alert}>
          <AlertTitle>Erreur: Paramètre{s} manquant{s}</AlertTitle>
          <Typography>
              Le{s} paramètre{s} requis suivant {sont} manquant{s}:
              {missingParameters.map(param => (
                <span style={{display: 'block'}} key={param}>- {param}</span>
              ))}
          </Typography>
      </Alert>
    )
  }

  if (isLoading || unauthorizedError) {
    return (<div className={classes.centered}><CircularProgress size={24} /></div>);
  }

  if (invalidOfferError) {
    return (
      <Alert
          icon={<InfoIcon/>}
          variant="filled"
          severity="warning"
          className={classes.alert}>
          <AlertTitle>Erreur: Offre invalide</AlertTitle>
          <Typography>
              L'offre demandé n'existe pas ou n'est plus disponible.
          </Typography>
      </Alert>
    )
  }
}
