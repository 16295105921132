import makeStyles from "@material-ui/core/styles/makeStyles";
import {COLORS} from "@smartpush-front/styles";
import { Theme } from '@material-ui/core';

interface Props {
  isMobile: boolean;
}

export const useStylesUserOrder = makeStyles((theme: Theme) => ({
  card: {
    maxWidth: 745,
    borderRadius: 5,
    backgroundColor: COLORS.white,
    padding: theme.spacing(2, 0, 2, 0),
    boxShadow: '0 2px 4px 0 rgba(152,152,152,0.62)',
  },
  breadCrumbsMobile: ({isMobile}: Props) => ({
    margin: isMobile ? '8px 0' : '48px 0px 32px',
    maxWidth: 745,
    '& .back': {
      textDecoration: 'none',
      '& p': {
        lineHeight: '24px',
        marginRight: isMobile ? 16 : 0,
        cursor: 'pointer',
        '& span': {
          position: 'relative',
          top: -5,
          left: -5,
          fontSize: '20px'
        }
      }
    },
    '& .orderNumber': {
      color: theme.palette.primary.main,
      fontSize: '20px',
      lineHeight: '29px',
      fontFamily: 'ProximaNovaBold'
    }
  }),
  black: {
    color: 'black'
  },
  breadCrumbsMain: {
    fontSize: '24px',
    lineHeight: '29px',
    fontFamily: 'ProximaNovaBold'
  },
  breadCrumbsSeparator: {
    margin: '0 8px'
  },
  redColor: {
    color: theme.palette.primary.main,
  },
  topPadding: ({isMobile}: Props) => ({
    paddingTop: isMobile ? 8 : 32
  }),
  horizontalPadding: ({isMobile}: Props) => ({
    paddingLeft: isMobile ? 16 : 44,
    paddingRight: isMobile ? 16 : 44,
  }),
  title: {
    fontWeight: 800,
    fontFamily: 'ProximaNovaBold'
  },
  titleSub: {
    fontWeight: 800,
    fontSize: 16,
    paddingBottom: 8
  },
  summary: {
    fontWeight: 200
  },
  divider: {
    marginTop: 24,
    marginBottom: 24,
  },
  subtitle: {
    fontSize: '10px',
    lineHeight:'10px',
    fontWeight: 300,
    color: '#878B8D',
    paddingTop: "4px",
    paddingRight: "16px"
  },
  subtitleValue: {
    fontSize: '14px',
    fontWeight: 200,
    lineHeight:'16px',
    paddingRight: "48px"
  },
  noPaddingRight: {
    paddingRight: 0
  },
  contactUsTypo: {
    marginTop: "7px",
    marginBottom: "10px",
    fontFamily: 'ProximaNova',
    textDecoration: 'underline',
    display: "flex",
    justifyContent: "center"
  },
  needHelpTypo: {
      fontFamily: 'ProximaNovaBold',
      fontSize:"16px",
      display: "flex",
      justifyContent: "center"
  },
  bottomPadding: {
    paddingBottom: 16
  },
  smBottomPadding: {
    paddingBottom: 8
  },
  smTopPadding: {
    paddingTop: 8
  },
  xsBottomPadding: {
    paddingBottom: 0,
    marginBottom: 0
  },
  cardItem: {
    paddingRight: '16px'
  },
  cardPicture: {
    height: '75px',
    borderRadius: 5,
    boxShadow: '0 2px 4px 0 rgba(152,152,152,0.62)',
  },
  productTitle: {
    fontSize: '14px',
    marginBottom: '4px',
    lineHeight: '16px',
  },
  productDescription: {
    fontSize: '16px',
    lineHeight: '19px',
    fontWeight: 200,
    color: '#878B8D'
  },
  pdfButton: {
    paddingLeft: 32,
    '& button': {
      '& .MuiFab-label': {
        fontFamily: 'ProximaNovaBold'
      }
    }
  },
  statusIcon: {
    fontSize: '16px'
  },
  pendingColor: {
    color: "#F6B93B"
  },
  completedColor: {
    color: theme.palette.primary.main
  },
  minWidthContainer: {
    minWidth: 150
  }
}));
