import SvgIcon from "@material-ui/core/SvgIcon/SvgIcon";
import IconButton from "@material-ui/core/IconButton";
import React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";

interface QuantityButtonPropsI {
  handleClick: any;
  type: string
}

export const QuantityButton = ({handleClick, type}: QuantityButtonPropsI) => {
  const classes = styles();
  const getSvg = () => {
    switch (type) {
      case 'minus':
        return <path d="M7.5,0 C11.6421356,0 15,3.35786438 15,7.5 C15,11.6421356 11.6421356,15 7.5,15 C3.35786438,15 0,11.6421356 0,7.5 C0,3.35786438 3.35786438,0 7.5,0 Z M7.5,1 C3.91014913,1 1,3.91014913 1,7.5 C1,11.0898509 3.91014913,14 7.5,14 C11.0898509,14 14,11.0898509 14,7.5 C14,3.91014913 11.0898509,1 7.5,1 Z M10.038,7.038 C10.2931556,7.038 10.5,7.24484445 10.5,7.5 C10.5,7.75515555 10.2931556,7.962 10.038,7.962 L10.038,7.962 L4.962,7.962 C4.70684445,7.962 4.5,7.75515555 4.5,7.5 C4.5,7.24484445 4.70684445,7.038 4.962,7.038 L4.962,7.038 Z"/>
      case 'plus':
        return <path d="M7.5,0 C11.6421356,0 15,3.35786438 15,7.5 C15,11.6421356 11.6421356,15 7.5,15 C3.35786438,15 0,11.6421356 0,7.5 C0,3.35786438 3.35786438,0 7.5,0 Z M7.5,1 C3.91014913,1 1,3.91014913 1,7.5 C1,11.0898509 3.91014913,14 7.5,14 C11.0898509,14 14,11.0898509 14,7.5 C14,3.91014913 11.0898509,1 7.5,1 Z M7.5,4.5 C7.77614237,4.5 8,4.72385763 8,5 L8,5 L8,7.038 L10.038,7.038 C10.2931556,7.038 10.5,7.24484445 10.5,7.5 C10.5,7.75515555 10.2931556,7.962 10.038,7.962 L10.038,7.962 L8,7.962 L8,10 C8,10.2761424 7.77614237,10.5 7.5,10.5 C7.22385763,10.5 7,10.2761424 7,10 L7,10 L7,7.962 L4.962,7.962 C4.70684445,7.962 4.5,7.75515555 4.5,7.5 C4.5,7.24484445 4.70684445,7.038 4.962,7.038 L4.962,7.038 L7,7.038 L7,5 C7,4.72385763 7.22385763,4.5 7.5,4.5 Z"/>
    }
  }
  return (
    <IconButton
      className={classes.quantityButton}
      onClick={handleClick}
    >
      <SvgIcon
        viewBox="0 0 15 15"
        className={classes.quantityIcon}
      >
        {getSvg()}
      </SvgIcon>
    </IconButton>
  )
}

const styles = makeStyles(theme => ({
  quantityButton: {
    padding: 0,
    height: '24px',
    width: '24px',
  },
  quantityIcon: {
    height: '24px',
    width: '24px',
    color: theme.palette.primary.main
  },
}));
