import produce from 'immer';
import {
  GET_USER_CITY, GET_USER_CITY_BY_ID, GET_USER_CITY_BY_ID_ERROR,
  GET_USER_CITY_BY_ID_SUCCESS,
  GET_USER_CITY_ERROR,
  GET_USER_CITY_SUCCESS
} from "./actions";

export const initialStateUserCitiesReducer: UserCitiesReducerProps = {
  cities: [],
  city: null,
  loading: false
};

interface UserCitiesReducerProps {
  cities: any[],
  city?: any,
  loading: boolean
}

export const userCityReducer = (
  state: UserCitiesReducerProps = initialStateUserCitiesReducer,
  action: any
) => produce(state, draft => {
    switch (action.type) {
      case GET_USER_CITY:
        draft.loading = true;
        draft.cities  = [];
        break;
      case GET_USER_CITY_SUCCESS:
        draft.loading = false;
        draft.cities  = action.cities.sort((a: any, b: any) => {
          if (a.population > b.population) return -1;
          if (a.population < b.population) return 1;
          return 0;
        });
        break;
      case GET_USER_CITY_ERROR:
        draft.loading = false;
        draft.cities  = [];
        break;
      case GET_USER_CITY_BY_ID:
        draft.loading = true;
        draft.city    = null;
        break;
      case GET_USER_CITY_BY_ID_SUCCESS:
        draft.loading = false;
        draft.cities  = [...state.cities, action.city];
        draft.city    = action.city;
        break;
      case GET_USER_CITY_BY_ID_ERROR:
        draft.loading = false;
        draft.city    = null;
        break;
    }
  });
