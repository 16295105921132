/**
 * Gets the offers
 */

import {call, put, all, select, takeLatest} from 'redux-saga/effects';
import {
  CLEAR_ALL_FILTERS,
  GET_MORE_OFFERS,
  GET_MORE_OFFERS_FOR_EDF,
  GET_OFFER,
  GET_OFFERS,
  LOAD_OFFERS,
  GET_CITY,
  GET_RECOMMENDED_OFFERS,
  GET_HIGHLIGHTED_OFFERS,
  GET_BRAND_WITH_OFFERS,
  GET_BRAND,
  SEND_ANALYTICS_OFFER_VIEW,
  SEND_ANALYTICS_OFFER_WALLET, SEND_ANALYTICS_OFFER_PRINT, GET_MORE_OFFERS_PAGINATION, GET_OFFERS_MAP,
} from './constants';
import {
  getMoreOffersError,
  getMoreOffersSuccess,
  getMoreOffersForEdfError,
  getMoreOffersForEdfSuccess,
  getOffersError,
  getOffersSuccess,
  getOfferSuccess,
  loadOffersSuccess,
  loadOffersError,
  getCitySuccess,
  getCityError,
  getRecommendedOffersSuccess,
  getRecommendedOffersError,
  getHighlightedOffersSuccess,
  getHighlightedOffersError,
  getBrandWithOffersSuccess,
  getBrandWithOffersError,
  sendAnalyticsOfferViewSuccess,
  sendAnalyticsOfferViewError,
  sendAnalyticsOfferWalletSuccess,
  sendAnalyticsOfferWalletError,
  sendAnalyticsOfferPrintSuccess,
  sendAnalyticsOfferPrintError,
  getOffersPaginationSuccess,
  getOffersPaginationError,
  getOffersMapSuccess,
  getBrandError,
  getBrandSuccess,
  getOfferError,
} from './actions';
import {
  SMARTPUSH_API_URL_ANALYTICS_OFFER_PRINT,
  SMARTPUSH_API_URL_ANALYTICS_OFFER_VIEW, SMARTPUSH_API_URL_ANALYTICS_OFFER_WALLET,
  SMARTPUSH_API_URL_BRAND_OFFERS,
  SmartpushAPI,
  SMARTPUSH_API_URL_BRAND,
  SMARTPUSH_API_URL_OFFER,
  SMARTPUSH_API_URL_OFFERS,
  SMARTPUSH_API_URL_RECOMMENDED_OFFERS,
  SMARTPUSH_API_URL_CITY_BY_ID
} from '@smartpush-front/api';
import {OfferModel} from "./Offer/OfferModel";
import {
  getCredentialToken,
  getToken,
  getTokenAccessKeyCloak,
  LS_KEYCLOAK_ACCESS_TOKEN_KEY,
  LS_KEYCLOAK_TOKENS, makeSelectMapInformations,
  makeSelectUserDataCoordinates, getLocationOfferBeforeLogin
} from "@smartpush-front/store";
import {objectToParams} from "../utils/url/urlUtils";
import {injectParameter} from "../utils/url/injectParameter";
import {makeSelectOffersCity} from "./selectors";
import {isEmpty} from "lodash";
import {SETTINGS} from "@smartpush-front/styles";
import {get} from "local-storage";
import {handleToken} from '../../../../apps/phoenix/src/app/components/tokens/tokenSaga';
import {makeSelectUserData} from "../../../payment/src/lib/store/selectors/userSelector";

/**
 * Offers request/response handler
 */
export function* getOffersCall(action: any): any {
  let token = yield select(getToken());
  if (SETTINGS.partnerName === 'EDF') {
    token = yield select(getTokenAccessKeyCloak());
  }
  if (!token) {
    token = yield select(getCredentialToken());
  }

  let searchParams = Object.assign({}, action.searchOptions);
  const paramsCoordinates = Object.assign({}, action.userCoordinates);
  if (searchParams?.byShop !== 1) { // If not by brands, then by shops
    const groups = {'byBrand' : 1};
    searchParams.groups = groups;
  } else if (searchParams?.byShop === 1) {
    delete searchParams.byShop;
    const groups = {'byShop' : 1};
    searchParams.groups = groups;
  }

  let params = '';

  if (paramsCoordinates.latitude && paramsCoordinates.longitude) {
    params = objectToParams(searchParams) + '&' + objectToParams(paramsCoordinates) + '&filters[distance]=25000';
  } else {
    params = objectToParams(searchParams);
  }

  const offersOptions = {
    url: SMARTPUSH_API_URL_OFFERS + '?' + params,
    config: {
      headers: {
        Authorization: token
      }
    },
  };

  try {
    const offers = yield call(SmartpushAPI.get, offersOptions);
    yield put(getOffersSuccess(offers));
  } catch (err) {
    yield put(getOffersError(err));
  }
}

/**
 * Recommended offers request/response handler
 */
export function* getRecommendedOffersCall(): any {
  let token = yield select(getToken());
  const cityCoordinates = yield select(makeSelectOffersCity());
  const userCoordinates = yield select(makeSelectUserDataCoordinates());
  const coordinates = cityCoordinates !== "" && !isEmpty(cityCoordinates) ? cityCoordinates : userCoordinates;
  const user = yield select(makeSelectUserData());

  const recommendedOptions = {
    url: SMARTPUSH_API_URL_RECOMMENDED_OFFERS.replace(':userId', user?.id ?? "") +
      (coordinates ? '?' + objectToParams(coordinates) : ''),
    config: {
      headers: {
        Authorization: token
      }
    },
    model: OfferModel
  };

  if (token && user?.id) {
    try {
      const recommended = yield call(SmartpushAPI.get, recommendedOptions);
      /** Retrieve Recommended Offers through redux */
      yield put(getRecommendedOffersSuccess(recommended?.data ?? []));
    } catch (err) {
      yield put(getRecommendedOffersError(err));
    }
  }
}

/**
 * Highlighted offers request/response handler
 */
export function* getHighlightedOffersCall(): any {
  let token = yield select(getToken());
  if (SETTINGS.partnerName === 'EDF') {
    token = yield select(getTokenAccessKeyCloak());
    if (!token) {
      token = yield get(LS_KEYCLOAK_TOKENS);
      token = token[LS_KEYCLOAK_ACCESS_TOKEN_KEY];
    }
  }
  if (!token) {
    token = yield select(getCredentialToken());
  }

  const highlightedOptions = {
    url: SMARTPUSH_API_URL_OFFERS + '?filters[highlighted]=true',
    config: {
      headers: {
        Authorization: token
      }
    },
  };

  try {
    const highlighted = yield call(SmartpushAPI.get, highlightedOptions);
    /** Retrieve Offers through redux */
    yield put(getHighlightedOffersSuccess(highlighted?.data ?? []));
  } catch (err) {
    yield put(getHighlightedOffersError(err));
  }
}

export function* loadOffersCall(action: any) {
  const token = yield handleToken();
  try {
    const offersIdUnique = [...new Set(action.offerIds)];
    const offers = yield all(offersIdUnique.map(offerId =>
      call(SmartpushAPI.get, {
        url: injectParameter(SMARTPUSH_API_URL_OFFER, offerId, SETTINGS.partnerName),
        config: {
          headers: {
            Authorization: token
          }
        },
      })
    ));

    yield put(loadOffersSuccess(offers.map(offer => offer.data)));
  } catch (e) {
    yield put(loadOffersError(e));
  }
}

export function* getMoreOffersCall(action: any) {
  const token = yield handleToken();
  const searchParams = Object.assign({}, action.searchOptions);
  const paramsCoordinates = Object.assign({}, action.userCoordinates);
  Object.assign(searchParams, { page: action.pagination.page + 1 });

  if (searchParams?.byShop !== 1) { // If not by brands, then by shops
    searchParams.byBrand = 1;
  }

  let params = "";
  if (paramsCoordinates.latitude && paramsCoordinates.longitude) {
    params = objectToParams(searchParams) + '&' + objectToParams(paramsCoordinates) + '&distance=25000';
  } else {
    params = objectToParams(searchParams);
  }

  const offersOptions = {
    url: SMARTPUSH_API_URL_OFFERS + '?' + params,
    config: {
      headers: {
        Authorization: token
      }
    }
  };


  try {
    const offers = yield call(SmartpushAPI.get, offersOptions);
    yield put(getMoreOffersSuccess(offers));
  } catch (err) {
    yield put(getMoreOffersError(err));
  }
}

export function* getMoreOffersForEdfCall(action: any) {
  const token = yield handleToken();
  const searchParams = Object.assign({}, action.searchOptions);
  const paramsCoordinates = Object.assign({}, action.userCoordinates);
  Object.assign(searchParams, {page: action.pages});

  let params = "";
  if (paramsCoordinates.latitude && paramsCoordinates.longitude) {
    params = objectToParams(searchParams) + '&' + objectToParams(paramsCoordinates) + '&filters[distance]=25000';
  } else {
    params = objectToParams(searchParams);
  }

  const offersOptions = {
    url: SMARTPUSH_API_URL_OFFERS + '?' + params + '&groups[byBrand]=1',
    config: {
      headers: {
        Authorization: token
      }
    },
    model: OfferModel
  };

  try {
    const offers = yield call(SmartpushAPI.get, offersOptions);
    yield put(getMoreOffersForEdfSuccess(offers, {}, action.totalPagination));
  } catch (err) {
    yield put(getMoreOffersForEdfError(err));
  }
}

export function* getOffersPaginationCall(action: any) {
  let token = yield select(getTokenAccessKeyCloak());
  if (!token) {
    token = yield select(getCredentialToken());
  }

  const searchParams = Object.assign({}, action.searchOptions);
  let callTwice = false;
  let webOffersOptions = null;
  let shopOffersOptions = null;
  // Make two calls when BOTH web and shop are requested and map is not displayed
  if (searchParams.isWeb === undefined && searchParams.byShop === undefined && !isEmpty(action.webOfferPagination)) {
    callTwice = true;
    let webParams = searchParams;
    if (action.webOfferPagination?.pages > action.webOfferPagination?.page) {
      webParams = {
        ...searchParams,
        page: action.currentPage,
        limit: action.limit,
      }
    }
    webOffersOptions = {
      url: SMARTPUSH_API_URL_OFFERS + '?isWeb=1&' + objectToParams(webParams),
      config: {
        headers: {
          Authorization: token
        }
      },
      model: OfferModel
    };

    let shopParams = "";
    if (action.pagination?.pages > action.pagination?.page) {
      shopParams = {
        ...searchParams,
        page: action.currentPage,
        limit: action.limit,
      }
    }
    shopOffersOptions = {
      url: SMARTPUSH_API_URL_OFFERS + '?isWeb=0&' + objectToParams(shopParams) + '&distance=25000',
      config: {
        headers: {
          Authorization: token
        }
      },
      model: OfferModel
    };


    webOffersOptions = {
      url: SMARTPUSH_API_URL_OFFERS + '?isWeb=1&' + objectToParams(webParams),
      config: {
        headers: {
          Authorization: token
        }
      },
      model: OfferModel
    };
  }

  try {
    let offers = null;
    let webOffers = null;
    if (callTwice) {
      const [shopOffersResponse, webOffersResponse] = yield all([
        action.pagination?.pages > action.pagination?.page ? call(SmartpushAPI.get, shopOffersOptions) : undefined,
        action.webOfferPagination?.pages > action.webOfferPagination?.page ? call(SmartpushAPI.get, webOffersOptions) : undefined,
      ]);
      offers = shopOffersResponse ?? {data: []};
      webOffers = webOffersResponse ?? {data: []};
    } else {
      offers = yield call(SmartpushAPI.get, webOffersOptions);
    }
    /** Retrieve morer Offers through redux */
    yield put(getOffersPaginationSuccess(offers, webOffers));
  } catch (err) {
    yield put(getOffersPaginationError(err));
  }
}

/**
 * Offer request/response handler
 */
export function* getOfferCall(action: any) {
  const token = yield handleToken();
  const offerOptions = {
    url: SMARTPUSH_API_URL_OFFER.replace(':offerId', action.offerId),
    config: {
      headers: {
        Authorization: token
      }
    },
  };
  try {
    const response = yield call(SmartpushAPI.get, offerOptions);
    const {data} = response;
    /** Retrieve Offers through redux */
    if (data.length === 0) {
      throw new Error('No offer');
    }

    const pagination = {
      page: parseInt(response?.headers["pagination-page"]),
      pages: parseInt(response?.headers["pagination-count"]),
      limit: parseInt(response?.headers["pagination-limit"]),
      count: parseInt(response?.headers["element-count"])
    }
    yield put(getLocationOfferBeforeLogin(window.location.pathname))
    yield put(getOfferSuccess(new OfferModel(data), pagination, action.switchBrandPage));
  } catch (err) {
    yield put(getOfferError(err));
  }
}

/**
 * Brand with offers request/response handler
 */
export function* getBrandWithOfferCall(action: any) {
  const token = yield handleToken();

  const offerOptions = {
    url: injectParameter(`${SMARTPUSH_API_URL_BRAND_OFFERS}?limit=100`, action.brandId),
    config: {
      headers: {
        Authorization: token
      }
    },
  };
  try {
    const {data} = yield call(SmartpushAPI.get, offerOptions);
    /** Retrieve Offers through redux */
    yield put(getBrandWithOffersSuccess(data));
  } catch (err) {
    yield put(getBrandWithOffersError(err));
  }
}

/**
 * Brand with offers request/response handler
 */
export function* getBrand(action: any) {
  const token = yield handleToken();

  const brandOptions = {
    url: injectParameter(SMARTPUSH_API_URL_BRAND, action.brandId),
    config: {
      headers: {
        Authorization: token
      }
    }
  };

  try {
    const {data} = yield call(SmartpushAPI.get, brandOptions);
    /** Retrieve Offers through redux */
    yield put(getBrandSuccess(data));
  } catch (err) {
    yield put(getBrandError(err));
  }
}

export function* loadCityById(action: any) {
  const token = yield handleToken();

  const offerOptions = {
    url: injectParameter(SMARTPUSH_API_URL_CITY_BY_ID, action.cityId),
    config: {
      headers: {
        Authorization: token
      }
    },
    model: OfferModel
  };
  try {
    const {data} = yield call(SmartpushAPI.get, offerOptions);
    /** Retrieve Offers through redux */
    yield put(getCitySuccess(data));
  } catch (err) {
    yield put(getCityError(err));
  }
}

export function* sendOfferViewToAnalyticsDataBase(action: any) {
  const token = yield select(getToken());
  const options = {
    url: SMARTPUSH_API_URL_ANALYTICS_OFFER_VIEW,
    config: {
      headers: {
        Authorization: token
      }
    },
    data: {
      viewedAt: action.data.viewedAt,
      offer: action.data.offer,
      plainTextOfferId: action.data.plainTextOfferId,
    }
  };
  try {
    yield call(SmartpushAPI.post, options);
    yield put(sendAnalyticsOfferViewSuccess());
  } catch (err) {
    yield put(sendAnalyticsOfferViewError(err));
  }
}

export function* sendOfferWalletToAnalyticsDataBase(action: any) {
  const token = yield select(getToken());
  const options = {
    url: SMARTPUSH_API_URL_ANALYTICS_OFFER_WALLET,
    config: {
      headers: {
        Authorization: token
      }
    },
    data: {
      updatedWalletAt: action.data.updatedWalletAt,
      offer: action.data.offer,
      plainTextOfferId: action.data.plainTextOfferId,
      inWallet: action.data.inWallet
    }
  };
  try {
    yield call(SmartpushAPI.post, options);
    yield put(sendAnalyticsOfferWalletSuccess());
  } catch (err) {
    yield put(sendAnalyticsOfferWalletError(err));
  }
}

export function* sendOfferPrintToAnalyticsDataBase(action: any) {
  const token = yield select(getToken());
  const options = {
    url: SMARTPUSH_API_URL_ANALYTICS_OFFER_PRINT,
    config: {
      headers: {
        Authorization: token
      }
    },
    data: {
      printedAt: action.data.printedAt,
      offer: action.data.offer,
      plainTextOfferId: action.data.plainTextOfferId
    }
  };
  try {
    yield call(SmartpushAPI.post, options);
    yield put(sendAnalyticsOfferPrintSuccess());
  } catch (err) {
    yield put(sendAnalyticsOfferPrintError(err));
  }
}

export function* getOffersWithShopCall(action: any) {
  let token = yield select(getToken())
  if (SETTINGS.partnerName === 'EDF') {
    token = yield select(getTokenAccessKeyCloak());
  }
  if (!token) {
    token = yield select(getCredentialToken());
  }

  const coordinates = Object.assign({}, action.userCoordinates);
  const mapInformations = yield select(makeSelectMapInformations());
  let params = "";
  if (coordinates.latitude && coordinates.longitude) {
    params = objectToParams(coordinates);
  }
  if (action.categoryId) {
    params = params + '&filters[categories]=' + action.categoryId
  }

  const options = {
    url: `${SMARTPUSH_API_URL_OFFERS}?${params}&filters[distance]=${mapInformations.distance}&groups[byShop]=1`,
    config: {
      headers: {
        Authorization: token,
      }
    },
    model: OfferModel
  }

  try {
    const offers = yield call(SmartpushAPI.get, options);
    yield put(getOffersMapSuccess(offers ?? {data: []}))
  } catch (err) {
    yield put(getOffersError(err));
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export function* saga() {
  // Watches for GET_OFFERS actions and calls getUser when one comes in.
  // By using `takeLatest` only the result of the latest API call is applied.
  // It returns task descriptor (just like fork) so we can continue execution
  // It will be cancelled automatically on component unmount
  yield takeLatest(GET_OFFER, getOfferCall);
  yield takeLatest(GET_BRAND_WITH_OFFERS, getBrandWithOfferCall);
  yield takeLatest(GET_BRAND, getBrand);
  yield takeLatest(GET_OFFERS, getOffersCall);
  yield takeLatest(GET_RECOMMENDED_OFFERS, getRecommendedOffersCall);
  yield takeLatest(GET_HIGHLIGHTED_OFFERS, getHighlightedOffersCall);
  yield takeLatest(GET_MORE_OFFERS, getMoreOffersCall);
  yield takeLatest(GET_MORE_OFFERS_FOR_EDF, getMoreOffersForEdfCall);
  yield takeLatest(CLEAR_ALL_FILTERS, getOffersCall);
  yield takeLatest(LOAD_OFFERS, loadOffersCall);
  yield takeLatest(GET_CITY, loadCityById);
  yield takeLatest(SEND_ANALYTICS_OFFER_VIEW, sendOfferViewToAnalyticsDataBase);
  yield takeLatest(SEND_ANALYTICS_OFFER_WALLET, sendOfferWalletToAnalyticsDataBase);
  yield takeLatest(SEND_ANALYTICS_OFFER_PRINT, sendOfferPrintToAnalyticsDataBase);
  yield takeLatest(GET_MORE_OFFERS_PAGINATION, getOffersPaginationCall);
  yield takeLatest(GET_OFFERS_MAP, getOffersWithShopCall)
}
