import { Model } from '@smartpush-front/api';

class UserModel extends Model {
  public id: string;
  public email: string;
  public username: string;
  public enabled: boolean;
  public gender: string;
  public birthDate: string;
  public zipCode: string;
  public needProfileUpdate: string;
  public needTosAccept: string;
  public lastName?: string;
  public firstName?: string;
  public coordinates: any;

  constructor(props: any) {
    super(props);
    this.id = props.id;
    this.email = props.email;
    this.username = props.username;
    this.enabled = props.enabled;
    this.gender = props.gender;
    this.birthDate = props.birthDate;
    this.zipCode = props.zipCode;
    this.needProfileUpdate = props.needProfileUpdate;
    this.needTosAccept = props.needTosAccept;
    this.lastName = props.lastName;
    this.firstName = props.firstName;
    this.coordinates = props.coordinates;
  }
}

export default UserModel;
