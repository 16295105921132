export const signUpSchema: any = {
  "title": "Modifier vos informations",
  "description": "",
  "type": "object",
  "required": [
    "email",
    "gender",
    "firstName",
    "lastName"
  ],
  "properties": {
    "firstName": {
      "type": "string",
      "title": "Prénom",
      "default": ""
    },
    "lastName": {
      "type": "string",
      "title": "Nom",
      "default": ""
    },
    "email": {
      "type": "string",
      "format": "email",
      "title": "Email",
      "default": ""
    },
    "gender": {
      "type": "string",
      "title": "Genre",
      "enumNames": ["Homme", "Femme", "Non renseigné"],
      "enum": ["MALE", "FEMALE", ""]
    },
    "tosMarketing": {
      "type": "boolean",
      "title": "Marketing"
    }
  }
};
