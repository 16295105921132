// Hook
import {RefObject, useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import {useSelector} from "react-redux";
import {makeSelectUserData} from "../app/pages/LoginPage/selectors";
import {isEmpty} from "lodash";
import {get} from "local-storage";

interface Size {
  width: number
  height: number
}

// Hook
export function useEventListener<T extends HTMLElement = HTMLDivElement>(
  eventName: string,
  handler: Function,
  element?: RefObject<T>,
) {
  // Create a ref that stores handler
  const savedHandler = useRef<Function>()
  useEffect(() => {
    // Define the listening target
    const targetElement: T | Window = element?.current || window
    if (!(targetElement && targetElement.addEventListener)) {
      return
    }
    // Update saved handler if necessary
    if (savedHandler.current !== handler) {
      savedHandler.current = handler
    }
    // Create event listener that calls handler function stored in ref
    const eventListener = (event: Event) => {
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!savedHandler?.current) {
        savedHandler.current(event)
      }
    }
    targetElement.addEventListener(eventName, eventListener)
    // Remove event listener on cleanup
    return () => {
      targetElement.removeEventListener(eventName, eventListener)
    }
  }, [eventName, element, handler])
}

export function useElementSize<T extends HTMLElement = HTMLDivElement>(
  elementRef: RefObject<T>,
): Size {
  const [size, setSize] = useState<Size>({
    width: 0,
    height: 0,
  })
  // Prevent too many rendering using useCallback
  const updateSize = useCallback(() => {
    const node = elementRef?.current
    if (node) {
      setSize({
        width: node.offsetWidth || 0,
        height: node.offsetHeight || 0,
      })
    }
  }, [elementRef])
  // Initial size on mount
  useEffect(() => {
    updateSize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEventListener('resize', updateSize)
  return size
}

export function useWindowSize() {
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });
  useLayoutEffect(() => {
    function updateSize() {
      setSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}


export function useEdfConsent() {
  const [consent, setConsent] = useState(false);

  const cons: any = localStorage.getItem('edf_consent')

  useEffect(() => {
    if (cons === 'true') {
      setConsent(true)
    }
  }, [cons])


  return consent;
}

export function useUserData() {
  const [userData, setUserData] = useState(undefined);

  const userDataFromStore = useSelector(makeSelectUserData());

  useEffect(() => {
    if (!isEmpty(userDataFromStore)) {
      setUserData(userDataFromStore);
    }
  }, [userDataFromStore])

  return {
    userData,
    isConnected: userData !== undefined
  }
}

export function useConsentGDPR() {
  const {userData, isConnected} = useUserData();
  const LSCookies = get('consentGDPR');
  const edfConsent = useEdfConsent();
  const [sendGDPR, setSendGDPR] = useState(false);

  useEffect(() => {
    // @ts-ignore
    if (isConnected && userData && (LSCookies || edfConsent) && !userData?.statisticalCookiesAt) {
      setSendGDPR(true);
    }
  }, [isConnected, userData, LSCookies, edfConsent])

  return {
    sendGDPR,
  }
}
