import {darken, makeStyles} from "@material-ui/core/styles";
import {COLORS, FORMS_STYLES, SETTINGS} from "@smartpush-front/styles";
import backgroundImage from "./BackgroundImage.svg";
import { Theme } from '@material-ui/core';

export const useStylesPaymentModal = makeStyles((theme: Theme) => ({
    backLink: {
      textDecoration: 'none',
      display: 'inline-block',
      marginLeft: 10,
      color: 'black',
      cursor: 'pointer',
      margin: '8px 0',
      '& svg': {
        fontSize: '1.2rem',
        marginRight: 2
      },
      '& span': {
        fontSize: '16px',
        position: 'relative',
        top: -4,
        left: -5
      },
    },
    topRoot: {
      paddingTop: '12px'
    },
    topMobile: {
      paddingTop: '16px'
    },
    background: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        background: `url("${backgroundImage}")`,
        backgroundColor: 'white',
        height: '100%'
    },
    backButton: {
        height: '34px',
        width: '81px',
        border: `solid 1px ${theme.palette.primary.main}`,
        backgroundColor: 'white',
        color: theme.palette.primary.main,
        borderRadius: '17px',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: 'white'
        }
    },
    alert: {
        backgroundColor: '#FFFCF7 !important',
        border: '1px solid #FAD390',
        lineHeight: '16px',
        fontSize: '16px',
        marginTop: '16px',
        '& .MuiAlert-icon': {
            color: '#FAD390',
            alignItems: 'center'
        },
        '& .MuiTypography-root': {
            color: '#878B8D',
            fontWeight: 300,
            fontSize: 14
        },
        '& .MuiAlertTitle-root': {
            color: '#232323',
            fontWeight: 700,
            fontFamily: 'ProximaNovaBold',
            marginBottom: 0,
            fontSize: 16
        },
    },
    divLogo: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    logo: {
        height: '50px',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
        padding: 0,
    },
    padding: {
        padding: 20,
        backgroundColor: "rgba(245, 245, 245)"
    },
    root: {
    },
    cardRoot: {
        borderRadius: 5,
        backgroundColor: 'white',
        boxShadow: '0 2px 4px 0 rgba(152,152,152,0.62)',
    },
    mainTitle: {
        paddingLeft: 32,
        paddingTop: 32,
        paddingBottom: 16,
        fontSize: '24px',
        lineHeight: '32px',
        fontFamily: 'ProximaNovaBold'
    },
    mainTitleSuccess: {
        fontSize: '24px',
        lineHeight: '32px',
        paddingBottom: '16px',
        fontFamily: 'ProximaNovaBold'
    },
    successLogo: {
        height: 64,
        width: 64,
        margin: 32,
        color: '#38ada9'
    },
    pendingLogo: {
        height: 64,
        width: 64,
        margin: 32,
        color: '#F6B93B'
    },
    mainTitleNoPaddingBottom: {
        paddingLeft: 32,
        paddingTop: 32,
        fontSize: '24px',
        lineHeight: '32px',
        fontFamily: 'ProximaNovaBold'
    },
    sectionPadding: {
        paddingLeft: '56px',
        paddingRight: '56px'
    },
    sectionPaddingMobile: {
      paddingLeft: '0',
      paddingRight: '0'
    },
    sectionPaddingOrder: {
        paddingLeft: '32px',
        paddingRight: '32px',
    },
    sectionPaddingOrderLast: {
        paddingLeft: '32px',
        paddingRight: '32px',
        paddingBottom: '24px'
    },
    sectionTitle: {
        fontFamily: 'ProximaNovaBold',
        fontSize: '20px',
        paddingBottom: '8px'
    },
    iconRotate: {
      transform: 'rotate(270deg)',
      marginRight: '30px'
    },
    iconRotated: {
      transform: 'rotate(0deg)',
      marginRight: '30px'
    },
    sectionTitlePaddingMobile: {
      backgroundColor: "#F7F9FA",
      fontFamily: 'ProximaNova',
      fontSize: '20px',
      lineHeight: '24px',
      paddingBottom: '4px',
      paddingTop: '4px',
      paddingLeft: '20px',
    },
    sectionTitlePadding4: {
      fontFamily: 'ProximaNovaBold',
      fontSize: '20px',
      lineHeight: '24px',
      paddingBottom: '4px'
    },
    sectionText: {
        fontSize: '16px',
        fontWeight: 200,
        lineHeight: '19px',
        paddingBottom: '8px'
    },

    sectionText2: {
        fontSize: '14px',
        fontWeight: 300,
        lineHeight: '16px',
        paddingBottom: '8px',
        color: '#878B8D',
        marginLeft: 8
    },
    sectionInput: {
        width: '100%',
        '& .MuiInputBase-input': {
          marginBottom: 0,
        },
        maxWidth: '360px',
        '& .MuiOutlinedInput-root': {
            backgroundColor: '#e2e9ec',
            width: '100%',
            maxWidth: '360px',
            height: 34,
            borderRadius: 5,
            color: '#232323'
        },
        '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'white',
            borderRadius: 5
        },
        '& .MuiOutlinedInput-input': {
            padding: 8,
            fontSize: 16
        }
    },
    divider: {
        marginTop: 24,
        marginBottom: 24,
    },
    dividerLast: {
        marginBottom: 24,
    },
    dividerOrderSummary: {
        marginTop: 16,
        marginBottom: 16,
    },
    orderContent: {
        fontSize: '16px',
        color: theme.palette.primary.main,
        lineHeight: '19px',
    },
    totalPrice: {
      padding: '8px 32px 24px',
      display: 'flex',
      flexDirection: 'column',
      '& > div': {
        display: 'flex',
        justifyContent: 'space-between',
      },
      '& .text': {
        flexGrow: 1,
        marginRight: '8px',
        '& .title': {
          fontSize: '16px',
          lineHeight: '17px',
          fontFamily: 'ProximaNovaBold'
        },
        '& .description': {
          fontSize: '12px',
          fontWeight: 300,
          lineHeight: '14px',
          color: '#878B8D'
        }
      },
      '& .amount': {
        flexWrap: 'nowrap'
      }
    },
    paymentButton: {
        borderRadius: 5,
        height: '34px',
        width: '100%',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: darken(theme.palette.primary.main, 0.3)
        }
    },
  paymentButtonMobile: {
    borderRadius: 5,
    height: '34px',
    boxShadow: 'none',
    '&:hover': {
      backgroundColor: darken(theme.palette.primary.main, 0.3)
    }
  },
    orderButton: {
        borderRadius: 5,
        height: '34px',
        width: '100%',
        boxShadow: 'none',
        padding: '8px 16px 8px 16px',
        marginBottom: 32,
        marginTop: 16,
        '&:hover': {
          backgroundColor: darken(theme.palette.primary.main, 0.3)
        }
    },
    paymentButtonTypo: {
        fontSize: '16px',
        fontFamily: 'ProximaNovaBold',
        color: COLORS.white,
      // '&:hover': {
        //   backgroundColor: darken(theme.palette.primary.main, 0.3)
        // }
    },
    paymentCGU: {
        marginTop: '12px',
        marginBottom: '32px',
        fontSize: '12px',
        fontWeight: 300,
        lineHeight: '14px',
        color: '#878B8D'
    },
    paddingTopTotal: {
        paddingTop: '8px'
    },
    contactUsTypo: {
      marginTop: "7px",
      marginBottom: "10px",
      fontFamily: 'ProximaNova',
      textDecoration: 'underline',
      display: "flex",
      justifyContent: "center"
    },
    needHelpTypo: {
      fontFamily: 'ProximaNovaBold',
      fontSize:"16px",
      display: "flex",
      justifyContent: "center"
    },
    orderButtonContainerMobile: {
      marginTop: "15px",
      display: "flex",
      justifyContent: "center"
    },
    totalTypographyStyle:{
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
      marginLeft: '20px',
      marginRight: '20px',
      marginTop: '10px',
      paddingLeft: '0px',
      justifyContent: 'space-between'
    },
    totalContainerFees: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '20px',
      marginRight: '20px',
      marginTop: '10px',
      paddingLeft: '0px',
    },
    totalSaving: {
      display: 'flex',
      justifyContent: 'space-between',
      fontStyle: 'italic',
    },
    totalTextCommon: {
      fontSize: '18px',
      fontFamily: 'ProximaNova'
    },
    totalAmountCommon: {
      fontSize: '20px',
      fontFamily: 'ProximaNovaBold'
    },
    savingsAmountCommon:{
      fontSize: '14px',
      fontFamily: 'ProximaNova'
    },
    totalFees: {
      fontFamily: 'ProximaNova',
      fontSize: '14px'
    }
}));
