import React from "react";

import { Grid } from "@material-ui/core";
import {useStylesPaymentModal} from "../styles";

import Typography from "@material-ui/core/Typography";
import Fab from "@material-ui/core/Fab";
import {history} from "@smartpush-front/store";
import {SETTINGS} from "@smartpush-front/styles";
import AccessTimeIcon from "@material-ui/icons/AccessTime";

export function OrderSuccessMessage(props) {
  const classes = useStylesPaymentModal();
  const { userData, ctaRoute, readableId } = props;

  return (
    <Grid container alignItems="center" justify="center">
        <Grid item>
          <AccessTimeIcon
            fontSize="large"
            className={classes.successLogo}
          />
        </Grid>
        <Grid item xs={12}>
            <Typography align="center" className={classes.sectionText} color="textPrimary">
                { userData.firstname && userData.lastname ? (userData.firstname + ' ' +userData.lastname) : userData.email }
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Typography align="center" className={classes.mainTitleSuccess}>
              {SETTINGS.familiarity ? "Ta" : "Votre"} commande n°{readableId} est en cours de traitement !
            </Typography>
        </Grid>
        <Grid item xs={12}>
            <Typography align="center" gutterBottom className={classes.sectionText} color="textPrimary">
                <span style={{display: 'block'}}>{SETTINGS.familiarity ? 'Ton' : 'Votre'} paiement a été accepté</span>
              {SETTINGS.familiarity ? "Tu vas" : "Vous allez"} recevoir un e-mail de confirmation.
            </Typography>
        </Grid>
        <Grid item >
            <Fab color="primary"  className={classes.orderButton} onClick={() => history.push(ctaRoute)}>
                <Typography className={classes.paymentButtonTypo}>
                    Suivre ma commande
                </Typography>
            </Fab>
        </Grid>
    </Grid>

  );
}
