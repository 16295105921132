/**
 * OfferShopList selectors
 */

import { createSelector } from 'reselect';
import {initialState} from "../reducers/reducer";

const selectOrder = (state: any) => state.order || initialState;

const makeSelectShoppingCartItems = () =>
    createSelector(
        selectOrder,
        orderState => orderState.shoppingCart
    );

const makeSelectShoppingCartTotalPrice = () =>
    createSelector(
        selectOrder,
        orderState => Object.values(orderState.shoppingCart).reduce((result: any, item: any) => {
          if (item.freeValue) {
            return result + (item.quantity - (item.quantity * item.discountAmount / 100))
          }

          return result + item.price * item.quantity
        }, 0)
    );

const makeSelectShoppingCartTotalInitialPrice = () =>
    createSelector(
        selectOrder,
        orderState => Object.values(orderState.shoppingCart).reduce((result: any, item: any) => {
          const price = item.initialPrice ?? item.faceValue ?? 0;
          return result + price * item.quantity
        }, 0)
    );
const makeSelectShoppingCartHasQuantitySelected = () =>
    createSelector(
        selectOrder,
        orderState => Object.values(orderState.shoppingCart).reduce((result: any, item: any) => result + item.quantity, 0)
    );

const makeSelectOrderIsValid = () =>
  createSelector(
    selectOrder,
    orderState => {
      if (orderState.feesLoading || orderState.feesError) {
        return false;
      }

      let isValid = false;
      Object.values(orderState.shoppingCart).forEach((item: any) => {
        if (!item.freeValue) {
          isValid = true;
        } else {
          if (item.maxFaceValue) {
            isValid = item.quantity >= item.minFaceValue && item.quantity <= item.maxFaceValue;
          } else {
            isValid = item.quantity >= item.minFaceValue;
          }
        }
      });

      return isValid;
    }
  )

/** Card Data selector */
const makeSelectCardFields = () =>
    createSelector(
        selectOrder,
        orderState => orderState.fields,
    );
const makeSelectIsOldCardSelected = () =>
    createSelector(
        selectOrder,
        orderState => orderState.isOldCardSelected,
    );
const makeSelectCardData = () =>
    createSelector(
        selectOrder,
        orderState => orderState.cardData,
    );
const makeSelectCardPan = () =>
    createSelector(
        selectOrder,
        orderState => orderState.cardData?.pan,
    );
const makeSelectCardToken = () =>
    createSelector(
        selectOrder,
        orderState => orderState.cardData?.token,
    );
const makeSelectRequestId = () =>
    createSelector(
      selectOrder,
      orderState => orderState.cardData?.request_id
    )
const makeSelectCardBrandProductName = () =>
    createSelector(
        selectOrder,
        orderState => orderState.cardData?.payment_product,
    );

const makeSelectCardExpiryDate = () =>
    createSelector(
        selectOrder,
        orderState => orderState.cardData?.card_expiry_year,
    );

const makeSelectCardIsLoading = () =>
    createSelector(
        selectOrder,
        orderState => orderState.loading,
    );
const makeSelectCardOrderFromRedirectLoading = () =>
  createSelector(
    selectOrder,
    orderState => orderState.orderFromRedirectLoading,
  );
const makeSelectCardError = () =>
    createSelector(
        selectOrder,
        orderState => orderState.error,
    );

const makeSelectCardOrderDataSending = () =>
    createSelector(
        selectOrder,
        orderState => orderState.orderDataSending,
    );

const makeSelectCardOrderDataStatusCodeError = () =>
    createSelector(
        selectOrder,
        orderState => orderState.orderDataError.statusCode,
    );

const makeSelectCardOrderDataError = () =>
    createSelector(
        selectOrder,
        orderState => orderState.orderDataError.error,
    );

const makeSelectCardOrderDataErrorOrderId = () =>
  createSelector(
    selectOrder,
    orderState => orderState.orderDataError.orderId,
  );


const makeSelectCardOrderDataResponseSuccess = () =>
    createSelector(
        selectOrder,
        orderState => orderState.orderDataResponse.status === 'completed',
    );

const makeSelectCardOrderDataResponsePending = () =>
  createSelector(
    selectOrder,
    orderState => orderState.orderDataResponse.status === 'pending',
  );

const makeSelectOrderDataResponse = () =>
  createSelector(
    selectOrder,
    orderState => orderState.orderDataResponse
  )

const makeSelectOrderId = () =>
    createSelector(
        selectOrder,
        orderState => orderState.orderDataResponse.orderId
    );

const makeSelectReadableId = () =>
  createSelector(
    selectOrder,
    orderState => orderState.orderDataResponse.readableId
  );

const makeSelectCardTypes = () =>
    createSelector(
        selectOrder,
        orderState => orderState.cardTypes,
    );

const makeSelectCardTokens = () =>
    createSelector(
        selectOrder,
        orderState => orderState.cardTokens,
    );

const makeSelectCardFeesAmount = () =>
  createSelector(
    selectOrder,
    orderState => orderState.fees
  );

const makeSelectCardFeesError = () =>
  createSelector(
    selectOrder,
    orderState => orderState.feesError
  );

const makeSelectCardFeesLoading = () =>
  createSelector(
    selectOrder,
    orderState => orderState.feesLoading
  );

export {
    selectOrder,
    makeSelectShoppingCartItems,
    makeSelectShoppingCartTotalPrice,
    makeSelectShoppingCartTotalInitialPrice,
    makeSelectShoppingCartHasQuantitySelected,
    makeSelectIsOldCardSelected,
    makeSelectCardFields,
    makeSelectCardIsLoading,
    makeSelectCardOrderFromRedirectLoading,
    makeSelectCardError,
    makeSelectCardData,
    makeSelectCardPan,
    makeSelectCardToken,
    makeSelectCardBrandProductName,
    makeSelectCardExpiryDate,
    makeSelectCardOrderDataSending,
    makeSelectCardOrderDataError,
    makeSelectCardOrderDataStatusCodeError,
    makeSelectCardOrderDataErrorOrderId,
    makeSelectCardOrderDataResponseSuccess,
    makeSelectCardTypes,
    makeSelectCardTokens,
    makeSelectOrderId,
    makeSelectCardOrderDataResponsePending,
    makeSelectReadableId,
    makeSelectOrderIsValid,
    makeSelectCardFeesAmount,
    makeSelectCardFeesError,
    makeSelectOrderDataResponse,
    makeSelectRequestId,
    makeSelectCardFeesLoading
};
